import React, { useMemo, useCallback } from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { RoomTable } from "types/enums";
import { Andar, DeviceXinfo, Room, RoomInfo } from "types/websocket-schema";
import { isNotNil } from "helpers/utils";
import defaultAvatar from "assets/images/defaultAvatar.svg";
import nosleep from "assets/images/icons/realtimeInfo/ic_nosleep.svg";
import sidebed from "assets/images/icons/realtimeInfo/ic_sidebed.svg";
import inbed from "assets/images/icons/realtimeInfo/ic_inbed.svg";
import sleep from "assets/images/icons/realtimeInfo/ic_sleep.svg";
import leave from "assets/images/icons/realtimeInfo/ic_leave.svg";

const category = [
    RoomTable.ROOM,
    RoomTable.NAME,
    RoomTable.BEDSTATUS,
    RoomTable.HRRR,
    RoomTable.ROOMSTATUS,
    RoomTable.NOTINROOM,
    RoomTable.DEVICESTATUS,
];

interface RealTimeInfoTableProps {
    filter?: string;
    data?: { [key: string]: Room };
}

const getBedStatusIcon = (status?: number) => {
    if (!isNotNil(status)) return nosleep;
    switch (status) {
        case 0:
            return nosleep;
        case 1:
            return sidebed;
        case 2:
            return inbed;
        case 4:
            return sleep;
        default:
            return nosleep;
    }
};

const getRoomStatusIcon = (room: RoomInfo | Andar, roomName: string) => {
    if (room?.people === 1) {
        if ("fallDown" in room && room.fallDown) {
            return require(`assets/images/icons/realtimeInfo/ic_${roomName}_fall.svg`);
        }
        if ("toLong" in room && room.toLong) {
            return require(`assets/images/icons/realtimeInfo/ic_${roomName}_long.svg`);
        }
        if (room.people) {
            return require(`assets/images/icons/realtimeInfo/ic_${roomName}_normal.svg`);
        }
    }
    return null;
};

const RealTimeInfoDetailsTable: React.FC<RealTimeInfoTableProps> = ({ filter, data }) => {
    const { t: translate } = useTranslation(["common"]);
    const navigate = useNavigate();

    const handleClick = useCallback(
        (residentId?: string) => {
            window.history.pushState({ prevUrl: "/overview/overview-unit" }, null);
            navigate("/overview/profile", { state: { residentId } });
        },
        [navigate],
    );
    const getDeviceStatusComponent = (
        deviceXinfo: DeviceXinfo[],
        ander: Andar,
        kitchen: RoomInfo,
        bedRoom: RoomInfo,
        livingRoom: RoomInfo,
        bathRoom: RoomInfo,
        hasLiveData: boolean,
    ): React.ReactNode => {
        if (!hasLiveData) {
            return (
                <MDTypography
                    variant="subtitle2"
                    sx={{
                        textAlign: "center",
                        backgroundColor: "#FF6B6B",
                        display: "flex",
                        justifyContent: "center",
                        minWidth: "60px",
                        padding: "4px 6px",
                        color: "#FFFFFF",
                        borderRadius: "5px",
                    }}>
                    {translate(`info.realTimeInfo.offline`)}
                </MDTypography>
            );
        }

        const status = {
            kitchen: false,
            bedRoom: false,
            livingRoom: false,
            bathRoom: false,
            bedRoomVs: Boolean(ander),
        };

        if (Array.isArray(deviceXinfo)) {
            deviceXinfo.forEach((device) => {
                if (device.space === "kitchen") status.kitchen = true;
                if (device.space === "bedRoom") status.bedRoom = true;
                if (device.space === "livingRoom") status.livingRoom = true;
                if (device.space === "bathRoom") status.bathRoom = true;
            });
        }

        const deviceStatus = [];
        if (status.kitchen && !kitchen) deviceStatus.push("kitchen");
        if (status.bedRoom && !bedRoom) deviceStatus.push("bedRoom");
        if (status.livingRoom && !livingRoom) deviceStatus.push("livingRoom");
        if (status.bathRoom && !bathRoom) deviceStatus.push("bathRoom");
        if (!status.bedRoomVs && !ander) deviceStatus.push("bedRoomVs");

        if (deviceStatus.length === 0) {
            return (
                <MDTypography
                    variant="subtitle2"
                    sx={{
                        textAlign: "center",
                        backgroundColor: "#4CAF50",
                        display: "flex",
                        justifyContent: "center",
                        minWidth: "60px",
                        padding: "4px 6px",
                        color: "#FFFFFF",
                        borderRadius: "5px",
                    }}>
                    OK
                </MDTypography>
            );
        }

        return (
            <MDBox sx={translate("lang") === "en" ? { width: "100%", maxWidth: 120, margin: "auto" } : {}}>
                {deviceStatus.map((device, index) => (
                    <MDBox key={index} textAlign="center" display="flex" justifyContent="left" alignItems="center">
                        <MDBox
                            sx={{
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                                backgroundColor: "#FF6B6B",
                                display: "inline-block",
                                marginRight: "5px",
                            }}
                        />
                        <MDTypography textAlign="start" fontSize="16px">
                            {translate(`info.realTimeInfo.${device}`)}
                        </MDTypography>
                    </MDBox>
                ))}
            </MDBox>
        );
    };

    const filteredData = useMemo(() => {
        if (!data) return [];
        return Object.entries(data).filter(
            ([key, room]) => !filter || room.roomName.includes(filter) || room.name.includes(filter),
        );
    }, [data, filter]);

    const DeviceStatusCell = React.memo(({ room }: { room: Room }) => (
        <MDBox component="td" p={1.5}>
            <MDBox
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    position: "relative",
                    width: "100%",
                    alignItems: "center",
                }}>
                {getDeviceStatusComponent(
                    room.deviceXinfo,
                    room.andar,
                    room.kitchen,
                    room.bedRoom,
                    room.livingRoom,
                    room.bathRoom,
                    room.hasLiveData,
                )}
            </MDBox>
        </MDBox>
    ));

    if (!data) {
        return (
            <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
                <MDTypography variant="h5">{translate("info.realTimeInfo.noData")}</MDTypography>
            </MDBox>
        );
    }

    return (
        <TableContainer sx={{ backgroundColor: "white !important", borderRadius: 3 }}>
            <Table sx={{ minWidth: 900 }} aria-label="real time info table">
                <MDBox component="thead">
                    <TableRow>
                        {category.map((item, i) => (
                            <TableCell key={i} align="center">
                                <MDTypography
                                    variant="subtitle2"
                                    sx={{
                                        display: "inline-block",
                                        fontWeight: "medium",
                                        mx: item !== RoomTable.ROOM ? 2 : 0,
                                        paddingTop: "20px",
                                    }}>
                                    {item ? translate(`info.realTimeInfo.${item}`) : ""}
                                </MDTypography>
                            </TableCell>
                        ))}
                    </TableRow>
                </MDBox>
                <TableBody>
                    {filteredData.map(([key, room]) => {
                        return (
                            <TableRow
                                key={key}
                                onClick={() => handleClick(key)}
                                sx={{
                                    cursor: "pointer",
                                    backgroundColor: room.hasLiveData ? "white" : "rgba(0, 0, 0, 0.1)",
                                    "&:hover": { backgroundColor: "#E9DEEE !important" },
                                }}>
                                <RoomCell room={room} />
                                <NameCell name={room.name} />
                                <BedStatusCell status={room.andar?.status} />
                                <HRRRCell andar={room.andar} />
                                <RoomStatusCell room={room} />
                                <NotInRoomCell room={room} />
                                <DeviceStatusCell room={room} />
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const  RoomCell = React.memo(({ room }: { room: Room }) => {
    const isWarning = useMemo(() => {
        if (room.bedRoom?.fallDown || room.kitchen?.fallDown || room.livingRoom?.fallDown || room.bathRoom?.fallDown) {
            return 1;
        }
        if (room.andar?.alarm) {
            return 2;
        }
        return 0;
    }, [room]);

    return (
        <MDBox component="td" p={1.5} sx={{ width: "10%" }}>
            <MDTypography variant="subtitle2" sx={{ display: "flex", alignItems: "center", float: "left" }}>
                <MDBox
                    sx={{
                        height: "10px",
                        width: "10px",
                        borderRadius: "50%",
                        backgroundColor: isWarning === 0 ? "transparent" : isWarning === 1 ? "red" : "#3B5AFF",
                        display: "inline-block",
                        marginRight: "5px",
                    }}
                />
                <MDAvatar sx={{ margin: "0px 16px" }} src={room.avatar || defaultAvatar} />
                <MDBox>{room.roomName}</MDBox>
            </MDTypography>
        </MDBox>
    );
});

const NameCell = React.memo(({ name }: { name: string }) => (
    <MDBox component="td" p={1.5}>
        <MDTypography variant="subtitle2" textAlign="center">
            {name}
        </MDTypography>
    </MDBox>
));

const BedStatusCell = React.memo(({ status }: { status?: number }) => (
    <MDBox component="td" p={1.5}>
        <MDTypography variant="subtitle2" textAlign="center" fontSize="50px">
            <MDBox
                sx={{ padding: "0 4px", width: "60px", height: "60px" }}
                component="img"
                src={getBedStatusIcon(status)}
                alt="bed status"
            />
        </MDTypography>
    </MDBox>
));

const HRRRCell = React.memo(({ andar }: { andar?: Andar }) => (
    <MDBox component="td" p={1.5}>
        <MDTypography variant="subtitle2" textAlign="center">
            {andar?.bpm ? andar.bpm : "--"}/ {andar?.rpm ? andar.rpm : "--"}
        </MDTypography>
    </MDBox>
));

const RoomStatusCell = React.memo(({ room }: { room: Room }) => {
    const statusIcon = useMemo(() => {
        return (
            getRoomStatusIcon(room.kitchen, "kitchen") ||
            getRoomStatusIcon(room.bedRoom, "bedroom") ||
            getRoomStatusIcon(room.livingRoom, "livingroom") ||
            getRoomStatusIcon(room.bathRoom, "bathroom") ||
            getRoomStatusIcon(room.andar, "bedroom")
        );
    }, [room]);

    return (
        <MDBox component="td" p={1.5}>
            <MDTypography variant="subtitle2" fontSize="16px" sx={{ display: "flex", justifyContent: "center" }}>
                {statusIcon ? (
                    <img
                        style={{ padding: "0 4px", width: "60px", height: "60px" }}
                        src={statusIcon}
                        alt="room status"
                    />
                ) : (
                    "--"
                )}
            </MDTypography>
        </MDBox>
    );
});

const NotInRoomCell = React.memo(({ room }: { room: Room }) => {
    const isInHouse = useMemo(() => {
        const { bedRoom, livingRoom, kitchen, bathRoom, andar } = room;

        const hasAllRooms = Boolean(bedRoom && livingRoom && kitchen && bathRoom && andar);

        const isAnyRoomPresent = Boolean(
            (bedRoom?.people && bedRoom.people > 0) ||
                (livingRoom?.people && livingRoom.people > 0) ||
                (kitchen?.people && kitchen.people > 0) ||
                (bathRoom?.people && bathRoom.people > 0) ||
                (andar?.people && andar.people > 0),
        );
        if (isAnyRoomPresent) {
            if (hasAllRooms) {
                return "N/A";
            } else {
                return "--";
            }
        } else {
            if (hasAllRooms) {
                return <MDBox sx={{ padding: "0 4px" }} component="img" src={leave} alt="leave" />;
            } else {
                return "N/A";
            }
        }
    }, [room]);

    return (
        <MDBox component="td" p={1.5}>
            <MDTypography variant="subtitle2" textAlign="center" fontSize="16px">
                {isInHouse}
            </MDTypography>
        </MDBox>
    );
});

RealTimeInfoDetailsTable.whyDidYouRender = true;
export default React.memo(RealTimeInfoDetailsTable);

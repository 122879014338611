/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2024 REL Technology Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { FC, forwardRef } from "react";

// @mui material components
import { OutlinedTextFieldProps, StandardTextFieldProps } from "@mui/material";

// Custom styles for MDInput
import MDInputRoot from "components/MDInput/MDInputRoot";

// Declaring props types for MDInput
interface Props extends Omit<StandardTextFieldProps | OutlinedTextFieldProps, "variant"> {
    variant?: "standard" | "outlined";
    error?: boolean;
    success?: boolean;
    disabled?: boolean;
    circular?: boolean;
}

const MDInput: FC<Props | any> = forwardRef(({ error, success, disabled, circular, ...rest }, ref) => (
    <MDInputRoot {...rest} ref={ref} ownerState={{ error, success, disabled, circular }} />
));

// Declaring default props for MDInput
MDInput.defaultProps = {
    variant: "standard",
    error: false,
    success: false,
    disabled: false,
    circular: true,
};

export default MDInput;

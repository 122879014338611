import { useNavigate } from "react-router-dom";
import { useState } from "react";

import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { FormType } from "types/enums";
import { Business } from "types/setting-schema";
import NewCenter from "layouts/settings/business-page/newCenter";
import NewUnit from "layouts/settings/business-page/newUnit";
import NewResident from "layouts/settings/resident-page/newResident";

// i18n
import { useTranslation } from "react-i18next";
import { CenterWithAlerts } from "../..";

export const CenterCard: React.FC<CenterWithAlerts> = ({ businessId, ...rest }) => {
    const navigate = useNavigate();
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [mode, setMode] = useState<{ type: FormType } | null>(null);
    const { name, address, phone, contact, _id, todayAlerts, onlineResidents, blur } = { ...rest };
    const { t: translate } = useTranslation(["common"]);

    const replaceSpace = (name: string) => {
        return name.replaceAll(" ", "-");
    };

    // Navigate to create center or unit form with business id
    const handleNavigate = (url: string) => {
        navigate(`/roomactivity`, {
            state: {
                businessId: businessId,
                centerId: _id,
            },
        });
    };

    return (
        <>
            <Grid item xs={12} sm={6} md={4} xl={3}>
                <MDBox
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    px={3}
                    py={4}
                    bgColor="white"
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFFFFF",
                        borderRadius: "13px",
                        boxShadow: "0px 2px 6px 0px #00000026",
                    }}>
                    {blur && (
                        <MDBox
                            sx={{
                                position: "absolute",
                                top: 4,
                                left: 6,
                                width: "95%",
                                height: "95%",
                                backgroundColor: "rgba(255,255,255,0.5)",
                                // filter: "blur(5px)",
                                backdropFilter: "blur(10px)",
                                zIndex: 1000,
                            }}
                        />
                    )}
                    {blur && (
                        <MDBox
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                zIndex: 1001,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                            <MDTypography variant="h5" color="textSecondary">
                                {/* {translate("auth.unauthorized")} */}
                            </MDTypography>
                        </MDBox>
                    )}
                    <MDBox
                        width="100%"
                        height="100%"
                        top="0"
                        right="0"
                        sx={{ cursor: blur ? "not-allowed" : "pointer", position: "absolute" }}
                        onClick={() => handleNavigate(`${replaceSpace(name)}`)}
                    />
                    <MDBox pb={2} display="flex" flexDirection="column" height="100%">
                        <MDBox mb={3}>
                            <MDTypography variant="h2" color="text">
                                {name.length > 25 ? `${name.substring(0, 25)}...` : name}
                            </MDTypography>
                        </MDBox>
                    </MDBox>

                    <MDBox mt="auto" display="flex" justifyContent="space-around">
                        <MDBox display="flex" flexDirection="column" alignItems="center">
                            <MDTypography sx={{ fontSize: "16px", fontWeight: "bold" }} color="text">
                                {translate("info.realTimeInfo.activeAlert")}
                            </MDTypography>
                            <MDTypography
                                sx={{ fontSize: "30px", fontWeight: "bold", paddingTop: "10px" }}
                                color="text">
                                {todayAlerts ? todayAlerts.length : 0}
                            </MDTypography>
                        </MDBox>
                        <MDBox display="flex" flexDirection="column" alignItems="center">
                            <MDTypography sx={{ fontSize: "16px", fontWeight: "bold" }} color="text">
                                {translate("info.realTimeInfo.resident")}
                            </MDTypography>
                            <MDTypography
                                sx={{ fontSize: "30px", fontWeight: "bold", paddingTop: "10px" }}
                                color="text">
                                {onlineResidents}
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Grid>
        </>
    );
};

export default CenterCard;

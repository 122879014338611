/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2024 REL Technology Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, ReactNode, useMemo, useCallback, memo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDCircularProgressRoot from "components/MDCircularProgress/MDCircularProgressRoot";
// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import WebsocketService from "services/websocket.service";
import AuthService from "services/auth.service";
import { Room, WebSocketProps } from "types/websocket-schema";

import { UnitStatus } from "./components/UnitStatus";
import { convertToObject, isFallAndLong, isNotNil, updateData } from "helpers/utils";
import io from "socket.io-client";
import { useAuth } from "context/userContext";
import { useNavigate } from "react-router-dom";
import { Roles } from "types/roles";
import AlarmService from "services/alarm.service";
import RealTimeInfoTable from "examples/Tables/RealTimeInfoTable";
import { useTranslation } from "react-i18next";
import WistronHome from "layouts/business/wistron-home";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { debounce } from "lodash";
import Footer from "examples/Footer";

import { AlertRecord } from "types/alert-schema";
import MDAvatar from "components/MDAvatar";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import "./toast.css";

// const renderInfoCard = (title: string, children?: ReactNode, background?: boolean, onClick?: () => void) => {
//     return (
//         <MDBox
//             onClick={onClick}
//             sx={{
//                 height: "130px",
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "13px",
//                 boxShadow: "0px 2px 6px 0px #00000026",
//                 padding: "30px",
//                 position: "relative",
//                 overflow: "hidden",
//                 cursor: onClick ? "pointer" : "normal",
//             }}>
//             {background && (
//                 <img
//                     src={require("assets/images/dashboard/user_block_bg.png")}
//                     alt="active-alert"
//                     style={{
//                         position: "absolute",
//                         width: "20vw",
//                         maxWidth: "256px",
//                         top: "clamp(-134px, 10vw, -10vw)",
//                         right: "-50px",
//                         zIndex: 0,
//                     }}
//                 />
//             )}
//             <MDBox
//                 sx={{
//                     fontSize: "16px",
//                     fontWeight: "300",
//                     color: "#7B809A",
//                     lineHeight: "22.4px",
//                     zIndex: 2,
//                     position: "relative",
//                 }}>
//                 {title}
//             </MDBox>
//             {children}
//         </MDBox>
//     );
// };

const RenderInfoCard = memo(
    ({
        title,
        children,
        background,
        onClick,
    }: {
        title: string;
        children?: React.ReactNode;
        background?: boolean;
        onClick?: () => void;
    }) => {
        const content = useMemo(() => (
            <MDBox
                onClick={onClick}
                sx={{
                    height: "130px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "13px",
                    boxShadow: "0px 2px 6px 0px #00000026",
                    padding: "30px",
                    position: "relative",
                    overflow: "hidden",
                    cursor: onClick ? "pointer" : "normal",
                }}
            >
                {background && (
                    <img
                        src={require("assets/images/dashboard/user_block_bg.png")}
                        alt="active-alert"
                        style={{
                            position: "absolute",
                            width: "20vw",
                            maxWidth: "256px",
                            top: "clamp(-134px, 10vw, -10vw)",
                            right: "-50px",
                            zIndex: 0,
                        }}
                    />
                )}
                <MDBox
                    sx={{
                        fontSize: "16px",
                        fontWeight: "300",
                        color: "#7B809A",
                        lineHeight: "22.4px",
                        zIndex: 2,
                        position: "relative",
                    }}
                >
                    {title}
                </MDBox>
                {children}
            </MDBox>
        ), [background, children, onClick, title]);

        return content;
    }
);
const AnimatedNotification = ({ children, index }: { children: React.ReactNode; index: number }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setIsVisible(true), index * 100);
        return () => clearTimeout(timer);
    }, [index]);

    return (
        <MDBox
            sx={{
                transform: isVisible ? "translateX(0)" : "translateX(100%)",
                opacity: isVisible ? 1 : 0,
                transition: "transform 0.3s ease-out, opacity 0.3s ease-out",
                transitionDelay: `${index * 0.1}s`,
            }}>
            {children}
        </MDBox>
    );
};

export const Dashboard: React.FC = () => {
    const [tableData, setTableData] = useState<{ [key: string]: Room }>(null);
    const [cardData, setCardData] = useState<any>(null);
    const { currentUser, setCurrentUser } = useAuth();
    const [alertData, setAlertData] = useState<{ todayAlerts: number; yesterdayAlerts: number }>(null);
    const [notiAlertData, setNotiAlertData] = useState<AlertRecord[] | null>(null);
    const navigate = useNavigate();
    const { t: translate } = useTranslation(["common"]);
    const [notifications, setNotifications] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [renderedNotifications, setRenderedNotifications] = useState<Set<string>>(new Set());
    const [newNotifications, setNewNotifications] = useState<AlertRecord[]>([]);

    // const notify = () => addToast(messageBody(message), { appearance: "success" });
    const memoNotifications = useMemo(() => notifications, [notifications]);
    const handleAlertHistory = () => {
        window.history.pushState({ prevUrl: "/dashboard" }, null);
        if (currentUser.role === Roles.relAdmin)
            navigate("/dashboard/alert-history", { state: { businessId: currentUser.business } });
        else if (currentUser.role === Roles.centerAdmin || currentUser.role === Roles.centerStaff)
            navigate("/dashboard/alert-history", { state: { centerId: currentUser.center.id } });
        else if (currentUser.role === Roles.businessAdmin)
            navigate("/dashboard/alert-history", { state: { businessId: currentUser.business } });
        else navigate("/dashboard/alert-history");
    };
    const handleResident = () => {
        window.history.pushState({ prevUrl: "/dashboard" }, null);
        navigate("/settings/resident", { state: { businessId: currentUser.business } });
    };

    const handleDevice = () => {
        window.history.pushState({ prevUrl: "/dashboard" }, null);
        navigate("/device", {});
    };
    const fetchAlertHistory = async (type: "businessId" | "centerId" | "all", value: string) => {
        const res = await AlarmService.getTodayBusinessAlarm(
            type,
            value,
            Intl.DateTimeFormat().resolvedOptions().timeZone,
        );
        if (res.success) {
            setAlertData({
                todayAlerts: res.data.totalAlertsToday,
                yesterdayAlerts: res.data.totalAlertsYesterday,
            });
            setNotiAlertData(res.data.todayAlerts);
        }
    };
    const calculatePercentageChange = (yesterdayAlert: number, todayAlert: number): JSX.Element => {
        let result = Math.round(((todayAlert - yesterdayAlert) / yesterdayAlert) * 100);
        if (yesterdayAlert === 0) result = 100;
        if (todayAlert === 0) result = -100;

        return (
            <>
                <MDTypography sx={{ color: result <= 0 ? "#0FB059" : "#CA1818" }}>
                    {result >= 100 ? `+${result}` : result}%
                </MDTypography>
                {translate(`info.realTimeInfo.sinceYesterday`)}
            </>
        );
    };

    function formatTime(timestamp: string): string {
        const time = new Date(timestamp);
        const now = new Date();
        const diffInSeconds = Math.floor((now.getTime() - time.getTime()) / 1000);

        if (diffInSeconds < 60) {
            return `${diffInSeconds} ${translate(`info.realTimeInfo.sec`)}`;
        } else if (diffInSeconds < 600) {
            const diffInMinutes = Math.floor(diffInSeconds / 60);
            return `${diffInMinutes} ${translate(`info.realTimeInfo.min`)}`;
        } else {
            const hours = time.getHours().toString().padStart(2, "0");
            const minutes = time.getMinutes().toString().padStart(2, "0");
            return `${hours}:${minutes}`;
        }
    }
    const handleClose = useCallback(
        (alertId: string) => {
            UpdateNotiReaded(alertId);
        },
        [memoNotifications],
    );
    const updateOwnerStatus = async (alertId: string) => {
        try {
            const response = await AlarmService.updateAlarmStatus(alertId, true);
            if (response?.success) {
                console.log("Update successful");
                setNotifications((prevNotifications) =>
                    prevNotifications.filter((notification) => notification.alertId !== alertId),
                );
            } else {
                console.warn("Update failed or response malformed");
            }
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };
    const addNotification = (message: AlertRecord) => {
        setNotifications((prevNotifications) => {
            // Check if the alertId already exists in the notifications
            if (prevNotifications.some((notification) => notification.alertId === message.alertId)) {
                return prevNotifications; // If it exists, return the previous notifications array without changes
            }

            // Check if the notification should be added based on its status
            if (!message.ownerReport && !message.read) {
                const updatedNotifications = [...prevNotifications, message];
                console.log("notifications", updatedNotifications);
                return updatedNotifications;
            }

            // If the notification doesn't meet the criteria, return the previous state
            return prevNotifications;
        });

        // Only update rendered notifications and trigger re-render if the notification was actually added
        if (!message.ownerReport && !message.read) {
            // Update the set of rendered notifications
            setRenderedNotifications((prev) => {
                const updated = new Set(prev);
                updated.add(message.alertId);
                return updated;
            });

            // Optionally, if you want to trigger a re-render of the NotificationContainer:
            setNewNotifications((prev) => [...prev, message]);
        }
    };

    const memoizedMessageBody = useCallback(
        (alert: AlertRecord) => {
            const alertType = alert.status !== "dead" && alert.status !== "Fall";

            return (
                <MDBox
                    className={alertType ? "custom-toast-warning" : "custom-toast-error"}
                    key={alert.alertId}
                    sx={{
                        position: "relative",
                        marginTop: "8px",
                        padding: "4px",
                        borderRadius: "8px",
                        boxShadow: "0 1px 10px 0 rgba(0,0,0,.1),0 2px 15px 0 rgba(0,0,0,.05)",
                    }}>
                    <MDBox sx={{ display: "flex", alignItems: "center" }}>
                        <ErrorIcon
                            sx={{
                                width: "20px",
                                color: alertType ? "#FF9F24" : "#FF6464",
                                margin: "0 8px",
                            }}
                        />
                        <MDBox>
                            <MDTypography
                                sx={{
                                    fontSize: "15px",
                                    lineHeight: "140%",
                                }}>
                                {translate(`info.realTimeInfo.alertType.${alert.status}`)}
                            </MDTypography>
                        </MDBox>
                        <MDBox sx={{ margin: "0 8px" }}>
                            <MDTypography
                                sx={{
                                    fontSize: "15px",
                                    color: "#7B809A",
                                    lineHeight: "140%",
                                }}>
                                {/* {formatTime(alert.startTime)} */}
                            </MDTypography>
                        </MDBox>
                        <IconButton
                            onClick={() => {
                                console.log("Clicked close button");
                                handleClose(alert.alertId);
                            }}
                            sx={{
                                marginLeft: "auto",
                                padding: "4px",
                                "&:hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                },
                            }}>
                            <CloseIcon sx={{ fontSize: "14px !important" }} />
                        </IconButton>
                    </MDBox>
                    <MDBox sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <MDBox>
                            <MDAvatar
                                sx={{
                                    margin: "0px 8px",
                                    width: "30px",
                                    height: "30px",
                                }}
                                src={
                                    alert.avatarUrl !== ""
                                        ? alert.avatarUrl
                                        : "https://firebasestorage.googleapis.com/v0/b/rel-dev-9037c.appspot.com/o/%E9%AB%98%E9%9B%84%E9%86%AB%E5%AD%B8%E5%A4%A7%E5%AD%B8%2F64717e0b8ee3a47becefc5a2%2Fresident%2Fc8bbbf2f-12b1-45d9-b947-29619dfe69e1?alt=media&token=044acbb8-b47e-43db-b59e-cb9d7c9ba8e5"
                                }
                            />
                        </MDBox>
                        <MDBox sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                            <MDTypography
                                textAlign={"center"}
                                sx={{ textWrap: "nowrap", paddingTop: "4px", fontSize: "16px" }}>
                                {translate(`info.realTimeInfo.room`)} {alert.roomName}
                            </MDTypography>
                            <MDBox>
                                <MDTypography sx={{ paddingTop: "4px", fontSize: "16px" }}>
                                    {`${alert.residentName}${translate(`info.realTimeInfo.alertDes.${alert.status}`)}`}
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                        <MDBox
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                paddingTop: "16px",
                                marginLeft: "auto",
                            }}>
                            <MDBox
                                onClick={async () => {
                                    console.log("Clicked confirm button");
                                    console.log("Alert ID:", alert.alertId);
                                    updateOwnerStatus(alert.alertId);
                                }}
                                sx={{
                                    width: "71px",
                                    height: "28px",
                                    fontSize: "12px",
                                    padding: "4px 9px",
                                    background: alertType ? "#FFE6C6" : "#FFDADA",
                                    color: alertType ? "#B96800" : "#B90000",
                                    textAlign: "center",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                }}>
                                {translate(`info.realTimeInfo.confirm`)}
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </MDBox>
            );
        },
        [translate],
    );

    const ConsolidatedNotification = ({ updatedNotifications }: any) => (
        <div
            style={{
                boxShadow: "0 1px 10px 0 rgba(0,0,0,.1),0 2px 15px 0 rgba(0,0,0,.05)",
                borderRadius: "8px",
                height: "77px",
                marginTop: "8px",
                padding: "4px",
                background: "#FFFFFF",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
            <div
                onClick={() => {
                    setShowAll(true);
                }}>
                <MDTypography
                    textAlign={"center"}
                    sx={{ textWrap: "nowrap", paddingTop: "4px", fontSize: "15px", color: "#006AFF" }}>
                    {translate(`info.realTimeInfo.showAll`, { count: updatedNotifications.length - 3 })}
                </MDTypography>
            </div>
        </div>
    );
    const UpdateNotiReaded = async (alertId: string) => {
        try {
            const res = await AlarmService.updateAlarmReaded(alertId);
            if (res.success) {
                setNotifications((prevNotifications) =>
                    prevNotifications.filter((notification) => notification.alertId !== alertId),
                );
            } else {
                console.warn("Failed to update notification read status");
            }
        } catch (error) {
            console.error("Error updating notification read status:", error);
        }
    };

    useEffect(() => {
        if (!isNotNil(currentUser)) return;
        let fetchInterval: NodeJS.Timeout | undefined;
        let type_id = "";
        switch (currentUser?.role) {
            case Roles.relAdmin:
                fetchAlertHistory("businessId", currentUser.business);
                fetchInterval = setInterval(() => fetchAlertHistory("businessId", currentUser.business), 5000);
                type_id = `business-All`;
                break;
            case Roles.businessAdmin:
                fetchAlertHistory("businessId", currentUser.business);
                fetchInterval = setInterval(() => fetchAlertHistory("businessId", currentUser.business), 5000);
                type_id = `business-${currentUser.business}`;
                break;
            case Roles.centerAdmin:
            case Roles.centerStaff:
                fetchAlertHistory("centerId", currentUser.center);
                fetchInterval = setInterval(() => fetchAlertHistory("centerId", currentUser.center), 5000);
                type_id = `center-${currentUser.center}`;
                break;
            default:
                break;
        }

        const socket = io(
            process.env.REACT_APP_ENV === "production"
                ? "https://api.realeasyliving.com/"
                : process.env.REACT_APP_ENV === "development"
                ? "https://devapi.realeasyliving.com/"
                : "http://localhost:8080/",
            // process.env.REACT_APP_ENV === "production" ? "https://api.realeasyliving.com/" : "http://localhost:6000/",
            {
                //transports : ['websocket'],
                query: {
                    type_id: type_id,
                },
            },
        );

        socket.on("connect", async () => {
            const socketId = socket.id;
        });
        socket.on("data", (channel, message) => {
            let tableData = convertToObject(channel.data.dashboard);
            let cardData = {
                activeDevice: channel.data.activeDevice,
                totalDevice: channel.data.totalDevice,
                user: Object.keys(tableData).length,
            };
            setTableData((prevData) => updateData(prevData, tableData));
            setCardData(cardData);
            // setTableData(tableData);
        });

        return () => {
            socket.disconnect();
            if (fetchInterval) clearInterval(fetchInterval);
        };
    }, [currentUser]);

    useEffect(() => {
        // When first time to get the data, show all the notifications and push the array to notifications to make a record
        if (notiAlertData) {
            notiAlertData.reverse().forEach((alert) => {
                if (!alert.ownerReport && !alert.read) addNotification(alert);
            });
        }
    }, [notiAlertData]);

    useEffect(() => {
        if (showAll && notifications.length === 0) {
            setShowAll(false);
        }
    }, [showAll, notifications]);

    useEffect(() => {
        const unrenderedNotifications = memoNotifications.filter(
            (notification) => !renderedNotifications.has(notification.alertId),
        );
        setNewNotifications(unrenderedNotifications);

        if (unrenderedNotifications.length > 0) {
            setRenderedNotifications((prev) => {
                const updated = new Set(prev);
                unrenderedNotifications.forEach((notification) => {
                    updated.add(notification.alertId);
                });
                return updated;
            });
        }
    }, [memoNotifications, renderedNotifications]);
    useEffect(() => {
        console.log("notifications", notifications);
        if (notifications.length <= 5) {
            setShowAll(false);
        }
    }, [notifications]);

    const NotificationContainer = useCallback(
        ({ notifyList }: { notifyList: AlertRecord[] }) => {
            const MAX_VISIBLE_NOTIFICATIONS = 5;
            if (notifyList.length === 0) return null;
            const reversedList = [...notifyList].reverse();
            return (
                <MDBox
                    sx={{
                        zIndex: 1000,
                        position: "fixed",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        width: "100%",
                        maxWidth: "458px",
                        height: "100%",
                        maxheight: "100vh",
                        overflowY: "auto",
                        overflowX: "hidden",
                        padding: "8px",
                        "&::-webkit-scrollbar": {
                            width: "6px",
                        },
                        "&::-webkit-scrollbar-track": {
                            background: "#f1f1f1",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            background: "#888",
                            borderRadius: "3px",
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                            background: "#555",
                        },
                        // For Firefox
                        scrollbarWidth: "thin",
                        scrollbarColor: "#888 #f1f1f1",
                    }}>
                    {reversedList.map(
                        (notification, index) =>
                            (index < MAX_VISIBLE_NOTIFICATIONS || showAll) && (
                                <AnimatedNotification key={notification.alertId} index={index}>
                                    {memoizedMessageBody(notification)}
                                </AnimatedNotification>
                            ),
                    )}

                    {reversedList.length > MAX_VISIBLE_NOTIFICATIONS && !showAll && (
                        <ConsolidatedNotification
                            updatedNotifications={reversedList}
                            count={reversedList.length - MAX_VISIBLE_NOTIFICATIONS}
                            onShowAll={() => setShowAll(true)}
                        />
                    )}
                </MDBox>
            );
        },
        [memoizedMessageBody, showAll],
    );

    // Memoize the NotificationContainer to prevent unnecessary re-renders
    const MemoizedNotificationContainer = useMemo(() => NotificationContainer, [NotificationContainer]);
    const memoizedCardData = useMemo(() => cardData, [cardData]);
    const memoizedRealTimeInfoTable = useMemo(() => {
        return <RealTimeInfoTable data={tableData} />;
    }, [tableData]);
    return (
        <DashboardLayout>
            <MemoizedNotificationContainer notifyList={memoNotifications} />
            <DashboardNavbar />
            <Grid container columnSpacing={1} rowSpacing={1}>
                <Grid item xs={12}>
                    <MDTypography variant="h2" mb={3} mt={3}>
                        {translate(`dashboard.activity`)}
                    </MDTypography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <RenderInfoCard
                        title={translate(`info.realTimeInfo.activeAlert`)}
                        background={false}
                        onClick={handleAlertHistory}>
                        <MDBox
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                placeContent: alertData === null ? "center" : "",
                            }}>
                            {alertData === null ? (
                                <CircularProgress size={50} />
                            ) : (
                                <>
                                    <MDTypography sx={{ fontSize: "32px", lineHeight: "44.8px" }}>
                                        {alertData?.todayAlerts ? alertData.todayAlerts : 0}
                                    </MDTypography>
                                    <MDTypography
                                        sx={{
                                            display: "flex",
                                            alignItems: "baseline",
                                            marginTop: "auto",
                                            fontSize: "16px",
                                            lineHeight: "22.4px",
                                            color: "#344767",
                                        }}>
                                        {calculatePercentageChange(alertData?.yesterdayAlerts, alertData?.todayAlerts)}
                                    </MDTypography>
                                </>
                            )}
                        </MDBox>
                    </RenderInfoCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    <RenderInfoCard
                        title={translate(`info.realTimeInfo.allUser`)}
                        background={true}
                        onClick={handleResident}>
                        <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                            <MDTypography sx={{ fontSize: "32px", lineHeight: "44.8px" }}>
                                {memoizedCardData?.user}
                            </MDTypography>
                        </MDBox>
                    </RenderInfoCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    <RenderInfoCard
                        title={translate(`info.realTimeInfo.deviceStatus`)}
                        background={false}
                        onClick={handleDevice}>
                        <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                            <MDBox sx={{ display: "flex", alignItems: "baseline", width: "100%" }}>
                                <MDTypography sx={{ fontSize: "32px", lineHeight: "44.8px" }}>
                                    {memoizedCardData?.activeDevice ? memoizedCardData?.activeDevice : 0}
                                </MDTypography>
                                <MDTypography
                                    sx={{
                                        fontSize: "16px",
                                        lineHeight: "22.4px",
                                        marginLeft: "18px",
                                        position: "relative",
                                        "&:before": {
                                            content: "''",
                                            width: "6px",
                                            height: "6px",
                                            background: "#0FB059",
                                            borderRadius: "25px",
                                            display: "inline-block",
                                            position: "absolute",
                                            left: "-9px",
                                            transform: "translateY(-50%);",
                                            top: "50%",
                                        },
                                    }}>
                                    On
                                </MDTypography>
                            </MDBox>
                            <MDBox sx={{ display: "flex", alignItems: "baseline", width: "100%" }}>
                                <MDTypography sx={{ fontSize: "32px", lineHeight: "44.8px" }}>
                                    {memoizedCardData?.totalDevice ? memoizedCardData?.totalDevice - memoizedCardData?.activeDevice : 0}
                                </MDTypography>
                                <MDTypography
                                    sx={{
                                        fontSize: "16px",
                                        lineHeight: "22.4px",
                                        marginLeft: "18px",
                                        position: "relative",
                                        "&:before": {
                                            content: "''",
                                            width: "6px",
                                            height: "6px",
                                            background: "#CD1111",
                                            borderRadius: "25px",
                                            display: "inline-block",
                                            position: "absolute",
                                            left: "-9px",
                                            transform: "translateY(-50%);",
                                            top: "50%",
                                        },
                                    }}>
                                    Off
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </RenderInfoCard>
                </Grid>
                <Grid item xs={12}>
                    <MDTypography variant="h2" mb={3} mt={3}>
                        {translate(`dashboard.residentStatus`)}
                    </MDTypography>
                    {memoizedRealTimeInfoTable}
                </Grid>
            </Grid>
            <Footer />
        </DashboardLayout>
    );
};

export default Dashboard;

/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2024 REL Technology Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactNode } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Style
import { globalStyle } from "styles/global.styles";
import { basicLayoutStyle } from "layouts/authentications/styles/auth.styles";

// Footer
import Footer from "examples/Footer";

// background images
import brandLogo from "assets/images/logos/REL-logo.svg";

// Declaring props types for BasicLayout
interface Props {
    children: ReactNode;
}

export const BasicLayout: React.FC<Props> = ({ children }) => {
    return (
        <PageLayout>
            <MDBox sx={basicLayoutStyle.container}>
                <Grid container sx={globalStyle.flex.center}>
                    <Grid item xs={11} sm={9} md={6} xl={4} sx={globalStyle.flex.horizon}>
                        <Card sx={basicLayoutStyle.card}>
                            <MDBox sx={basicLayoutStyle.imageContainer}>
                                <MDBox component="img" src={brandLogo} alt="Brand" sx={basicLayoutStyle.imageN} />
                            </MDBox>
                            {children}
                        </Card>
                    </Grid>
                    <Footer />
                </Grid>
            </MDBox>
            <MDBox sx={basicLayoutStyle.backgroundImage1}></MDBox>
            <MDBox sx={basicLayoutStyle.backgroundImage2}></MDBox>
            <MDBox sx={basicLayoutStyle.backgroundImage3}></MDBox>
        </PageLayout>
    );
};

export default BasicLayout;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Types
import { FormOperation, FormType } from "types/enums";
import { HealthData } from "types/measurement-schema";
import { yupResolver } from "@hookform/resolvers/yup";
import form from "layouts/info/schemas/form";
import validations from "layouts/info/schemas/validations";

import Grid from "@mui/material/Grid";
import FormLayout from "examples/LayoutContainers/FormLayout";
import EditButton from "components/MDButton/editButton";
import SubmitButtons from "components/MDButton/submitButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDErrorMsg from "components/MDErrorMsg";
import { FormFieldRHF, FormSelectRHF } from "components/MDFormField";
import { useForm } from "react-hook-form";
import { isNotNil } from "helpers/utils";
import { getFormKeys, isEditDisabled } from "helpers/formUtil";
import BiometricsService from "services/biometrics.service";
import { BiometricsType } from "types/enums";

// i18n
import { useTranslation } from "react-i18next";
import DeleteButton from "components/MDButton/deleteButton";
import DeleteConfirmPop from "components/MDFormField/deleteConfirm";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
    operation: FormOperation;
    residentId: string;
    measurement?: HealthData;
    defaultData?: HealthData;
    callback: (updated: boolean, isDelete?: boolean) => void;
    timeZone?: string;
}

export const MeasurementForm: React.FC<Props> = ({
    operation: ops,
    residentId,
    measurement: initMeasurement,
    defaultData,
    callback,
    timeZone
}) => {
    const lng = localStorage.getItem("i18nextLng");
    const [operation, setOperation] = useState<FormOperation>(ops);
    const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const initValues = initMeasurement;
    const { formField } = form;
    const {
        inputDate,
        systolicBloodPressure,
        diastolicBloodPressure,
        pulse,
        bloodSugar,
        bloodOxygen,
        height,
        weight,
        bmi,
        fat,
        waistline,
        temperature,
    } = formField.measurement;
    const { t: translate } = useTranslation(["common"]);

    const defaultMeasurement: HealthData = {
        bloodSugar: {
            condition: "before-meal",
        },
        ...defaultData,
        inputDate: dayjs().format("YYYY-MM-DDTHH:mm:ss")
    };

    const {
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        control,
        formState: { errors, isDirty, isSubmitting },
    } = useForm<HealthData>({
        defaultValues: isNotNil(initMeasurement) ? initValues : defaultMeasurement,
        resolver: yupResolver(validations.measurement[0]),
        mode: "onChange", // validates onChange, not only onSubmit
    });

    const [watchHeight, watchWeight] = watch(["height", "weight"]); // onChange watch

    useEffect(() => {
        if (isNotNil(initMeasurement) && lng === "tw")
            setValue("bloodSugar.level", initMeasurement.bloodSugar.level * 14);
    }, []);

    const handleResult = (res: any, isDelete?: boolean) => {
        if (res.success) {
            if (isNotNil(callback)) {
                callback(true, isDelete);
            } else {
                navigate("/dashboard");
            }
        } else {
            setError(res.error);
        }
    };

    const onSubmit = async (data: HealthData) => {
        const { bloodSugar, ...rest } = data;
        const { level, condition } = bloodSugar;

        const utcDate = dayjs(rest?.inputDate)
            .local() // Parse as local time
            .utc() // Convert to UTC
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"); // Format in ISO 8601 UTC
      
        let info = {
            inputDate: utcDate,
            systolicBloodPressure: 0,
            diastolicBloodPressure: 0,
            pulse: 0,
            bloodOxygen: 0,
            bloodSugar: {
                condition: "before-meal",
                level: 0,
            },
            height: 0,
            weight: 0,
            bmi: 0,
            fat: 0,
            waistline: 0,
            temperature: 0,
        };
        Object.assign(info, rest);

        info.inputDate = utcDate;


        info.bloodSugar.condition = condition;
        if (lng === "tw") {
            info.bloodSugar.level = isNotNil(level) ? level / 14 : 0;
        } else {
            info.bloodSugar.level = isNotNil(level) ? level : 0;
        }

        let res;
        if (operation === FormOperation.CREATE) {
            res = await handleCreateBiometrics(residentId, info);
        } else {
            res = await handleUpdateBiometrics(info);
        }

        handleResult(res);
    };

    const handleCreateBiometrics = async (residentId: string, data: HealthData) => {
        return BiometricsService.createBiometrics(residentId, data);
    };

    const handleUpdateBiometrics = async (data: HealthData) => {
        const { _id } = initMeasurement;
        return BiometricsService.updateBiometrics(_id, data);
    };

    const handleDeleteBiometrics = async () => {
        const { _id } = initMeasurement;
        const res = await BiometricsService.deleteBiometrics(_id);
        handleResult(res, true);
    };

    const MeasurementUnit = (unit: string) => {
        return (
            <Grid item xs={2} alignSelf="end">
                <MDTypography variant="button" fontWeight="regular">
                    {unit}
                </MDTypography>
            </Grid>
        );
    };

    const renderInputField = (fieldName: any, field: { name: string; type: string }, unit: string, size: string) => {
        // if is blood sugar and is tw, value * 14
        const value =
            fieldName === BiometricsType.BLOOD_SUGAR && lng === "tw"
                ? Math.round(getValues(fieldName) * 14 * 100) / 100
                : getValues(fieldName);
        return (
            <>
                <Grid item {...(size === "large" ? { xs: 6, sm: 4, lg: 3 } : { xs: 6, sm: 2 })}>
                    <FormFieldRHF
                        {...register(fieldName, {
                            valueAsNumber: true,
                            min: 0,
                        })}
                        {...getFormKeys(field, translate(`info.measurement.${field.name}`))}
                        control={control}
                        value={value}
                        helperText={value < 0 && translate("general.notification.negativeNumber")}
                        isDisplayMode={isEditDisabled(operation)}
                    />
                </Grid>
                {MeasurementUnit(unit)}
            </>
        );
    };

    const renderTime = (time: string) => {
        // get yyyy-mm-dd hh:mm format by use dayjs and timezone 
        let _timeZone = timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
        const date = dayjs(time).tz(_timeZone).format("YYYY-MM-DD");
        console.log(time,date);
        return date;
    };

    return (
        <>
            {deleteConfirm && (
                <DeleteConfirmPop
                    title={`${translate("general.popup.delete")}`}
                    handleDelete={handleDeleteBiometrics}
                    setDeleteConfirm={setDeleteConfirm}
                />
            )}
            <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
                <Grid item xs={12}>
                    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <FormLayout
                            header={`${translate(`general.operation.${operation}`)}${
                                operation === FormOperation.CREATE ? translate(`general.state.new`) : ""
                            }${translate(`general.type.${FormType.MEASUREMENT}`)}`}>
                            {operation === FormOperation.VIEW && <EditButton setOperation={setOperation} />}
                            <MDBox mt={1.625}>
                                <MDBox mb={8}>
                                    <Grid container rowSpacing={3} columnSpacing={1} mb={3}>
                                        <Grid item xs={12} sm={6}>
                                            <FormFieldRHF
                                                {...register("inputDate")}
                                                {...getFormKeys(inputDate, translate(`info.measurement.inputDate`))}
                                                // TODO: Bug to be fixed, update mode
                                                value={renderTime(getValues("inputDate"))}
                                                control={control}
                                                editDate={operation !== FormOperation.VIEW ? true : false}
                                                isDisplayMode={operation === FormOperation.VIEW ? true : false}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container rowSpacing={3} columnSpacing={1} mb={5}>
                                        {renderInputField(
                                            "systolicBloodPressure",
                                            systolicBloodPressure,
                                            "mmHg",
                                            "large",
                                        )}
                                        {renderInputField(
                                            "diastolicBloodPressure",
                                            diastolicBloodPressure,
                                            "mmHg",
                                            "large",
                                        )}
                                    </Grid>
                                    <Grid container rowSpacing={3} columnSpacing={1} mb={5}>
                                        {renderInputField("pulse", pulse, "bpm", "small")}
                                        {renderInputField("bloodOxygen", bloodOxygen, "%", "small")}
                                    </Grid>
                                    <Grid container rowSpacing={3} columnSpacing={1} mb={5}>
                                        {renderInputField(
                                            "bloodSugar.level",
                                            bloodSugar.level,
                                            translate("info.measurement.bloodSugarUnit"),
                                            "small",
                                        )}
                                        <Grid item xs={4} sm={2.5}>
                                            <FormSelectRHF
                                                {...register("bloodSugar.condition")}
                                                {...getFormKeys(
                                                    bloodSugar.condition,
                                                    translate("info.measurement.measuredTime"),
                                                )}
                                                options={["before-meal", "after-meal", "anytime"].map((item) => ({
                                                    option: translate(`info.measurement.condition.${item}`),
                                                    value: item,
                                                }))}
                                                defaultValue={
                                                    isEditDisabled(operation)
                                                        ? translate(
                                                              `info.measurement.condition.${getValues(
                                                                  "bloodSugar.condition",
                                                              )}`,
                                                          )
                                                        : getValues("bloodSugar.condition")
                                                }
                                                control={control}
                                                isDisplayMode={isEditDisabled(operation)}
                                            />
                                        </Grid>
                                    </Grid>
                                </MDBox>
                                <MDBox>
                                    <Grid container rowSpacing={3} columnSpacing={1} mb={5}>
                                        {renderInputField("height", height, "cm", "small")}
                                        {renderInputField("weight", weight, "kg", "small")}
                                        {/* BMI auto calculated */}
                                        <Grid item xs={6} sm={2}>
                                            <FormFieldRHF
                                                {...register("bmi", {
                                                    valueAsNumber: true,
                                                    min: 0,
                                                })}
                                                {...getFormKeys(bmi, translate(`info.measurement.${bmi.name}`))}
                                                control={control}
                                                value={
                                                    isNotNil(watchWeight) && isNotNil(watchHeight)
                                                        ? Math.round(
                                                              (watchWeight / ((watchHeight * watchHeight) / 10000)) *
                                                                  10,
                                                          ) / 10
                                                        : 0
                                                }
                                                isDisplayMode={true}
                                            />
                                        </Grid>
                                        {MeasurementUnit("kg/m^2")}
                                        {renderInputField("fat", fat, "%", "small")}
                                        {renderInputField("waistline", waistline, "cm", "small")}
                                        {renderInputField("temperature", temperature, "°C", "small")}
                                    </Grid>
                                </MDBox>
                            </MDBox>
                            {isNotNil(error) && <MDErrorMsg errorMsg={error} />}
                            <SubmitButtons
                                disable={isSubmitting || Object.keys(errors).length !== 0 || !isDirty}
                                operation={operation}
                                setDeleteConfirm={setDeleteConfirm}
                            />
                        </FormLayout>
                    </form>
                </Grid>
            </Grid>
        </>
    );
};

export default MeasurementForm;

import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/userContext";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// formik components
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// New settings layout schemas for form and form fields
import form from "layouts/settings/schemas/form";
import validations from "layouts/settings/schemas/validations/unit";

// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSkeleton from "components/MDSkeleton";
import MDGallery from "components/MDGallery";
import MDErrorMsg from "components/MDErrorMsg";
import { FormFieldRHF, FormFieldScanRHF, FormSelect, FormSelectRHF } from "components/MDFormField";
import FormTitle from "components/MDFormField/title";
import SubmitButtons from "components/MDButton/submitButton";
import EditButton from "components/MDButton/editButton";
import DeleteConfirmPop from "components/MDFormField/deleteConfirm";
import FormLayout from "examples/LayoutContainers/FormLayout";
import { UploadableDropzone } from "components/MDFormField/uploadableImage";

// Service
import CenterService from "services/center.service";
import UnitService from "services/unit.service";
import ResidentService from "services/resident.service";

// Types
import { FormOperation, FormType, RoomType, WisRoomType } from "types/enums";
import { Center, Unit, RoomDevice, Device } from "types/setting-schema";

// Helpers
import {
    getCamelCase,
    isEditDisabled,
    getCenterByName,
    getFormKeys,
    NOT_SELECTED,
    uploadFile,
    deleteFile,
    fileRead,
    checkStatus,
} from "helpers/formUtil";
import { isNotNil, isNil } from "helpers/utils";

// i18n
import { useTranslation } from "react-i18next";
import { isRelAdmin } from "types/user-schema";
import { BedType } from "types/wistron-schema";
import { get } from "http";
import { array } from "yup";
import { Roles } from "types/roles";
import { item } from "examples/Sidenav/styles/sidenavItem";

interface Props {
    operation: FormOperation;
    businessId?: string;
    unit?: Unit;
    callback: (updated: boolean, isDelete?: boolean) => void;
}

interface ImageModalProps {
    roomIndex: number;
    imageIndex: number;
    type: string;
    values: RoomDevice;
}

export const UnitForm: React.FC<Props> = ({ operation: ops, businessId, unit: initUnit, callback }) => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [operation, setOperation] = useState<FormOperation>(ops);
    const { formField } = form;
    const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
    const [centerInfo, setCenterInfo] = useState<Center[] | undefined>(undefined);
    const [isCentralized, setIsCentralized] = useState<boolean>(false);
    const [imageModal, setImageModal] = useState<ImageModalProps | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [floorRoom, setFloorRoom] = useState([]);
    const [floors, setFloors] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [deviceXInfo, setDeviceXInfo] = useState<any>(null);
    const [deviceInfo, setDeviceInfo] = useState<any>(null);
    const [wistronResident, setWistronResident] = useState<any>(null);
    const [wistronRoom, setWistronRoom] = useState<any>(null);
    const [invalidSN, setInvalidSN] = useState<boolean>(false);

    const [oldValue, setOldValue] = useState<any>({
        centerName: "",
        floor: NOT_SELECTED,
        unitNumber: NOT_SELECTED,
        numberOfResidents: 1,
        address: "",
        description: "",
        roomType: BedType.Single,
        roomDevices: [
            {
                room: WisRoomType.BEDROOM,
                installationPhotoUrls: [],
                uploadableImages: [],
                devices: [
                    {
                        status: "not installed",
                        serialNumber: "",
                        model: "care",
                    },
                    {
                        status: "not installed",
                        serialNumber: "",
                        model: "vital",
                    },
                ],
            },
        ],
    });

    const {
        centerName,
        floor,
        address,
        unitNumber,
        numberOfResidents,
        description,
        roomDevices,
        anderDevices,
        roomType,
    } = formField.unit;
    const { t: translate } = useTranslation(["common"]);
    const notSelectedOption = useMemo(
        () => ({ option: translate(`general.state.notSelected`), value: NOT_SELECTED }),
        [translate],
    );
    const noSelectOption = useMemo(
        () => ({ option: translate(`general.state.noData`), value: NOT_SELECTED }),
        [translate],
    );
    const defaultUnit: Unit = useMemo(
        () => ({
            centerName: "",
            floor: NOT_SELECTED,
            unitNumber: NOT_SELECTED,
            numberOfResidents: 1,
            address: "",
            description: "",
            roomType: BedType.Single,
            roomDevices: [
                {
                    room: WisRoomType.BEDROOM,
                    installationPhotoUrls: [],
                    uploadableImages: [],
                    devices: [
                        {
                            status: "not installed",
                            serialNumber: "",
                            model: "care",
                        },
                        {
                            status: "not installed",
                            serialNumber: "",
                            model: "vital",
                        },
                    ],
                },
            ],
        }),
        [],
    );
    const handleInitUnit = (initUnit: Unit): Unit => {
        if (isNotNil(initUnit)) {
            let anderDevice;
            const { roomDevices } = initUnit;

            if (isNotNil(roomDevices)) {
                roomDevices.forEach((item) => {
                    item.devices.forEach((device) => {
                        if (device.model === "vital") {
                            anderDevice = {
                                room: device.room,
                                status: device.status,
                                serialNumber: device.serialNumber,
                                model: device.model,
                            };
                        }
                    });
                });
            }
            console.log(123, { ...initUnit, anderDevices: anderDevice });
            return { ...initUnit, anderDevices: anderDevice };
        }

        return initUnit;
    };

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        reset,
        watch,
        control,
        formState: { errors, isDirty, isSubmitting, touchedFields },
    } = useForm<Unit>({
        defaultValues: isNotNil(initUnit) ? handleInitUnit(initUnit) : defaultUnit,
        resolver: yupResolver(validations.unit[0]),
        mode: "onChange", // validates onChange, not only onSubmit
    });
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: "roomDevices",
    });
    const [watchCenterName, watchFloor, watchUnit, watchRoomDevices] = watch([
        "centerName",
        "floor",
        "unitNumber",
        "roomDevices",
    ]); // onChange watch, for success, error purposes
    useEffect(() => {
        console.log(watchRoomDevices);
    }, [watchRoomDevices]);
    useEffect(() => {
        const fetchDeviceXInfo = async () => {
            try {
                const res = await ResidentService.getWistronLocationInfosWithAnderX();
                if (res.data && res.data.andarXdeviceList) {
                    setDeviceXInfo(res.data.andarXdeviceList);
                } else {
                    setDeviceXInfo([]);
                }
            } catch (error) {
                console.error("Error fetching device X info:", error);
                setDeviceXInfo([]);
            }
        };
        const fetchDeviceInfo = async () => {
            try {
                const res = await ResidentService.getWistronLocationInfos();
                if (res.data?.deviceList) {
                    setDeviceInfo(res.data.deviceList);
                } else {
                    setDeviceInfo([]);
                }
            } catch (error) {
                console.error("Error fetching device info:", error);
                setDeviceInfo([]);
            }
        };
        const fetchWistronResident = async () => {
            try {
                const res = await ResidentService.getAllWistronResident();
                if (res.data) {
                    setWistronResident(res.data);
                } else {
                    setWistronResident([]);
                }
            } catch (error) {
                console.error("Error fetching Wistron residents:", error);
                setWistronResident([]);
            }
        };

        const fetchWistronRoom = async () => {
            const res = await UnitService.getAllWistronRoom();
            if (res.success) {
                setWistronRoom(res.data);
            }
        };

        if (isNotNil(businessId)) {
            fetchWistronResident();
            fetchDeviceXInfo();
            fetchDeviceInfo();
            fetchWistronRoom();
        }
    }, []);

    useEffect(() => {
        if (isNotNil(initUnit)) {
            setOldValue({ ...initUnit });
        }
    }, [initUnit]);

    useEffect(() => {
        const fetchCenters = async () => {
            const res = await CenterService.getAllCenters(businessId);
            if (res.success) {
                const ary = res.data;
                if (isNil(initUnit)) {
                    reset({
                        ...defaultUnit,
                        centerName: ary[0].name,
                        address: ary[0].isCentralized ? ary[0].address : "",
                    });
                }
                setCenterInfo(ary);
            }
        };
        if (isNotNil(businessId)) {
            // if (isNotNil(businessId) && !isEditDisabled(operation)) {
            fetchCenters();
        }
    }, [businessId, operation]);

    // Update is centralized
    useEffect(() => {
        if (isNotNil(centerInfo)) {
            const isCentralized_ = getCenterByName(centerInfo, watchCenterName)?.isCentralized;
            setIsCentralized(isCentralized_);
            if (isCentralized_) {
                setValue("unitNumber", operation !== FormOperation.CREATE ? watchUnit : NOT_SELECTED);
            } else {
                //spite unitNumber with -
                const _unitNumber = watchUnit.split("-")[0];
                setValue("unitNumber", _unitNumber);
            }
            // setValue("unitNumber", isCentralized_ ? NOT_SELECTED : "");
            const { _id } = getCenterByName(centerInfo, watchCenterName);
            fetchAvailableFloorAndRoom(_id);
        }
    }, [watchCenterName, centerInfo]);

    useEffect(() => {
        if (isNotNil(floorRoom)) {
            const floors = [...new Set(floorRoom.map((item: any) => item.floor))];
            setFloors(floors);
        }
    }, [floorRoom]);

    useEffect(() => {
        if (isNotNil(watchFloor)) {
            const room = floorRoom.find((room) => room.floor === watchFloor);

            if (isNotNil(room)) {
                const wistronAvailableRoom = wistronRoom?.filter((room: any) => room.item.floor === watchFloor);
                let rooms = JSON.parse(room.setting);
                rooms = rooms
                    .filter((item: any) => item.room !== "")
                    .map((item: any) => ({
                        option: item.room,
                        value: item.room,
                    }))
                    .filter(
                        (item: any) =>
                            !wistronAvailableRoom?.map((room: any) => room.item.roomName).includes(item.value) ||
                            getValues("unitNumber") === item.value,
                    );
                // if(getValues("unitNumber") !== NOT_SELECTED){
                //     rooms.push({ option: getValues("unitNumber"), value: getValues("unitNumber") });
                // }
                setRooms(rooms);
            }
        }
    }, [watchFloor, floorRoom, wistronRoom]);

    const getAvailableRoomType = () => {
        const usedRoomType = watchRoomDevices.map((room) => room.room);
        const RoomTypeList = [
            WisRoomType.LIVING_ROOM,
            WisRoomType.BEDROOM,
            WisRoomType.BATHROOM,
            WisRoomType.KITCHEN,
            WisRoomType.BEDROOM, // Second BEDROOM entry
            WisRoomType.LIVING_ROOM,
            WisRoomType.LIVING_ROOM,
        ];

        // Count occurrences of each room type in usedRoomType
        const roomTypeCount = usedRoomType.reduce((acc: any, room: any) => {
            acc[room] = (acc[room] || 0) + 1;
            return acc;
        }, {});

        return RoomTypeList.map((room) => {
            const option = translate(`setting.unit.roomType.${getCamelCase(room)}`);
            const value = room;

            // Logic to determine if the room type should be disabled
            let disabled;
            if (room === WisRoomType.BEDROOM) {
                // Allow up to 2 BEDROOMs; disable only if count is 2 or more
                disabled = (roomTypeCount[room] || 0) >= 2;
            } else if (room === WisRoomType.LIVING_ROOM) {
                // Allow up to 2 BEDROOMs; disable only if count is 2 or more
                disabled = (roomTypeCount[room] || 0) >= 3;
            } else {
                // For other room types, disable if used even once
                disabled = usedRoomType.includes(room);
            }

            return {
                option,
                value,
                disabled,
            };
        });
    };

    const fetchAvailableFloorAndRoom = async (id: string) => {
        const res = await UnitService.getWistronRoomList(id);
        if (res.success) {
            setFloorRoom(res.data);
        }
    };

    const getUnUsedDevice = (wistronResident: any, deviceInfo: any, oldValue: string) => {
        if (!wistronResident || !deviceInfo) {
            return [notSelectedOption];
        }
        // Extract used device IDs
        const usedDevice = wistronResident.map((resident: any) => resident.deviceid);
        // Filter for unused devices
        const unUsedDevice = deviceInfo.filter((device: any) => !usedDevice.includes(device.id));
        // Transform to option-value objects
        const optionValueArray = unUsedDevice.map((device: any) => ({
            option: device.id, // Assuming 'name' is the property to be used as option
            value: device.id, // Use 'deviceid' as the value
        }));
        if (oldValue !== NOT_SELECTED) {
            optionValueArray.push({ option: oldValue, value: oldValue });
        }
        if (optionValueArray.length === 0) {
            return [noSelectOption];
        }
        return optionValueArray;
    };

    const getDeviceX = (oldValue: string) => {
        if (!deviceXInfo) {
            return [noSelectOption];
        }

        //filter out the devices that are used at watchRoomDevices
        const usedDevices = watchRoomDevices.reduce(
            (acc, room) => acc.concat(room.devices.map((device) => device.serialNumber)),
            [],
        );
        const optionValueArray = deviceXInfo // Filter out used devices
            .map((device: any) => ({
                option: device,
                value: device,
                disabled: usedDevices.includes(device),
            }));
        if (optionValueArray.length === 0) {
            return [noSelectOption];
        }
        if (oldValue !== NOT_SELECTED) {
            optionValueArray.push({ option: oldValue, value: oldValue });
        }
        return optionValueArray;
    };
    const handleResult = (res: any, isDelete?: boolean) => {
        if (res.success) {
            if (isNotNil(callback)) {
                callback(true, isDelete);
            } else {
                navigate("/dashboard");
            }
        } else {
            setError(res.error);
        }
    };

    const getRoomArray = (roomDevices: RoomDevice[]): Promise<any> => {
        return Promise.all(
            roomDevices.map(async (item: RoomDevice) => {
                handleDeleteImages(item.installationPhotoUrls, item.roomId);
                if (isNotNil(item.uploadableImages)) {
                    const uploadRes = await handleUploadImages(item.uploadableImages);
                    if (isNotNil(uploadRes)) item.installationPhotoUrls = [...item.installationPhotoUrls, ...uploadRes];
                }
                return item;
            }),
        );
    };

    const handleUploadImages = (files: object[]): Promise<any> => {
        if (files.length === 0) return null;
        const path = `${centerInfo[0].business.name}/${businessId}/${FormType.UNIT}`;
        return Promise.all(
            files.map(async (file: object) => {
                return await uploadFile(path, file);
            }),
        );
    };

    const handleDeleteImages = (URLs: string[], roomId: string) => {
        // Get initial urls
        const initUrls = getValues("roomDevices").find((item) => roomId === item.roomId).installationPhotoUrls;
        if (isNil(initUrls)) return;

        // Filter those elements not in the original array
        const nonExistImages = initUrls.filter((url: string) => !URLs?.includes(url));

        if (nonExistImages.length === 0) return;
        nonExistImages.forEach(async (url: string) => {
            await deleteFile(url);
        });
    };

    // Triggered if the whole unit, or a room is deleted
    const handleDeleteAllImages = (removedRooms?: RoomDevice[]) => {
        const rooms = isNil(removedRooms) ? initUnit.roomDevices : removedRooms;

        if (rooms.length === 0) return;
        rooms.forEach((item: RoomDevice) => {
            if (isNil(item.installationPhotoUrls)) return;

            item.installationPhotoUrls.forEach(async (url: string) => {
                await deleteFile(url);
            });
        });
    };

    const onSubmit = async (data: any) => {
        setError(null);
        const { _id } = getCenterByName(centerInfo, data.centerName);
        const { center, address, floor, roomDevices, unitNumber, ...updatedValue } = data;

        //  if isCentralized, don't store address, else don't store floor
        const info = isCentralized
            ? { ...updatedValue, floor: floor, unitNumber: unitNumber }
            : { ...updatedValue, address: address, floor: null, unitNumber: unitNumber };
        info.center = _id;

        // rooms.map((item) => {
        //     if (item.value == unitNumber) return (info.unitNumber = item.option);
        // });
        info.roomDevices = await getRoomArray(roomDevices);

        // if not relAdmin and installer, don't store roomDevices when create
        if (
            currentUser.role !== Roles.relAdmin &&
            currentUser.role !== Roles.installer &&
            operation === FormOperation.CREATE
        ) {
            info.roomDevices = [];
        }
        let res;
        if (operation === FormOperation.CREATE) {
            res = await handleCreateUnit(_id, info);
        } else {
            res = await handleUpdateUnit(info);
        }

        handleResult(res);
    };

    const handleCreateUnit = async (id: string, unit: Unit) => {
        return UnitService.createUnit(id, unit);
    };

    const handleUpdateUnit = async (unit: Unit) => {
        const id = getValues("roomDevices").map((item) => item.roomId);
        // Get rooms that are removed
        const removedRooms = initUnit.roomDevices.filter((room) => !id.includes(room.roomId));
        handleDeleteAllImages(removedRooms);
        const { _id } = initUnit;
        return UnitService.updateSingleUnit(_id, unit);
    };

    const handleDeleteUnit = async () => {
        handleDeleteAllImages();
        const { _id } = initUnit;
        const res = await UnitService.deleteSingleUnit(_id);
        handleResult(res, true);
    };

    const handleOpenImageModal = (roomIndex: number, imageIndex?: number, type?: string) => {
        setImageModal({
            roomIndex: roomIndex,
            imageIndex: imageIndex,
            type: type,
            values: getValues(`roomDevices.${roomIndex}`),
        });
    };

    const isEditModeByRole = (operation: FormOperation) => {
        if ((isRelAdmin(currentUser) || currentUser.role === "installer") && operation !== FormOperation.VIEW) {
            return true;
        }
        return false;
    };

    // Render floor: Display if isCentralized
    const renderFloor = () => {
        if (!isEditDisabled(operation)) {
            return (
                isCentralized && (
                    <Grid item xs={12} sm={2}>
                        <FormSelectRHF
                            {...register("floor")}
                            options={[
                                notSelectedOption,
                                // { option:oldValue.floor, value:oldValue.floor },
                                ...floors.map((item: any) => ({
                                    option: item,
                                    value: item,
                                })),
                            ]}
                            {...getFormKeys(floor, translate(`setting.unit.floor`))}
                            control={control}
                            defaultValue={getValues("floor")}
                            error={watchFloor === NOT_SELECTED && touchedFields.floor}
                            helperText={errors.floor?.message}
                            success={getValues("floor")?.length > 0 && !errors.floor?.message}
                            isDisplayMode={isEditDisabled(operation)}
                        />
                    </Grid>
                )
            );
        }
        return isNotNil(getValues("floor")) && isCentralized ? (
            <Grid item xs={12} sm={2}>
                <FormFieldRHF
                    {...getFormKeys(floor, translate(`setting.unit.floor`))}
                    control={control}
                    value={getValues("floor")}
                    isDisplayMode={isEditDisabled(operation)}
                />
            </Grid>
        ) : (
            <Grid item xs={12} sm={2}></Grid>
        );
    };

    const renderRoom = () => {
        if (!isEditDisabled(operation)) {
            return isCentralized ? (
                <Grid item xs={12} sm={2}>
                    <FormSelectRHF
                        {...register("unitNumber")}
                        options={[notSelectedOption, ...rooms]}
                        {...getFormKeys(unitNumber, translate(`setting.unit.unitNumber`))}
                        control={control}
                        defaultValue={getValues("unitNumber")}
                        value={getValues("unitNumber")}
                        // error={!!errors.unitNumber && touchedFields.unitNumber}
                        error={watchFloor === NOT_SELECTED && touchedFields.floor && !!errors.unitNumber}
                        isDisplayMode={isEditDisabled(operation)}
                        helperText={errors.unitNumber?.message}
                        success={getValues("unitNumber")?.length > 0 && !errors.unitNumber?.message}
                    />
                </Grid>
            ) : (
                <Grid item xs={12} sm={2}>
                    <FormFieldRHF
                        {...register("unitNumber")}
                        {...getFormKeys(unitNumber, translate(`setting.unit.unitNumber`))}
                        control={control}
                        value={getValues("unitNumber")}
                        error={!!errors.unitNumber && touchedFields.unitNumber}
                        helperText={errors.unitNumber?.message}
                        success={getValues("unitNumber")?.length > 0 && !errors.unitNumber?.message}
                        isDisplayMode={isEditDisabled(operation)}
                    />
                </Grid>
            );
        }
        return (
            isNotNil(getValues("unitNumber")) && (
                <Grid item xs={12} sm={2}>
                    <FormFieldRHF
                        {...getFormKeys(unitNumber, translate(`setting.unit.unitNumber`))}
                        control={control}
                        value={getValues("unitNumber")}
                        isDisplayMode={isEditDisabled(operation)}
                    />
                </Grid>
            )
        );
    };

    const renderAddress = () => {
        if (!isEditDisabled(operation)) {
            return (
                !isCentralized && (
                    <Grid item xs={12}>
                        <FormFieldRHF
                            {...register("address")}
                            {...getFormKeys(address, translate(`setting.unit.address`))}
                            control={control}
                            value={getValues("address")}
                            isDisplayMode={isEditDisabled(operation)}
                        />
                    </Grid>
                )
            );
        }
        return (
            isNotNil(getValues("address")) && (
                <Grid item xs={12}>
                    <FormFieldRHF
                        {...getFormKeys(address, translate(`setting.unit.address`))}
                        control={control}
                        value={getValues("address")}
                        isDisplayMode={isEditDisabled(operation)}
                    />
                </Grid>
            )
        );
    };

    // Render image preview
    const renderPreview = (index: number) => {
        const UrlLength = getValues(`roomDevices.${index}.installationPhotoUrls`)?.length ?? 0;
        const renderFiles = (file: string | object, idx: number, type: string) => {
            // If is uploadable image, have to add url length first
            const photoIndex = type === "url" ? idx : UrlLength + idx;
            return (
                <MDBox
                    key={idx}
                    sx={{ cursor: "pointer", overflow: "hidden", width: "50px", height: "50px" }}
                    ml={1}
                    onClick={() => handleOpenImageModal(index, photoIndex, "gallery")}
                    borderRadius="8px">
                    <img src={fileRead(file)} alt="Uploaded File" width="100%" height="100%" />
                </MDBox>
            );
        };

        return (
            <MDBox display="flex">
                <MDBox {...register(`roomDevices.${index}.installationPhotoUrls`)} display="flex" alignItems="center">
                    {getValues(`roomDevices.${index}.installationPhotoUrls`)?.map((item, idx) =>
                        renderFiles(item, idx, "url"),
                    )}
                </MDBox>
                {isNotNil(getValues(`roomDevices.${index}.uploadableImages`)) && (
                    <MDBox {...register(`roomDevices.${index}.uploadableImages`)} display="flex" alignItems="center">
                        {getValues(`roomDevices.${index}.uploadableImages`)?.map((item, idx) =>
                            renderFiles(item, idx, "uploadable"),
                        )}
                    </MDBox>
                )}
            </MDBox>
        );
    };

    // Only REL_ADMIN can edit, other roles view only
    const renderRoomSelection = (index: number) => {
        return (
            <>
                <FormSelectRHF
                    {...register(`roomDevices.${index}.room`)}
                    options={
                        index === 0
                            ? [
                                  {
                                      option: translate(`setting.unit.roomType.${getCamelCase("bedRoom")}`),
                                      value: "bedRoom",
                                  },
                              ]
                            : getAvailableRoomType()
                    }
                    type={roomDevices.room.type}
                    control={control}
                    defaultValue={
                        isEditModeByRole(operation)
                            ? getValues(`roomDevices.${index}.room`)
                            : translate(`setting.unit.roomType.${getCamelCase(getValues(`roomDevices.${index}.room`))}`)
                    }
                    handleOnChange={() => {
                        // If room is bedroom, set devices to two
                        if (watchRoomDevices[index].room === WisRoomType.BEDROOM && index === 0) {
                            setValue(`roomDevices.${index}.devices`, [
                                {
                                    status: "not installed",
                                    serialNumber: "",
                                    model: "care",
                                },
                                {
                                    status: "not installed",
                                    serialNumber: "",
                                    model: "vital",
                                },
                            ]);
                        } else {
                            setValue(`roomDevices.${index}.devices`, [
                                {
                                    status: "not installed",
                                    serialNumber: "",
                                    model: "care",
                                },
                            ]);
                        }
                    }}
                    isDisplayMode={!isEditModeByRole(operation)}
                />
            </>
        );
    };

    const renderDeviceXSettingHeader = () => {
        if (
            !isRelAdmin(currentUser) &&
            currentUser.role !== "installer" &&
            (isNil(initUnit?.devices) || initUnit?.devices?.length === 0)
        ) {
            return <></>;
        }

        return (
            <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <FormTitle content={`${translate(`setting.title.deviceSetting`)}`} />
                {isEditModeByRole(operation) && watchRoomDevices?.length <= 6 && (
                    <MDButton
                        variant="outlined"
                        type="button"
                        onClick={() => {
                            const availableRoomTypes = getAvailableRoomType();
                            const firstUnusedRoomType = availableRoomTypes.find((room) => !room.disabled)?.value;

                            append({
                                room: firstUnusedRoomType,
                                devices: [
                                    {
                                        status: "not installed",
                                        serialNumber: "",
                                        model: "care",
                                    },
                                ],
                            });
                        }}>
                        + {translate(`general.button.addRoom`)}
                    </MDButton>
                )}
            </MDBox>
        );
    };
    const renderDeviceXSettingDetail = () => {
        if (
            !isRelAdmin(currentUser) &&
            currentUser.role !== "installer" &&
            (isNil(initUnit?.devices) || initUnit?.devices?.length === 0)
        ) {
            return <></>;
        }

        return fields.map((device, index) => {
            if (
                device.room === WisRoomType.BEDROOM &&
                device.devices?.length === 1 &&
                device.devices[0].model === "vital" &&
                operation !== FormOperation.CREATE
            ) {
                return;
            }
            return (
                <MDBox key={device.id} my={1}>
                    <Grid container alignItems="end">
                        <Grid item xs={0.5} md={0.5}>
                            <MDTypography variant="subtitle2">{index + 1}.</MDTypography>
                        </Grid>
                        <Grid item xs={1.5} md={1}>
                            <MDTypography variant="subtitle2">
                                {translate(`setting.unit.roomDevices.room`)}
                            </MDTypography>
                        </Grid>
                        <Grid item xs={3} md={2} mr={2}>
                            {renderRoomSelection(index)}
                        </Grid>
                        <Grid item xs={2} md={4} mr={2}>
                            {renderPreview(index)}
                        </Grid>
                        {isEditModeByRole(operation) && (
                            <>
                                <Grid item xs={3} md={2} mr={2}>
                                    <MDButton
                                        variant="outlined"
                                        type="button"
                                        onClick={() => handleOpenImageModal(index, null, "dropzone")}>
                                        + {translate(`general.button.addPhoto`)}
                                    </MDButton>
                                </Grid>
                                {(fields.length > 1 && index !== 0 ) && (
                                    <MDButton
                                        variant="outlined"
                                        color="dark"
                                        type="button"
                                        sx={{
                                            px: 1,
                                        }}
                                        onClick={() => remove(index)}>
                                        <Icon>delete</Icon> {translate(`general.button.delete`)}
                                    </MDButton>
                                )}
                            </>
                        )}
                    </Grid>
                    {renderDevicesSelection(index)}
                </MDBox>
            );
        });
    };

    const setDefaultAnderX = (array: any[], index: number, idx: number) => {
        // Loop to find the first anderXDevice that starts with "xx" and is not disabled
        let anderXDevice = array.find((item) => {
            if (item.value) return item.value.startsWith("xx") && !item.disabled;
        });

        if (anderXDevice) {
            // Case 1: An item starting with "xx" and not disabled is found
            if (
                getValues(`roomDevices.${index}.devices.${idx}.serialNumber`) === NOT_SELECTED ||
                getValues(`roomDevices.${index}.devices.${idx}.serialNumber`) === ""
            ) {
                setValue(`roomDevices.${index}.devices.${idx}.serialNumber`, anderXDevice.value);
            }
        } else {
            // Case 2: No item starts with "xx" (or all "xx" items are disabled)
            if (
                getValues(`roomDevices.${index}.devices.${idx}.serialNumber`) === NOT_SELECTED ||
                getValues(`roomDevices.${index}.devices.${idx}.serialNumber`) === ""
            ) {
                setValue(`roomDevices.${index}.devices.${idx}.serialNumber`, "not installed");
            }
        }
    };

    const checkSNValid = (SN: string, type: string): boolean => {
        // 獲取所有已使用的序列號
        const usedDevices = watchRoomDevices.flatMap((room) => room.devices.map((device) => device.serialNumber));
        // 根據類型選擇對應的設備映射
        const deviceMap = type === "care" ? deviceXInfo : deviceInfo;

        // 檢查序列號是否存在於 deviceMap 且未被使用
        let result = deviceMap?.some(
            (item: any) =>
                (type !== "care" ? item.id === SN : item === SN) &&
                usedDevices.indexOf(SN) === usedDevices.lastIndexOf(SN),
        );

        console.log(usedDevices, usedDevices.filter((d) => d === SN).length, result);
        return result;
    };
    const hasIssues =
        watchRoomDevices.some((room) =>
            room.devices.some((device) => device.serialNumber.length === 0 || device.serialNumber === NOT_SELECTED),
        ) ||
        (() => {
            const serials = watchRoomDevices
                .flatMap((room) => room.devices.map((device) => device.serialNumber))
                .filter((sn) => sn.length > 0 && sn !== NOT_SELECTED);
            return serials.length !== new Set(serials).size;
        })();

    const renderDevicesSelection = (index: number) => {
        // if (currentUser.role === Roles.installer) {
        //     return;
        // }
        const checkingRole = (index: number, item: Device, idx: number) => {
            if (currentUser.role === Roles.installer) {
                return installerRHF(index, item, idx);
            } else {
                // return insiderRHF(item, idx);
                return insiderRHF(index, item, idx);
            }
        };
        const insiderRHF = (index: number, item: Device, idx: number) => {
            return (
                <FormSelectRHF
                    {...register(`roomDevices.${index}.devices.${idx}.serialNumber`)}
                    // {...getFormKeys(anderDevices, translate(`resident.deviceSN`))}
                    // name={translate(`resident.deviceSN`)}
                    label={item.model === "care" ? translate(`resident.deviceSN`) : translate(`resident.deviceXSN`)}
                    control={control}
                    type={roomDevices.devices.serialNumber.type}
                    value={getValues(`roomDevices.${index}.devices.${idx}.serialNumber`)}
                    handleOnChange={() => {
                        if (item.serialNumber.length === 0)
                            setValue(`roomDevices.${index}.devices.${idx}.status`, "not installed");
                    }}
                    // getValues(`roomDevices.${index}.devices.${idx}.serialNumber`),
                    options={
                        item.model === "care"
                            ? getDeviceX(oldValue.roomDevices[index]?.devices[idx]?.serialNumber)
                            : getUnUsedDevice(
                                  wistronResident,
                                  deviceInfo,
                                  oldValue.roomDevices[index]?.devices[idx]?.serialNumber,
                              )
                    }
                    success={
                        watchRoomDevices[index].room === WisRoomType.BEDROOM
                            ? item.model === "care" && null
                            : getValues(`roomDevices.${index}.devices.${idx}.serialNumber`)?.length > 0 &&
                              !errors?.roomDevices?.[index]?.devices?.[idx]?.serialNumber
                    }
                    //@ts-ignore
                    defaultValue={
                        getValues(`roomDevices.${index}.devices.${idx}.serialNumber`)?.length > 0 &&
                        !isEditModeByRole(operation)
                            ? getValues(`roomDevices.${index}.devices.${idx}.serialNumber`)
                            : setDefaultAnderX(
                                  getDeviceX(oldValue.roomDevices[index]?.devices[idx]?.serialNumber),
                                  index,
                                  idx,
                              )
                    }
                    isDisplayMode={!isEditModeByRole(operation)}
                />
            );
        };
        const installerRHF = (index: number, item: Device, idx: number) => {
            return (
                <FormFieldScanRHF
                    {...register(`roomDevices.${index}.devices.${idx}.serialNumber`)}
                    label={item.model === "care" ? translate(`resident.deviceSN`) : translate(`resident.deviceXSN`)}
                    control={control}
                    type={roomDevices.devices.serialNumber.type}
                    value={getValues(`roomDevices.${index}.devices.${idx}.serialNumber`) || ""}
                    handleOnChange={(e) => {
                        if (item.serialNumber.length === 0) {
                            setValue(`roomDevices.${index}.devices.${idx}.status`, "not installed");
                        }
                    }}
                    helperText={
                        !checkSNValid(
                            getValues(`roomDevices.${index}.devices.${idx}.serialNumber`) || "",
                            item.model,
                        ) && translate("Invalid SN")
                    }
                    success={
                        !!getValues(`roomDevices.${index}.devices.${idx}.serialNumber`) && // 確保序列號非空
                        checkSNValid(getValues(`roomDevices.${index}.devices.${idx}.serialNumber`) || "", item.model)
                    }
                    defaultValue={
                        getValues(`roomDevices.${index}.devices.${idx}.serialNumber`)?.length > 0 &&
                        !isEditModeByRole(operation) &&
                        getValues(`roomDevices.${index}.devices.${idx}.serialNumber`)
                    }
                    isDisplayMode={!isEditModeByRole(operation)}
                    onScanComplete={(value: string) => {
                        if (checkSNValid(value, item.model)) {
                            setValue(`roomDevices.${index}.devices.${idx}.serialNumber`, value);
                        } else {
                            // 如果序列號無效，顯示提示但不更新值，讓用戶手動輸入
                            alert(translate("Invalid SN, please enter manually"));
                        }
                        return checkSNValid(value, item.model); // 返回布林值，決定是否繼續掃描
                    }}
                />
            );
        };
        return watchRoomDevices[index].devices.map((item, idx) => {
            // if (item.model === "vital") return;
            return (
                <Grid container my={2} key={idx} alignItems="end">
                    {/* <Grid item xs={0.5} md={0.5}></Grid> */}
                    <Grid item xs={1.5} md={1}>
                        {idx === 0 && (
                            <MDTypography variant="subtitle2" sx={{ fontSize: { xs: "0.75rem" } }}>
                                {translate(`setting.unit.roomDevices.device`)}
                            </MDTypography>
                        )}
                    </Grid>
                    <Grid item xs={10.5} md={10.5}>
                        <Grid container alignItems="end">
                            <Grid item xs={1.5} md={1}>
                                <MDTypography variant="subtitle2" sx={{ fontSize: { xs: "0.75rem" } }}>
                                    {idx === 0 ? "Care" : "Vital"}
                                </MDTypography>
                            </Grid>
                            <Grid item xs={6} md={2.5}>
                                {checkingRole(index, item, idx)}
                            </Grid>
                            <Grid item xs={1.5} md={1.5} pl={{ md: 2 }}>
                                <MDTypography variant="subtitle2" sx={{ fontSize: { xs: "0.75rem" } }}>
                                    {translate(`setting.unit.roomDevices.status`)}
                                </MDTypography>
                            </Grid>
                            <Grid item xs={3} md={2.5}>
                                <FormSelectRHF
                                    {...register(`roomDevices.${index}.devices.${idx}.status`)}
                                    options={
                                        item.serialNumber.length !== 0
                                            ? [
                                                  {
                                                      option: translate("setting.unit.installStatus.notInstalled"),
                                                      value: "not installed",
                                                  },
                                                  {
                                                      option: translate("setting.unit.installStatus.installed"),
                                                      value: "installed",
                                                  },
                                              ]
                                            : [
                                                  {
                                                      option: translate("setting.unit.installStatus.notInstalled"),
                                                      value: "not installed",
                                                  },
                                              ]
                                    }
                                    type={roomDevices.devices.status.type}
                                    control={control}
                                    defaultValue={
                                        isEditModeByRole(operation)
                                            ? getValues(`roomDevices.${index}.devices.${idx}.status`)
                                            : translate(
                                                  `setting.unit.installStatus.${getCamelCase(
                                                      getValues(`roomDevices.${index}.devices.${idx}.status`),
                                                  )}`,
                                              )
                                    }
                                    isDisplayMode={!isEditModeByRole(operation)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
        });
    };

    if (!isEditDisabled(operation) && isNil(centerInfo)) return <MDSkeleton />;

    return (
        <>
            {deleteConfirm && (
                <DeleteConfirmPop
                    title={`${translate("general.popup.delete")}`}
                    handleDelete={handleDeleteUnit}
                    setDeleteConfirm={setDeleteConfirm}
                />
            )}
            {isNotNil(imageModal) &&
                (imageModal.type === "dropzone" ? (
                    <UploadableDropzone
                        name="installationPhotoUrls"
                        imageUrl={imageModal.values.installationPhotoUrls ?? []}
                        uploadableFiles={imageModal.values.uploadableImages ?? []}
                        setImageModal={setImageModal}
                        control={control}
                        handleOnChange={(URLs: string[], files: object[]) => {
                            setValue(`roomDevices.${imageModal.roomIndex}.installationPhotoUrls`, URLs);
                            setValue(`roomDevices.${imageModal.roomIndex}.uploadableImages`, files);
                        }}
                    />
                ) : (
                    <MDGallery
                        imageUrl={imageModal.values.installationPhotoUrls ?? []}
                        uploadableFiles={imageModal.values.uploadableImages ?? []}
                        roomType={translate(`setting.unit.roomType.${getCamelCase(imageModal.values.room)}`)}
                        index={imageModal.imageIndex}
                        status={
                            checkStatus(imageModal.values.devices)
                                ? translate(`setting.unit.installStatus.installed`)
                                : translate(`setting.unit.installStatus.notInstalled`)
                        }
                        setImageModal={setImageModal}
                    />
                ))}
            <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
                <Grid item xs={12}>
                    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <FormLayout
                            header={`${translate(`general.operation.${operation}`)}${
                                operation === FormOperation.CREATE ? translate(`general.state.new`) : ""
                            }${translate(`general.type.${FormType.UNIT}`)}`}
                            name={operation !== FormOperation.CREATE ? initUnit?.unitNumber : ""}>
                            {operation === FormOperation.VIEW && currentUser.role !== "installer" && (
                                <EditButton setOperation={setOperation} />
                            )}
                            <MDBox mt={1.625}>
                                <MDBox mb={5}>
                                    <Grid container spacing={3} mb={3}>
                                        <Grid item xs={12} sm={4.5}>
                                            <FormSelectRHF
                                                {...register("centerName")}
                                                {...getFormKeys(centerName, translate(`setting.center.name`))}
                                                options={centerInfo?.map((item) => ({
                                                    option: item.name,
                                                    value: item.name,
                                                }))}
                                                defaultValue={getValues("centerName")}
                                                control={control}
                                                handleOnChange={(e) => {
                                                    // Address will be changed to specific center address
                                                    setValue(
                                                        "address",
                                                        getCenterByName(centerInfo, e.target.value)?.isCentralized
                                                            ? getCenterByName(centerInfo, e.target.value).address
                                                            : "",
                                                    );

                                                    setValue("floor", NOT_SELECTED);
                                                }}
                                                isDisplayMode={isEditDisabled(operation)}
                                            />
                                        </Grid>
                                        {renderFloor()}
                                        {renderRoom()}
                                        <Grid item xs={12} sm={2}>
                                            <FormSelectRHF
                                                {...register("numberOfResidents")}
                                                options={[...Array(4).keys()].map((idx: number) => ({
                                                    option: idx + 1,
                                                    value: idx + 1,
                                                }))}
                                                {...getFormKeys(
                                                    numberOfResidents,
                                                    translate(`setting.unit.numberOfResidents`),
                                                )}
                                                control={control}
                                                defaultValue={getValues("numberOfResidents")}
                                                isDisplayMode={isEditDisabled(operation)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <FormSelectRHF
                                                {...register("roomType")}
                                                options={[
                                                    {
                                                        option: translate(`resident.singleBed`),
                                                        value: BedType.Single,
                                                    },
                                                    {
                                                        option: translate(`resident.doubleBed`),
                                                        value: BedType.Double,
                                                    },
                                                ]}
                                                {...getFormKeys(roomType, translate(`setting.room.roomType`))}
                                                control={control}
                                                defaultValue={getValues("roomType")}
                                                isDisplayMode={isEditDisabled(operation)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} mb={3}>
                                        {renderAddress()}
                                    </Grid>
                                    <Grid container spacing={3} mb={3}>
                                        <Grid item xs={12}>
                                            <FormFieldRHF
                                                {...register("description")}
                                                {...getFormKeys(description, translate(`setting.unit.description`))}
                                                control={control}
                                                value={getValues("description")}
                                                isDisplayMode={isEditDisabled(operation)}
                                            />
                                        </Grid>
                                    </Grid>
                                    {/* <Grid container spacing={1} mb={1}>
                                        <Grid item xs={12}>
                                            {renderDeviceSettingHeader()}
                                            {renderDeviceSettingDetail()}
                                        </Grid>
                                    </Grid> */}
                                </MDBox>
                                <MDBox>
                                    {renderDeviceXSettingHeader()}
                                    {renderDeviceXSettingDetail()}
                                </MDBox>
                            </MDBox>
                            {isNotNil(error) && <MDErrorMsg errorMsg={error} />}
                            <SubmitButtons
                                disable={
                                    hasIssues ||
                                    isSubmitting ||
                                    Object.keys(errors).length !== 0 ||
                                    (isCentralized &&
                                        (watchFloor === NOT_SELECTED ||
                                            watchUnit === NOT_SELECTED ||
                                            watchCenterName === NOT_SELECTED)) ||
                                    watchRoomDevices.some((room) =>
                                        room.devices.some((device) => {
                                            if (room.room === WisRoomType.BEDROOM && device.model === "care") {
                                                return false; // Skip this device
                                            }
                                            return (
                                                device.serialNumber.length === 0 || device.serialNumber === NOT_SELECTED
                                            );
                                        }),
                                    )
                                }
                                operation={operation}
                                setDeleteConfirm={setDeleteConfirm}
                            />
                        </FormLayout>
                    </form>
                </Grid>
            </Grid>
        </>
    );
};

import * as Yup from "yup";
import checkout from "layouts/settings/schemas/form";
import {
    getCamelCase,
    isEditDisabled,
    getCenterByName,
    getFormKeys,
    NOT_SELECTED,
    uploadFile,
    deleteFile,
    fileRead,
    checkStatus,
} from "helpers/formUtil";
const {
    formField: {
        unit: { unitNumber,anderDevices },
    },
} = checkout;

const validations = {
    unit: [
        Yup.object().shape({
            unitNumber: Yup.string().required(unitNumber.errorMsg),
            // anderDevices: Yup.object().shape({
            //     serialNumber: Yup.string().required(anderDevices.serialNumber.errorMsg).notOneOf([NOT_SELECTED]),
            // }),
            address: Yup.string(),
            
        }),
    ],
};

export default validations;

import React, { useEffect, useRef } from "react";
import { Chart, ChartConfiguration, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import configs from "./configs";
import "chartjs-adapter-date-fns";
import { utcToZonedTime } from "date-fns-tz";
Chart.register(...registerables);

interface Props {
    icon?: {
        color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
        component: React.ReactNode;
    };
    title?: string;
    description?: string | React.ReactNode;
    height?: string | number;
    data: {
        labels: string[];
        datasets: {
            label: string;
            color: string;
            data: number[];
        }[];
    };
    options?: any;
    [key: string]: any;
    timeZone?: string;
}

const HorizontalStackBarChart: React.FC<Props> = ({ icon, title, description, height, data, options, timeZone }) => {
    const chartRef = useRef<Chart | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    // const chartDatasets = chart.datasets
    // const { data, options } = configs(chart.labels || [], chartDatasets, chart.options);
    useEffect(() => {
        if (canvasRef.current) {
            //set the start and end date for the x-axis, 7:00 am to 7:00 am with the timeZone props by using utcToZonedTime
            const start = utcToZonedTime(new Date(), timeZone);
            start.setHours(7, 0, 0, 0);
            const end = utcToZonedTime(new Date(), timeZone);
            end.setHours(7, 0, 0, 0);
            end.setDate(end.getDate() + 1);

            const chartConfig: ChartConfiguration = {
                type: "bar",
                data: data,
                options: {
                    ...options,
                    indexAxis: "y",
                    maintainAspectRatio: true,
                    scales: {
                        x: {
                            type: "time",
                            time: {
                                unit: "hour",
                                displayFormats: {
                                    hour: "HH:mm",
                                },
                            },
                            tooltipFormat: "d MMM yyyy HH:mm",
                            min: start,
                            max: end,
                            // stacked: true,
                        },
                        y: {
                            beginAtZero: true,
                            stacked: true,
                        },
                    },
                },
            };

            if (chartRef.current) {
                chartRef.current.destroy();
            }

            chartRef.current = new Chart(canvasRef.current, chartConfig);

            return () => {
                if (chartRef.current) {
                    chartRef.current.destroy();
                    chartRef.current = null;
                }
            };
        }
    }, [data, options]);

    return (
        <Card>
            <MDBox py={2} pr={2} pl={icon?.component ? 1 : 2}>
                {title || description ? (
                    <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
                        {icon?.component && (
                            <MDBox
                                width="4rem"
                                height="4rem"
                                bgColor={icon.color || "info"}
                                variant="gradient"
                                coloredShadow={icon.color || "info"}
                                borderRadius="xl"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                color="white"
                                mt={-5}
                                mr={2}>
                                <Icon fontSize="medium">{icon.component}</Icon>
                            </MDBox>
                        )}
                        <MDBox mt={icon?.component ? -2 : 0}>
                            {title && <MDTypography variant="h6">{title}</MDTypography>}
                            <MDBox mb={2}>
                                <MDTypography component="div" variant="button" color="text">
                                    {description}
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                ) : null}
                <MDBox height={height}>
                    <canvas ref={canvasRef} />
                </MDBox>
            </MDBox>
        </Card>
    );
};
HorizontalStackBarChart.defaultProps = {
    icon: { color: "info", component: "" },
    title: "",
    description: "",
    // height: "32.125rem",
};

export default HorizontalStackBarChart;

/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2024 REL Technology Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDErrorMsg from "components/MDErrorMsg";

// Authentication layout components
import BasicLayout from "layouts/authentications/components/BasicLayout";
import SubmitButton from "layouts/authentications/components/SubmitButton";

// Authentication
import AuthService from "services/auth.service";

// Validate
import { validateEmail } from "helpers/validations";
import DialogHeader from "../components/DialogHeader";

import { useTranslation } from "react-i18next";
import { isNotNil } from "helpers/utils";

export const Basic: React.FC = () => {
    const [email, setEmail] = useState<string>("");
    const [sended, setSended] = useState<boolean>(false);
    const [count, setCount] = useState<number>(300);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const { t: translate } = useTranslation(["common"]);

    useEffect(() => {
        if (sended) {
            if (count > 0) {
                const interval = setInterval(() => {
                    setCount((prev) => prev - 1);
                }, 1000);

                return () => clearInterval(interval);
            } else {
                setSended(false);
            }
        }
    }, [sended, count]);

    const secondsToTime = (time: number) => {
        const mins = Math.floor(time / 60);
        const secs = Math.floor(time % 60);
        return (mins >= 1 ? mins + "m" : "") + (secs < 10 ? "0" + secs : secs) + "s";
    };

    const handleEmailChange = (e: any) => {
        setEmail(e.target.value);
    };

    const handleSendMail = async () => {
        setErrorMsg(null);
        if (!validateEmail(email)) {
            setErrorMsg(translate("auth.error.emailFormat"));
            return;
        }

        const res = await AuthService.forgotPassword({ email: email }, translate("lang"));
        if (res.success) {
            setSended(true);
            setCount(300);
        } else {
            setErrorMsg(res.error);
        }
    };

    return (
        <BasicLayout>
            <DialogHeader title={translate("auth.forgotPassword")} />
            <MDBox component="form" role="form">
                <MDBox mb={4} sx={{ position: "relative" }}>
                    <MDTypography variant="h4" mb={5} fontWeight="regular" color="lightBlue">
                        {translate("auth.forgotPasswordInfo.enterEmail")}
                    </MDTypography>
                    <MDBox mb={2}>
                        <MDInput type="email" label={translate("auth.email")} onChange={handleEmailChange} fullWidth />
                    </MDBox>
                    {isNotNil(errorMsg) && <MDErrorMsg errorMsg={errorMsg} />}
                    {!sended ? (
                        <>
                            <SubmitButton
                                content={translate("auth.submit")}
                                disable={!email}
                                handleClick={handleSendMail}
                            />
                        </>
                    ) : (
                        <>
                            <MDBox mb={5}>
                                <MDTypography variant="body2" fontWeight="regular" color="primary" mt={1}>
                                    {translate("auth.forgotPasswordInfo.emailSent")}
                                </MDTypography>
                            </MDBox>
                            <SubmitButton
                                content={`${translate("auth.submit")} ${secondsToTime(count)}`}
                                disable={true}
                                handleClick={handleSendMail}
                            />
                        </>
                    )}
                </MDBox>
            </MDBox>
        </BasicLayout>
    );
};

export default Basic;

import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Business, Center, Resident } from "types/setting-schema";
import { Icon } from "@mui/material";

import BusinessService from "services/business.service";
import CenterService from "services/center.service";
import ResidentService from "services/resident.service";
import BiometricsService from "services/biometrics.service";

import MDCircularProgress from "components/MDCircularProgress";
import MDTypography from "components/MDTypography";
import MDFilter from "components/MDFilter";
import MDTab from "components/MDTab";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDNoData from "components/MDNoData";
import More from "components/MDIcon/more";
import DataText from "layouts/info/components/DataText";
import { BiometricsType } from "types/enums";

// Helpers
import { isNotNil, isNil, calculateAge } from "helpers/utils";
import { getBirthday } from "helpers/formUtil";
import defaultAvatar from "assets/images/defaultAvatar.svg";

// i18n
import { useTranslation } from "react-i18next";

import BasicLayout from "examples/LayoutContainers/BasicLayout";
import DataTable from "examples/Tables/DataTable";
import { HealthData } from "types/measurement-schema";
import { convertBloodSugar } from "helpers/infoUtil";

import Cookies from 'universal-cookie';

import { useAuth } from "context/userContext";

interface Cells {
    columns: { Header: string; accessor: string }[];
    rows: {
        avatar: JSX.Element;
        id: string;
        no: string;
        name: string;
        gender: string;
        age: string;
        bloodPressure: JSX.Element;
        bloodSugar: JSX.Element;
        pulse: JSX.Element;
        bloodOxygen: JSX.Element;
        temperature: JSX.Element;
        survey: JSX.Element;
        more: JSX.Element;
    }[];
}

// TODO: scroll will rerender
export const Info: React.FC = () => {
    const { currentUser } = useAuth();
    const [businessData, setBusinessData] = useState<Business[]>(null);
    const [centerData, setCenterData] = useState<Center[]>(null);
    const [residentData, setResidentData] = useState<Resident[]>(null);
    const [biometricsData, setBiometricsData] = useState<HealthData[]>(null);
    const [activeBusiness, setActiveBusiness] = useState<string>(null);
    const [activeCenter, setActiveCenter] = useState<string>(null);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const navigate = useNavigate();
    const { t: translate } = useTranslation(["common"]);
    const cookies = new Cookies();
    const prevRoute = useLocation();
    useEffect(() => {
        if (currentUser?.role === "rel-admin") {
            fetchBusinesses();
        }
        if (currentUser?.role === "business-admin") {
            fetchSingleBusiness(currentUser.business);
        }
        if (currentUser?.role === "center-admin" || currentUser?.role === "center-staff") {
            fetchSingleBusiness(currentUser.business);
        }
    }, [currentUser]);

    useEffect(() => {
        setCenterData(null);
        setResidentData(null);
        setBiometricsData(null);
 
        if (isNotNil(activeBusiness)){
            if (currentUser?.role === "rel-admin") {
                fetchCenters();
            }
            if (currentUser?.role === "business-admin") {
                fetchCenters();
            }
            if (currentUser?.role === "center-admin" || currentUser?.role === "center-staff") {
                fetchSingleCenter(currentUser.center);
            }
        }
    }, [activeBusiness]);
    useEffect(() => {
        if (isNotNil(activeBusiness) ) {
            fetchResidents();
            fetchBiometrics();
        }
    }, [page]);

    const fetchBusinesses = async () => {
        const res = await BusinessService.getAllBusinesses();
        if (res.success) {
            setBusinessData(res.data);
            if(window.history.state.prevUrl && isNotNil(cookies.get("daily-measurement-BS"))){
                setActiveBusiness(cookies.get("daily-measurement-BS"));

            }else{
                cookies.set("daily-measurement-BS",res.data[0]._id , { path: '/' });
                setActiveBusiness(res.data[0]._id);
            }
        }
    };

    const fetchSingleBusiness = async (businessId: string) => {
        const res = await BusinessService.getSingleBusiness(businessId);
        if (res.success) {
            setBusinessData([res.data]);
            setActiveBusiness(businessId);
        }
    };

    const fetchCenters = async () => {
        const res = await CenterService.getAllCenters(activeBusiness);
        if (res.success) {
            setCenterData(res.data);
            setActiveCenter("All");
            fetchResidents();
            fetchBiometrics();
        }
    };

    const fetchSingleCenter = async (centerId: string) => {
        const res = await CenterService.getSingleCenter(centerId);
        if (res.success) {
            setCenterData([res.data]);
            setActiveCenter(centerId);
            fetchResidents();
            fetchBiometrics();
        }
    }
    const fetchResidents = async () => {
        const res = await ResidentService.getAllResidentsByBusiness(activeBusiness,page);
        if (res.success) {
            console.log(res.data);
            setResidentData(res.data);
            setCount(res.pagination.total);
        }
    };

    const fetchBiometrics = async () => {
        const currentDateEnd = `${new Date().toJSON().slice(0, 10)}T23:59:59`;
        const currentDateStart = `${new Date().toJSON().slice(0, 10)}T00:00:00`;
        const res = await BiometricsService.getAllBiometricsByBusiness(
            activeBusiness,
            currentDateStart,
            currentDateEnd,
        );
        if (res.success) {
            setBiometricsData(res.data);
        }
    };

    const handleClick = (residentId?: string) => {
        window.history.pushState({ prevUrl: "/daily-measurement-and-survey/physiological-data" }, null)
        navigate("/daily-measurement-and-survey/physiological-data", { state: { residentId: residentId } });
    };

    const handleCenterChange = (e: any) => {
        setActiveCenter(e.target.value);
    };

    const handleBussiesChange = (e:any)=>{
        cookies.set("daily-measurement-BS",e , { path: '/' });
    }

    const renderBiometricsData = (residentBio: HealthData, type: BiometricsType, diabetes?: boolean) => {
        if (isNil(residentBio)) {
            return (
                <MDTypography variant="subtitle2" color="lightBlue">
                    N/A
                </MDTypography>
            );
        }
        switch (type) {
            case BiometricsType.BLOOD_PRESSURE:
                return (
                    <DataText
                        type={BiometricsType.BLOOD_PRESSURE}
                        dataList={[residentBio.systolicBloodPressure, residentBio.diastolicBloodPressure]}
                    />
                );
            case BiometricsType.BLOOD_SUGAR:
                return (
                    <DataText
                        type={BiometricsType.BLOOD_SUGAR}
                        data={convertBloodSugar(residentBio.bloodSugar.level)}
                        diabetes={diabetes}
                    />
                );
            case BiometricsType.PULSE:
                return <DataText type={BiometricsType.PULSE} data={residentBio.pulse} />;
            case BiometricsType.BLOOD_OXYGEN:
                return <DataText type={BiometricsType.BLOOD_OXYGEN} data={residentBio.bloodOxygen} />;
            case BiometricsType.TEMPERATURE:
                return <DataText type={BiometricsType.TEMPERATURE} data={residentBio.temperature} />;
        }
    };

    const handlePageChange = (page: number) => {
        setPage(page);
    }

    const residentCard = (residents: Resident[]) => {
        const cells: Cells = {
            columns: translate("info.table", { returnObjects: true }),
            rows: [] as any[],
        };

        cells.rows = residents?.map((resident) => {
            const birthday = getBirthday(resident.dateOfBirth);
            const { year, month, day } = birthday;
            const residentBio = biometricsData?.find((item) => item.resident._id === resident._id);
            // TODO: To be updated. Risks does not include all surveys
            const { surveys } = resident;
            const completedSurveys = surveys.filter((item) => item.complete);

            return {
                avatar: (
                    <MDAvatar
                        src={isNotNil(resident.avatarUrl) ? resident.avatarUrl : defaultAvatar}
                        alt="avatar"
                        size="sm"
                        shadow="sm"
                    />
                ),
                id: resident._id,
                no: resident.number,
                name: resident.name,
                gender: resident.gender.charAt(0).toUpperCase() + resident.gender.slice(1),
                age: calculateAge(year, month, day).toString(),
                bloodPressure: renderBiometricsData(residentBio, BiometricsType.BLOOD_PRESSURE),
                bloodSugar: renderBiometricsData(residentBio, BiometricsType.BLOOD_SUGAR, resident.diabetes),
                pulse: renderBiometricsData(residentBio, BiometricsType.PULSE),
                bloodOxygen: renderBiometricsData(residentBio, BiometricsType.BLOOD_OXYGEN),
                temperature: renderBiometricsData(residentBio, BiometricsType.TEMPERATURE),
                survey: (
                    <MDBox display="flex">
                        <Icon>article</Icon>
                        <MDTypography variant="subtitle2">{`${completedSurveys.length}/${surveys.length}`}</MDTypography>
                    </MDBox>
                ),
                more: <More />,
            };
        });

        return <DataTable table={cells} handleOnClick={handleClick} handlePageChange={handlePageChange} CustomPage={page} count={count}/>;
    };

    const renderResidentTable = () => {
        let returnVal;
        if (activeCenter === "All" && isNotNil(residentData) && residentData.length > 0) {
            // All
            returnVal = <>{residentCard(residentData)}</>;
        } else {
            // Filter by center
            const residentByCenter = residentData?.filter((item) => item.center?._id === activeCenter);
            if (isNotNil(residentByCenter) && residentByCenter.length > 0) {
                returnVal = <>{residentCard(residentByCenter)}</>;
            }
        }

        if (residentData?.length === 0) {
            returnVal = (
                <MDNoData
                    content={translate("general.notification.noData", {
                        type: `${translate("general.type.resident")}`,
                    })}
                />
            );
        }
        return returnVal;
    };

    return (
        <BasicLayout>
            {isNil(businessData) ? (
                <MDCircularProgress />
            ) : (
                <>
                    <MDBox mb={3}>
                        <MDTab
                            list={businessData.map((item) => ({ key: item.name, value: item._id }))}
                            active={activeBusiness}
                            setActive={setActiveBusiness}
                            setFallback={handleBussiesChange}
                        />
                    </MDBox>
                    {isNil(centerData) && isNil(residentData) ? (
                        <MDCircularProgress color="primary" />
                    ) : (
                        <>
                            <MDFilter data={centerData} handleChange={handleCenterChange} active={activeCenter}  disableAll={currentUser?.role !== "rel-admin"}/>
                            <MDBox>{renderResidentTable()}</MDBox>
                        </>
                    )}
                </>
            )}
        </BasicLayout>
    );
};

export default Info;
// import React, { useRef, useState, useEffect } from 'react';
// import Tesseract, { PSM } from 'tesseract.js';

// function CameraScanner() {
//   const videoRef = useRef<HTMLVideoElement>(null);
//   const canvasRef = useRef<HTMLCanvasElement>(null);
//   const [text, setText] = useState('');
//   const workerRef = useRef<Tesseract.Worker | null>(null);
//   const scanningRef = useRef<boolean>(false);
//   const scanIntervalRef = useRef<NodeJS.Timeout | null>(null);

//   useEffect(() => {
//     let mounted = true;

//     async function setup() {
//       try {
//         const constraints = {
//           video: { 
//             facingMode: 'environment',
//             width: { ideal: 1280 },
//             height: { ideal: 720 }
//           }
//         };
//         const stream = await navigator.mediaDevices.getUserMedia(constraints);
//         if (videoRef.current && mounted) {
//           videoRef.current.srcObject = stream;
//           await videoRef.current.play();
//         }

//         const worker = await Tesseract.createWorker('eng');
//         await worker.setParameters({
//           tessedit_pageseg_mode: PSM.SINGLE_LINE,
//           tessedit_char_whitelist: '0123456789',
//         });
//         workerRef.current = worker;

//         scanningRef.current = true;
//         scanIntervalRef.current = setInterval(() => {
//           if (scanningRef.current && mounted) {
//             scanText();
//           }
//         }, 500);

//       } catch (error) {
//         console.error('Setup failed:', error);
//         setText('錯誤：無法啟動相機，請檢查權限');
//       }

//       return () => {
//         mounted = false;
//         scanningRef.current = false;
//         if (scanIntervalRef.current) clearInterval(scanIntervalRef.current);
//         if (workerRef.current) workerRef.current.terminate();
//         if (videoRef.current && videoRef.current.srcObject) {
//           const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
//           tracks.forEach((track: MediaStreamTrack) => track.stop());
//           videoRef.current.srcObject = null;
//         }
//       };
//     }
//     setup();

//     return () => {};
//   }, []);

//   const preprocessImage = (context: CanvasRenderingContext2D, width: number, height: number) => {
//     const imageData = context.getImageData(0, 0, width, height);
//     const data = imageData.data;

//     // 計算平均灰度和對比度
//     let totalGray = 0;
//     let minGray = 255, maxGray = 0;
//     for (let i = 0; i < data.length; i += 4) {
//       const r = data[i];
//       const g = data[i + 1];
//       const b = data[i + 2];
//       const gray = 0.2989 * r + 0.5870 * g + 0.1140 * b;
//       totalGray += gray;
//       minGray = Math.min(minGray, gray);
//       maxGray = Math.max(maxGray, gray);
//     }
//     const avgGray = totalGray / (data.length / 4);
//     const contrast = maxGray - minGray; // 計算對比度

//     // 動態閾值，根據平均灰度和對比度調整
//     const dynamicThreshold = Math.max(100, Math.min(200, avgGray - contrast * 0.3)); // 根據對比度降低閾值

//     // 增強對比度（簡單線性拉伸）
//     for (let i = 0; i < data.length; i += 4) {
//       const r = data[i];
//       const g = data[i + 1];
//       const b = data[i + 2];
//       const gray = 0.2989 * r + 0.5870 * g + 0.1140 * b;
//       const normalized = (gray - minGray) * (255 / (maxGray - minGray || 1)); // 避免除以 0
//       const value = normalized < dynamicThreshold ? 0 : 255;
//       data[i] = data[i + 1] = data[i + 2] = value;
//     }

//     context.putImageData(imageData, 0, 0);
//     return context;
//   };

//   const scanText = async () => {
//     const video = videoRef.current;
//     const canvas = canvasRef.current;
//     const context = canvas?.getContext('2d');
//     const worker = workerRef.current;

//     if (!video || !canvas || !context || !worker || video.readyState !== 4 || !scanningRef.current) return;

//     const scanWidth = 400;
//     const scanHeight = 100;
//     const scanX = (video.videoWidth - scanWidth) / 2;
//     const scanY = (video.videoHeight - scanHeight) / 2;

//     canvas.width = scanWidth;
//     canvas.height = scanHeight;

//     try {
//       context.drawImage(video, scanX, scanY, scanWidth, scanHeight, 0, 0, scanWidth, scanHeight);
//       preprocessImage(context, scanWidth, scanHeight);
      
//       const { data: { text: recognizedText, confidence } } = await worker.recognize(canvas);
      
//       if (!scanningRef.current) return;

//       if (confidence >= 90) {
//         setText(`文字: ${recognizedText} (置信度: ${confidence}%)`);
//         scanningRef.current = false;
//         if (scanIntervalRef.current) clearInterval(scanIntervalRef.current);
//         if (video && video.srcObject) {
//           const tracks = (video.srcObject as MediaStream).getTracks();
//           tracks.forEach((track: MediaStreamTrack) => track.stop());
//           video.srcObject = null;
//         }
//       } else {
//         setText(`掃描中... (置信度: ${confidence}%)`);
//       }
//     } catch (error) {
//       console.error('Scan failed:', error);
//       setText('掃描失敗');
//     }
//   };

//   const getOverlayStyle = () => {
//     const video = videoRef.current;
//     if (!video || !video.videoWidth || !video.videoHeight) {
//       return { width: '400px', height: '100px' };
//     }

//     const videoWidth = video.videoWidth;
//     const videoHeight = video.videoHeight;
//     const displayWidth = video.clientWidth;
//     const displayHeight = video.clientHeight;

//     const scanWidth = 400;
//     const scanHeight = 100;
//     const scanX = (videoWidth - scanWidth) / 2;
//     const scanY = (videoHeight - scanHeight) / 2;

//     const widthRatio = displayWidth / videoWidth;
//     const heightRatio = displayHeight / videoHeight;

//     return {
//       position: 'absolute',
//       width: `${scanWidth * widthRatio}px`,
//       height: `${scanHeight * heightRatio}px`,
//       left: `${scanX * widthRatio}px`,
//       top: `${scanY * heightRatio}px`,
//       border: '2px solid red',
//       pointerEvents: 'none',
//     };
//   };

//   return (
//     <div style={{ textAlign: 'center' }}>
//       <h1>相機掃描器</h1>
//       <div style={{ position: 'relative', display: 'inline-block' }}>
//         <video ref={videoRef} style={{ width: '100%', maxWidth: '500px' }} muted playsInline />
//         <div style={getOverlayStyle() as React.CSSProperties} />
//       </div>
//       <canvas ref={canvasRef} style={{ display: 'block', margin: '10px auto' }} />
//       <p>{text}</p>
//     </div>
//   );
// }

// export default CameraScanner;

import React, { useRef, useState, useEffect } from 'react';
import Tesseract, { PSM } from 'tesseract.js';

interface CameraScannerProps {
  onScanSuccess?: (sn: string) => Promise<boolean>; 
}

function CameraScanner({ onScanSuccess }: CameraScannerProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [text, setText] = useState('');
  const workerRef = useRef<Tesseract.Worker | null>(null);
  const scanningRef = useRef<boolean>(false);
  const scanIntervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    let mounted = true;

    async function setup() {
      try {
        const constraints = {
          video: { 
            facingMode: 'environment',
            width: { ideal: 1280 },
            height: { ideal: 720 }
          }
        };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (videoRef.current && mounted) {
          videoRef.current.srcObject = stream;
          await videoRef.current.play();
        }

        const worker = await Tesseract.createWorker('eng');
        await worker.setParameters({
          tessedit_pageseg_mode: PSM.SINGLE_LINE,
          tessedit_char_whitelist: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
        });
        workerRef.current = worker;

        scanningRef.current = true;
        scanIntervalRef.current = setInterval(() => {
          if (scanningRef.current && mounted) {
            scanText();
          }
        }, 500);

      } catch (error) {
        console.error('Setup failed:', error);
        setText('錯誤：無法啟動相機，請檢查權限');
      }

      return () => {
        mounted = false;
        scanningRef.current = false;
        if (scanIntervalRef.current) clearInterval(scanIntervalRef.current);
        if (workerRef.current) workerRef.current.terminate();
        if (videoRef.current && videoRef.current.srcObject) {
          const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
          tracks.forEach((track: MediaStreamTrack) => track.stop());
          videoRef.current.srcObject = null;
        }
      };
    }
    setup();

    return () => {};
  }, []);

  const preprocessImage = (context: CanvasRenderingContext2D, width: number, height: number) => {
    const imageData = context.getImageData(0, 0, width, height);
    const data = imageData.data;

    // 計算平均灰度和對比度
    let totalGray = 0;
    let minGray = 255, maxGray = 0;
    for (let i = 0; i < data.length; i += 4) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];
      const gray = 0.2989 * r + 0.5870 * g + 0.1140 * b;
      totalGray += gray;
      minGray = Math.min(minGray, gray);
      maxGray = Math.max(maxGray, gray);
    }
    const avgGray = totalGray / (data.length / 4);
    const contrast = maxGray - minGray;

    // 自動調整參數
    const lightLevel = avgGray / 255; // 0 到 1 的光線強度（0 為暗，1 為亮）
    let thresholdAdjustment = 0;
    if (lightLevel > 0.7) { // 強光環境（> 70% 亮度）
      thresholdAdjustment = -30 * (lightLevel - 0.7); // 降低閾值，突出暗文字
    } else if (lightLevel < 0.3) { // 弱光環境（< 30% 亮度）
      thresholdAdjustment = 30 * (0.3 - lightLevel); // 提高閾值，突出明亮文字
    }
    const dynamicThreshold = Math.max(50, Math.min(230, avgGray + thresholdAdjustment)); // 限制範圍

    // 增強對比度，根據光線強度調整
    const contrastFactor = lightLevel > 0.7 ? 1.5 : (lightLevel < 0.3 ? 1.2 : 1.0); // 強光下加倍對比度
    for (let i = 0; i < data.length; i += 4) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];
      const gray = 0.2989 * r + 0.5870 * g + 0.1140 * b;
      const normalized = Math.min(255, Math.max(0, (gray - minGray) * (255 / (maxGray - minGray || 1)) * contrastFactor));
      const value = normalized < dynamicThreshold ? 0 : 255;
      data[i] = data[i + 1] = data[i + 2] = value;
    }

    context.putImageData(imageData, 0, 0);
    return context;
  };

  const scanText = async () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');
    const worker = workerRef.current;

    if (!video || !canvas || !context || !worker || video.readyState !== 4 || !scanningRef.current) return;

    const scanWidth = 400;
    const scanHeight = 100;
    const scanX = (video.videoWidth - scanWidth) / 2;
    const scanY = (video.videoHeight - scanHeight) / 2;

    canvas.width = scanWidth;
    canvas.height = scanHeight;

    try {
      context.drawImage(video, scanX, scanY, scanWidth, scanHeight, 0, 0, scanWidth, scanHeight);
      preprocessImage(context, scanWidth, scanHeight);
      
      const { data: { text: recognizedText, confidence } } = await worker.recognize(canvas);
      
      if (!scanningRef.current) return;

      if (confidence >= 90) {
        setText(`文字: ${recognizedText} (置信度: ${confidence}%)`);
        scanningRef.current = false;
        if (scanIntervalRef.current) clearInterval(scanIntervalRef.current);
        if (video && video.srcObject) {
          const tracks = (video.srcObject as MediaStream).getTracks();
          tracks.forEach((track: MediaStreamTrack) => track.stop());
          video.srcObject = null;
        }
      } else {
        setText(`掃描中... (置信度: ${confidence}%)`);
      }
    } catch (error) {
      console.error('Scan failed:', error);
      setText('掃描失敗');
    }
  };

  const getOverlayStyle = () => {
    const video = videoRef.current;
    if (!video || !video.videoWidth || !video.videoHeight) {
      return { width: '400px', height: '100px' };
    }

    const videoWidth = video.videoWidth;
    const videoHeight = video.videoHeight;
    const displayWidth = video.clientWidth;
    const displayHeight = video.clientHeight;

    const scanWidth = 400;
    const scanHeight = 100;
    const scanX = (videoWidth - scanWidth) / 2;
    const scanY = (videoHeight - scanHeight) / 2;

    const widthRatio = displayWidth / videoWidth;
    const heightRatio = displayHeight / videoHeight;

    return {
      position: 'absolute',
      width: `${scanWidth * widthRatio}px`,
      height: `${scanHeight * heightRatio}px`,
      left: `${scanX * widthRatio}px`,
      top: `${scanY * heightRatio}px`,
      border: '2px solid red',
      pointerEvents: 'none',
    };
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>相機掃描器</h1>
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <video ref={videoRef} style={{ width: '100%', maxWidth: '500px' }} muted playsInline />
        <div style={getOverlayStyle() as React.CSSProperties} />
      </div>
      <canvas ref={canvasRef} style={{ display: 'block', margin: '10px auto' }} />
      <p>{text}</p>
    </div>
  );
}

export default CameraScanner;
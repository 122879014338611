import React, { useRef, useState, useEffect } from "react";
import Tesseract, { PSM } from "tesseract.js";
import MDBox from "components/MDBox";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

interface CameraScannerProps {
    open: boolean; // 控制 Modal 開關
    onClose: () => void; // 關閉 Modal 的回調
    onScanSuccess?: (sn: string) => Promise<boolean>; // 掃描成功的回調
}

function CameraScanner({ open, onClose, onScanSuccess }: CameraScannerProps) {
    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [text, setText] = useState("");
    const workerRef = useRef<Tesseract.Worker | null>(null);
    const scanningRef = useRef<boolean>(false);
    const scanIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const { t: translate } = useTranslation(["common"]);
    useEffect(() => {
        let mounted = true;

        async function setup() {
            try {
                const constraints = {
                    video: {
                        facingMode: "environment",
                        width: { ideal: 1280 },
                        height: { ideal: 720 },
                    },
                };
                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                if (videoRef.current && mounted) {
                    videoRef.current.srcObject = stream;
                    await videoRef.current.play();
                }

                const worker = await Tesseract.createWorker("eng");
                await worker.setParameters({
                    tessedit_pageseg_mode: PSM.SINGLE_LINE,
                    tessedit_char_whitelist: "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
                });
                workerRef.current = worker;

                scanningRef.current = true;
                scanIntervalRef.current = setInterval(() => {
                    if (scanningRef.current && mounted) {
                        scanText();
                    }
                }, 500);
            } catch (error) {
                console.error("Setup failed:", error);
                setText(translate("scanning.cameraPermission"));
            }

            return () => {
                mounted = false;
                scanningRef.current = false;
                if (scanIntervalRef.current) clearInterval(scanIntervalRef.current);
                if (workerRef.current) workerRef.current.terminate();
                if (videoRef.current && videoRef.current.srcObject) {
                    const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
                    tracks.forEach((track: MediaStreamTrack) => track.stop());
                    videoRef.current.srcObject = null;
                }
            };
        }

        if (open) {
            setup();
        }

        return () => {};
    }, [open]); // 當 Modal 打開時觸發設置

    const preprocessImage = (context: CanvasRenderingContext2D, width: number, height: number) => {
        const imageData = context.getImageData(0, 0, width, height);
        const data = imageData.data;

        let totalGray = 0;
        let minGray = 255,
            maxGray = 0;
        for (let i = 0; i < data.length; i += 4) {
            const r = data[i];
            const g = data[i + 1];
            const b = data[i + 2];
            const gray = 0.2989 * r + 0.587 * g + 0.114 * b;
            totalGray += gray;
            minGray = Math.min(minGray, gray);
            maxGray = Math.max(maxGray, gray);
        }
        const avgGray = totalGray / (data.length / 4);
        const contrast = maxGray - minGray;

        const lightLevel = avgGray / 255;
        let thresholdAdjustment = 0;
        if (lightLevel > 0.7) {
            thresholdAdjustment = -30 * (lightLevel - 0.7);
        } else if (lightLevel < 0.3) {
            thresholdAdjustment = 30 * (0.3 - lightLevel);
        }
        const dynamicThreshold = Math.max(50, Math.min(230, avgGray + thresholdAdjustment));

        const contrastFactor = lightLevel > 0.7 ? 1.5 : lightLevel < 0.3 ? 1.2 : 1.0;
        for (let i = 0; i < data.length; i += 4) {
            const r = data[i];
            const g = data[i + 1];
            const b = data[i + 2];
            const gray = 0.2989 * r + 0.587 * g + 0.114 * b;
            const normalized = Math.min(
                255,
                Math.max(0, (gray - minGray) * (255 / (maxGray - minGray || 1)) * contrastFactor),
            );
            const value = normalized < dynamicThreshold ? 0 : 255;
            data[i] = data[i + 1] = data[i + 2] = value;
        }

        context.putImageData(imageData, 0, 0);
        return context;
    };

    const scanText = async () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const context = canvas?.getContext("2d");
        const worker = workerRef.current;

        if (!video || !canvas || !context || !worker || video.readyState !== 4 || !scanningRef.current) return;

        const scanWidth = 400;
        const scanHeight = 100;
        const scanX = (video.videoWidth - scanWidth) / 2;
        const scanY = (video.videoHeight - scanHeight) / 2;

        canvas.width = scanWidth;
        canvas.height = scanHeight;

        try {
            context.drawImage(video, scanX, scanY, scanWidth, scanHeight, 0, 0, scanWidth, scanHeight);
            preprocessImage(context, scanWidth, scanHeight);

            const {
                data: { text: recognizedText, confidence },
            } = await worker.recognize(canvas);

            if (!scanningRef.current) return;

            if (confidence >= 70) {
                if (onScanSuccess) {
                    const shouldContinue = await onScanSuccess(recognizedText.trim());
                    if (shouldContinue) {
                        setText(translate("scanning.scanningSuccess",{recognizedText:recognizedText,confidence:confidence}));
                        scanningRef.current = false;
                        if (scanIntervalRef.current) clearInterval(scanIntervalRef.current);
                        if (video && video.srcObject) {
                            const tracks = (video.srcObject as MediaStream).getTracks();
                            tracks.forEach((track: MediaStreamTrack) => track.stop());
                            video.srcObject = null;
                        }
                        onClose(); // 成功後關閉 Modal
                    } else {
                        scanningRef.current = true;
                        setText(translate("scanning.scanningRetry",{confidence:confidence}));
                    }
                }
            } else {
              setText(translate("scanning.scanning",{confidence:confidence}));
            }
        } catch (error) {
            console.error("Scan failed:", error);
            setText(translate("scanning.fail"));
        }
    };

    const getOverlayStyle = () => {
        const video = videoRef.current;
        if (!video || !video.videoWidth || !video.videoHeight) {
            return { width: "400px", height: "100px" };
        }

        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;
        const displayWidth = video.clientWidth;
        const displayHeight = video.clientHeight;

        const scanWidth = 400;
        const scanHeight = 100;
        const scanX = (videoWidth - scanWidth) / 2;
        const scanY = (videoHeight - scanHeight) / 2;

        const widthRatio = displayWidth / videoWidth;
        const heightRatio = displayHeight / videoHeight;

        return {
            position: "absolute",
            width: `${scanWidth * widthRatio}px`,
            height: `${scanHeight * heightRatio}px`,
            left: `${scanX * widthRatio}px`,
            top: `${scanY * heightRatio}px`,
            border: "2px solid red",
            pointerEvents: "none",
        };
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="camera-scanner-modal"
            aria-describedby="camera-scanner-modal-description"
            sx={{
                // Define Modal's width explicitly to control the container
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: { xs: 1, sm: 2 }, // Optional: padding for Modal
            }}>
            <MDBox
                sx={{
                    width: "100%", // Takes full width of Modal's content area
                    maxWidth: "90vw", // Limits maximum width relative to viewport
                    maxHeight: "90vh", // Limits maximum height
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: {
                        // Responsive padding
                        xs: 2,
                        sm: 4,
                    },
                    textAlign: "center",
                    borderRadius: 2,
                    overflowY: "auto", // Scroll if content overflows
                    "& video": {
                        width: "100%",
                        maxWidth: "500px",
                        height: "auto",
                    },
                    // Media queries for responsiveness
                    "@media (max-width: 600px)": {
                        maxWidth: "95vw", // Wider on smaller screens
                    },
                    "@media (max-height: 500px)": {
                        maxHeight: "85vh",
                        p: 2,
                    },
                }}>
                <div
                    style={{
                        position: "relative",
                        display: "inline-block",
                        width: "100%",
                        maxWidth: "500px",
                        margin: "0 auto",
                    }}>
                    <video ref={videoRef} muted playsInline />
                    <div style={getOverlayStyle() as React.CSSProperties} />
                </div>
                <canvas ref={canvasRef} style={{ display: 'block', margin: '10px auto',width:"100%" }} />
                <p>{text}</p>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClose}
                    sx={{
                        mt: 2,
                        width: {
                            xs: "100%",
                            sm: "auto",
                        },
                    }}>
                    X
                </Button>
            </MDBox>
        </Modal>
    );
}

export default CameraScanner;

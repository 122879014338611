import React, { useState, useEffect } from "react";

import AccountTable from "./components/AlertTable";
import MDBox from "components/MDBox";
import MDCircularProgress from "components/MDCircularProgress";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Helpers
import { isNotNil, isNil } from "helpers/utils";

// i18n
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAuth } from "context/userContext";
import { isRelAdmin } from "types/user-schema";
import AlarmService from "services/alarm.service";
import { AlertRecord } from "types/alert-schema";
import AlertTable from "./components/AlertTable";
const AlertHistoryPage: React.FC = () => {
    let state = useLocation().state as { businessId?: string; centerId?: string } | null;
    const { currentUser } = useAuth();
    const { t: translate } = useTranslation(["common"]);
    const [alertData, setAlertData] = useState<AlertRecord[] | null>(null);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    // Fetch data from API or any data source
    useEffect(() => {
        checkAuth();
    }, [currentUser,page]);

    const checkAuth = () => {
        if (isNotNil(state) && isNotNil(currentUser)) {
            if (currentUser && isRelAdmin(currentUser)) {
                fetchAlertHistory("businessId", state.businessId);
            } else {
                if (state && state.businessId) {
                    fetchAlertHistory("businessId", state.businessId);
                } else if (isNotNil(state.centerId)) {
                    fetchAlertHistory("centerId", state.centerId);
                }
            }
        }
    };
    const fetchAlertHistory = async (type: "businessId" | "centerId" | "all", value: string) => {
        const res = await AlarmService.getTodayBusinessAlarm(
            type,
            value,
            Intl.DateTimeFormat().resolvedOptions().timeZone,
            page,
        );
        if (res.success) {
            console.log("res",res)
            setAlertData(res.data.todayAlerts);
            setPage(res.pagination.page);
            setCount(res.pagination.total);
        }
    };
    const handlePageChange = (page: number) => {
        setPage(page);
    };
    const childCallback = async (updated: boolean) => {
        if (updated) {
            if (isNotNil(state)) {
                if (isRelAdmin(currentUser)) {
                    // fetchAlertHistory("all", "all");
                    fetchAlertHistory("businessId", state.businessId);
                } else {
                    if (state && state.businessId) {
                        fetchAlertHistory("businessId", state.businessId);
                    } else if (isNotNil(state.centerId)) {
                        fetchAlertHistory("centerId", state.centerId);
                    }
                }
            }
        }
    };

    const renderPage = () => {
        return isNil(alertData) ? (
            <MDCircularProgress />
        ) : (
            <MDBox>
                <AlertTable
                    list={alertData}
                    callback={childCallback}
                    handlePageChange={handlePageChange}
                    page={page}
                    count={count}
                />
            </MDBox>
        );
    };

    // useEffect(() => {
    //     checkAuth();
    // }, [page]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {renderPage()}
        </DashboardLayout>
    );
};

export default AlertHistoryPage;

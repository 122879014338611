import { useEffect, useState, useMemo, useCallback } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { categoryBar, timeRangeBar } from "layouts/info/styles/chart.styles";
// i18n
import { useTranslation } from "react-i18next";
import BiometricsService from "services/biometrics.service";
import { isNotNil, exportDataDate, roundNumber, exportDataDateV2 } from "helpers/utils";
import { HealthData } from "types/measurement-schema";
import { convertBloodSugar, dateStartByV2, fillUpArrayV2 } from "helpers/infoUtil";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import MDCircularProgress from "components/MDCircularProgress";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { BiometricsType, TimeRange } from "types/enums";
import HorizontalStackTimeBarChart from "examples/Charts/BarCharts/HorizontalStackTimeBarChart";
import ReportService from "services/report.service";
import { Summary } from "types/report";
import { set } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const category = [
    BiometricsType.BLOOD_PRESSURE,
    BiometricsType.BLOOD_SUGAR,
    BiometricsType.PULSE,
    BiometricsType.BLOOD_OXYGEN,
    BiometricsType.TEMPERATURE,
];
const timeRange = [Summary.LastSeveralDays, Summary.LastFourteenDays, Summary.LastThirtyDays];

interface Prop {
    wistronId: string;
    setPLoading: (data: any) => void;
    timeZone: string;
}

interface DateData {
    date: string;
    data: number;
}

export const HealthDataTimeChart: React.FC<Prop> = ({ wistronId, setPLoading, timeZone }) => {
    const [activeTimeRange, setTimeRange] = useState<Summary>(timeRange[0]);
    const [chartData, setChartData] = useState(null);
    const [data, setData] = useState<any>(null);
    const [label, setLabel] = useState([]);
    const { t: translate } = useTranslation(["common"]);
    const [loading, setLoading] = useState(false);

    const fetchSummary = useCallback(async () => {
        setLoading(true);
        // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const res = await ReportService.getDailyChart(wistronId, timeZone, activeTimeRange);
        if (res.success) {
            setData(res.data);
            setPLoading(false);
        } else {
            setPLoading(false);
        }
        setLoading(false);
    }, [wistronId, activeTimeRange]);

    const handleChange = useCallback((event: SelectChangeEvent) => {
        setTimeRange(event.target.value as Summary);
    }, []);

    useEffect(() => {
        if (!isNotNil(data)) return;
        // set the label of date from data.roomStats key
        const clonedRoomStats = JSON.parse(JSON.stringify(data.roomStats));
        const label: any = [];
        // Create Dataset
        const datasets: {
            label: string;
            data: {
                x: Date[];
                y: string;
            }[];
            backgroundColor: string;
        }[] = [];

        function updateDateWithTime(baseDate:Dayjs,time:Dayjs){
            const timeString = time.format('HH:mm:ss.SSS');
            const combinedDateTime = dayjs(`${baseDate.format('YYYY-MM-DD')}T${timeString}`);
            return combinedDateTime.toISOString();
        }
        const today = dayjs().tz(timeZone);
        const nextDay = today.add(1, "day");
        Object.entries(clonedRoomStats).forEach(([date, roomData]: [string, any]) => {
            label.push(date);
            if (Array.isArray(roomData)) {
                let _date = dayjs(date);


                let est = 0,
                    lest = 0;
                roomData.forEach((data: any) => {

                    if (est === 0) {
                        est = data.st;
                    }
                    if (data.st > lest) {
                        lest = data.et;
                    }
                    const _et = dayjs(data.et).tz(timeZone);
                    const _st = dayjs(data.st).tz(timeZone);

                    let updatedSt, updatedEt;
                    
                    if(_st.get("date") - _date.get("date") === 0){
                        updatedSt = updateDateWithTime(today, _st);
                    }else if (_st.get("date") - _date.get("date") === 1){
                        updatedSt = updateDateWithTime(nextDay, _st);
                    }

                    if(_et.get("date") - _date.get("date") === 0){
                        updatedEt = updateDateWithTime(today, _et);
                    }else if (_et.get("date") - _date.get("date") === 1){
                        updatedEt = updateDateWithTime(nextDay, _et);
                    }
                    //if the _st or _et is not the same date as the date, we need to update the date to next day

                    // check datasets object's label exists or not
                    const datasetExists = datasets.some((item) => item.label === data.location);
                    // conver the date, edit the date to today, keep the time

                    if (!datasetExists) {
                        datasets.push({
                            label: data.location,
                            data: [{ x: [new Date(updatedSt), new Date(updatedEt)], y: date }],
                            backgroundColor: getColor(data.location),
                        });
                    } else {
                        // If label exists, append new data point
                        const index = datasets.findIndex((item) => item.label === data.location);
                        // console.log("indexindex",index)
                        datasets[index].data.push({
                            x: [new Date(updatedSt), new Date(updatedEt)],
                            y: date,
                        });
                    }
                 
                });

            }
        });
        //sort all the data at datasets by the data.y
        datasets.forEach((data) => {
            data.data.sort((a, b) => {
                return new Date(a.y).getTime() - new Date(b.y).getTime();
            });
        });
        //update the datasets label with translateion
        datasets.forEach((data) => {
            data.label = translate(`device.roomType.${data.label}`);
        });
        datasets.sort((a, b) => {
            if (a.backgroundColor === '#FF5900') return -1;
            if (b.backgroundColor === '#F7AD53') return 1;
            if (a.backgroundColor === '#F7AD53') return -1;
            if (b.backgroundColor === '#FF5900') return 1;
            return 0;
        });
        setChartData({ labels: label, datasets });
        // console.log("datasetsdatasetsdatasets",datasets)
    }, [data]);
    const getColor = (index: string) => {
        switch (index) {
            case "bathRoom":
                return "#BA8CE7";
            case "livingRoom":
                return "#96CB71";
            case "bedRoom":
                return "#F7AD53";
            case "sleep":
                return "#FF5900";
            case "kitchen":
                return "#9C27B0";
            default:
                return "#16C0E8";
        }
    };

    useEffect(() => {
        fetchSummary();
    }, [activeTimeRange]);

    const horizontalStackBarChartProps = useMemo(
        () => ({
            chartData,
        }),
        [chartData, translate("lang")],
    ); // Only recompute when label or chartData change

    return (
        <Card sx={{ p: 3 }}>
            <MDBox display="flex" justifyContent="end" mt={3}>
                <FormControl>
                    <Select sx={{ p: 0.5 }} value={activeTimeRange} onChange={handleChange}>
                        {timeRange.map((item, i) => (
                            <MenuItem key={i} value={item} sx={{ minWidth: 50 }}>
                                <MDBox display="flex" alignItems="center">
                                    <MDTypography
                                        variant="subtitle2"
                                        sx={(theme: any) => timeRangeBar(theme, { activeTimeRange, item })}>
                                        {translate(`report.summaryType.${item}`)}
                                    </MDTypography>
                                </MDBox>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </MDBox>
            <MDBox mt={4}>
                {!loading ? (
                    <HorizontalStackTimeBarChart data={chartData} timeZone={timeZone} />
                ) : (
                    <MDCircularProgress size={36} />
                )}
            </MDBox>
        </Card>
    );
};

export default HealthDataTimeChart;

import { Response, default as http } from "http-common";
import { Resident, ResidentNote, Unit,WistronResident } from "types/setting-schema";

interface AvailableUnitsProps {
    _id: string;
    name: string;
    business: string;
    units: Unit[];
}

class ResidentService {
    async getAllResidentsByCenter(centerId: string): Promise<Response<Resident[]>> {
        const response = await http.get<Response<Resident[]>>(`/api/v1/centers/${centerId}/residents`);
        return response.data;
    }

    async getAllResidentsByBusiness(businessId: string,page?:number): Promise<Response<Resident[]>> {
        const response = await http.get<Response<Resident[]>>(`/api/v1/businesses/${businessId}/residents?page=${page}`);
        return response.data;
    }

    async getSingleResident(residentId: string): Promise<Response<Resident>> {
        const response = await http.get<Response<Resident>>(`/api/v1/residents/${residentId}`);
        return response.data;
    }

    async updateSingleResident(residentId: string, residentInfo: Resident): Promise<Response<Resident>> {
        const response = await http.put<Response<Resident>>(`/api/v1/residents/${residentId}`, residentInfo);
        return response.data;
    }

    async createResident(businessId: string, residentInfo: Resident): Promise<Response<Resident>> {
        const response = await http.post<Response<Resident>>(
            `/api/v1/businesses/${businessId}/residents`,
            residentInfo,
        );
        return response.data;
    }

    async deleteSingleResident(residentId: string): Promise<Response<Resident>> {
        const response = await http.delete<Response<Resident>>(`/api/v1/residents/${residentId}`);
        return response.data;
    }

    async getAvailableUnits(businessId: string): Promise<Response<AvailableUnitsProps[]>> {
        const response = await http.get<Response<AvailableUnitsProps[]>>(
            `/api/v1/businesses/${businessId}/availableUnits`,
        );
        return response.data;
    }

    async moveOutFromUnit(residentId: string, unitId: string): Promise<Response<Resident>> {
        const response = await http.put<Response<Resident>>(`/api/v1/residents/${residentId}/units/${unitId}/moveout`);
        return response.data;
    }
    async createResidentNote(residentId: string, content: string): Promise<Response<ResidentNote>> {
        const response = await http.post<Response<ResidentNote>>(`/api/v1/notes/${residentId}`, { content });
        return response.data;
    }
    async getResidentNotes(residentId: string): Promise<Response<ResidentNote[]>> {
        const response = await http.get<Response<ResidentNote[]>>(`/api/v1/notes/${residentId}`);
        return response.data;
    }
    async getReportAndarxData(name: string, date: string, type: number, id: string): Promise<Response<any>> {
        const response = await http.get<Response<any>>(
            `/api/v1/report/getReportAndarxData?name=${name}&date=${date}&type=${type}&id=${id}`,
        );
        return response.data;
    }
    async getSpaceData(et: number, st: number, room_id: string, timeZone: string): Promise<Response<any>> {
        const response = await http.get<Response<any>>(
            `/api/v1/report/getSpaceData?et=${et}&st=${st}&room_id=${room_id}&timeZone=${timeZone}`,
        );
        return response.data;
    }

    async getReportData(name: string, date: string, type: number, id: string,timeZone:string): Promise<Response<any>> {
        const response = await http.get<Response<any>>(
            `/api/v1/report/getReportData?name=${name}&date=${date}&type=${type}&id=${id}&timeZone=${timeZone}`,
        );
        return response.data;
    }

    async getspaceDataX(name: string, date: string, type: number, id: string,room_id:string,timeZone:string): Promise<Response<any>> {
        const response = await http.get<Response<any>>(
            `/api/v1/report/getspaceDataX?name=${name}&date=${date}&type=${type}&id=${id}&room_id=${room_id}&timeZone=${timeZone}`,
        );
        return response.data;
    }
    async getWistronResidentByID(wistron_id: string): Promise<Response<WistronResident>> {
        const response = await http.get<Response<WistronResident>>(`/api/v1/report/getWistronResidentByID?id=${wistron_id}`);
        return response.data;
    }

    async getReport(reportType:string,bedNum:string,residentName:string,date:string): Promise<Response<any>> {
        const response = await http.get<Response<any>>(`/api/v1/report/getReport?reportType=${reportType}&bed=${bedNum}&residentName=${residentName}&dateRange=${date}`);
        return response.data;
    }

    async getTodayAnderReport(id:string): Promise<Response<any>> {
        const response = await http.get<Response<any>>(`/api/v1/report/today?id=${id}`);
        return response.data;
    }

    async getTodayAnderXReport(id:string): Promise<Response<any>> {
        const response = await http.get<Response<any>>(`/api/v1/reportX/today?id=${id}`);
        return response.data;
    }

    async getAllTodayAnderReport(page:number): Promise<Response<any>> {
        const response = await http.get<Response<any>>(`/api/v1/report/today?page=${page}`);
        return response.data;
    }
    async getAllTodayAnderXReport(page:number): Promise<Response<any>> {
        const response = await http.get<Response<any>>(`/api/v1/reportX/today?page=${page}`);
        return response.data;
    }

    async getResidentByWistronId(wistron_id: string): Promise<Response<any>> {
        const response = await http.get<Response<any>>(`/api/v1/residents/wistron/${wistron_id}`);
        return response.data;
    }

    async getAllResidentWithWistronId(): Promise<Response<any>> {
        const response = await http.get<Response<any>>(`/api/v1/residents/unitRecord`);
        return response.data;
    }
    async getAllWistronResident(): Promise<Response<any>> {
        const response = await http.get<Response<any>>(`/api/v1/residents/wistronBabies`);
        return response.data;
    }
    async getAllResidentWithoutWistronId(): Promise<Response<any>> {
        const response = await http.get<Response<any>>(`/api/v1/residents/newResident`);
        return response.data;
    }

    async addNewWistronResident(body:any,id:any): Promise<Response<any>> {
        const response = await http.post<Response<any>>(`/api/v1/residents/wistronBabies?id=${id}`,body);
        return response.data;
    }

    async getWistronLocationInfos(): Promise<Response<any>> {
        const response = await http.get<Response<any>>(`/api/v1/devices/location-infos`);
        return response.data;
    }

    async getWistronFloorInfos(): Promise<Response<any>> {
        const response = await http.get<Response<any>>(`/api/v1/units/floorInfos`);
        return response.data;
    }

    async getWistronLocationInfosWithAnderX(): Promise<Response<any>> {
        const response = await http.get<Response<any>>(`/api/v1/devices/location-infos-withAndarX`);
        return response.data;
    }

    async getWistronRoom(): Promise<Response<any>> {
        const response = await http.get<Response<any>>(`/api/v1/rooms`);
        return response.data;
    }

    async getWistronBedNum(bedName:string,floor:string): Promise<Response<any>> {
        const response = await http.get<Response<any>>(`/api/v1/rooms/bedNum?bedName=${bedName}&floor=${floor}`);
        return response.data;
    }
}

export default new ResidentService();

import { useEffect, useState, useMemo, useCallback, memo } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { categoryBar, timeRangeBar } from "layouts/info/styles/chart.styles";
// i18n
import { useTranslation } from "react-i18next";
import BiometricsService from "services/biometrics.service";
import { isNotNil, exportDataDate, roundNumber, exportDataDateV2 } from "helpers/utils";
import { HealthData } from "types/measurement-schema";
import { convertBloodSugar, dateStartByV2, fillUpArrayV2 } from "helpers/infoUtil";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import MDCircularProgress from "components/MDCircularProgress";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { BiometricsType, TimeRange } from "types/enums";
import HorizontalStackBarChart from "examples/Charts/BarCharts/HorizontalStackBarChart";
import ReportService from "services/report.service";
import { Summary } from "types/report";
import { set } from "date-fns";
import ModLineChart from "examples/Charts/LineCharts/ModLineChart";
const category = [
    BiometricsType.BLOOD_PRESSURE,
    BiometricsType.BLOOD_SUGAR,
    BiometricsType.PULSE,
    BiometricsType.BLOOD_OXYGEN,
    BiometricsType.TEMPERATURE,
];
const timeRange = [Summary.LastSeveralDays, Summary.LastFourteenDays, Summary.LastThirtyDays];

interface Prop {
    wistronId: string;
    setRoomData: (data: any) => void;
    setPLoading: (data: any) => void;
    timeZone: string;
}

interface DateData {
    date: string;
    data: number;
}

export const HealthDataLineChart: React.FC<Prop> = ({ wistronId, setRoomData, setPLoading,timeZone }) => {
    const [activeTimeRange, setTimeRange] = useState<Summary>(timeRange[0]);
    const [chartData, setChartData] = useState(null);
    const [finalList, setFinalList] = useState<DateData[][]>(null);
    const [options, setOptions] = useState({});
    const [data, setData] = useState<any>(null);
    const [label, setLabel] = useState([]);
    const { t: translate } = useTranslation(["common"]);
    const [loading, setLoading] = useState(false);

    const fetchSummary = useCallback(async () => {
        setLoading(true);
        // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const res = await ReportService.getSummaryChart(wistronId, timeZone, activeTimeRange);
        if (res.success) {
            setFinalList(null);
            setChartData(null);
            setData(res.data);
            setRoomData(res.data);
            setPLoading(false);
        } else {
            setRoomData(null);
            setPLoading(false);
        }
        setLoading(false);
    }, [wistronId, activeTimeRange]);

    const skipped = (ctx: { p0: { skip: any }; p1: { skip: any } }, value: number[]) =>
        ctx.p0.skip || ctx.p1.skip ? value : undefined;

    const handleChange = useCallback((event: SelectChangeEvent) => {
        setTimeRange(event.target.value as Summary);
    }, []);

    useEffect(() => {
        if (!isNotNil(data)) return;
        const roomNames = Object.keys(data.roomStats).sort();
        if (roomNames.length === 0) return;
        let date = exportDataDateV2(activeTimeRange,timeZone);
        setLabel(date);
        const _data: { [key: string]: { [key: string]: any } } = {}; // Adjusted to store date-based data
        let startDate: Date = dateStartByV2(activeTimeRange,timeZone);
        let endDate: Date = new Date();
        endDate.setDate(endDate.getDate() - 1);
        roomNames.forEach((roomName) => {
            const roomData = data.roomStats[roomName];
            if (!_data[roomName]) {
                _data[roomName] = {}; // Initialize the room entry as an object if not already
            }

            switch (activeTimeRange) {
                case "lastSevenDays":
                    startDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 6);
                    while (startDate <= endDate) {
                        _data[roomName][startDate.toISOString().substring(0, 10)] = {};
                        startDate.setDate(startDate.getDate() + 1);
                    }
                    break;

                case "lastFourteenDays":
                    startDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 13);
                    while (startDate <= endDate) {
                        _data[roomName][startDate.toISOString().substring(0, 10)] = {};
                        startDate.setDate(startDate.getDate() + 1);
                    }
                    break;

                case "lastThirtyDays":
                    startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 1, endDate.getDate() + 1);
                    while (startDate <= endDate) {
                        _data[roomName][startDate.toISOString().substring(0, 10)] = {};
                        startDate.setDate(startDate.getDate() + 1);
                    }
                    break;

                default:
                    startDate = new Date();
            }

            roomData.forEach((item: any) => {
                const date = formatDateToYYYYMMDD(item.date, true);
                if (_data[roomName][date]) {
                    if (item.data) {
                        _data[roomName][date] = item.data;
                    }
                }
            });
            if(_data["bedRoom"] && _data["sleep"]){
                Object.keys(_data["bedRoom"]).forEach((date) => {
                    if(_data["bedRoom"][date] && _data["sleep"][date]){

                        _data.bedRoom[date] = _data.bedRoom[date] - _data.sleep[date];
                    }
                });
            }
        });

        //set Chart Data
        let _chartData: any = [];
        roomNames.forEach((roomName) => {
            let _roomData: any = [];
            Object.keys(_data[roomName]).forEach((date) => {
                const value = _data[roomName][date];
                console.log("1231", value);

                // Only push non-empty values
                if (value > 0) {
                    _roomData.push(value / 60 / 60);
                } else {
                    _roomData.push(null);
                }
            });
            _chartData.push({
                label: translate(`device.roomType.${roomName}`),
                color: getColor(roomName),
                backgroundColor: getColor(roomName),
                borderColor: getColor(roomName),
                fill: false,
                data: _roomData,
                spanGaps: true,
                tension: 0.5,
                borderWidth: 3,
                segment: {
                    borderDash: (ctx: { p0: { skip: any }; p1: { skip: any } }) => skipped(ctx, [4, 4]),
                },
            });
        });
        console.log(_chartData);
        setChartData(_chartData);
        setLoading(false);
    }, [data]);
    const getColor = (index: string) => {
        switch (index) {
            case "bathRoom":
                return "#BA8CE7";
            case "livingRoom":
                return "#96CB71";
            case "bedRoom":
                return "#F7AD53";
            case "sleep":
                return "#FF5900";
            case "kitchen":
                return "#9C27B0";
            default:
                return "#16C0E8";
        }
    };
    function formatDateToYYYYMMDD(timestamp: number, useUTC: boolean = false): string {
        const date = new Date(timestamp);

        const year = useUTC ? date.getUTCFullYear() : date.getFullYear();
        const month = String((useUTC ? date.getUTCMonth() : date.getMonth()) + 1).padStart(2, "0");
        const day = String(useUTC ? date.getUTCDate() : date.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
    }

    useEffect(() => {
        fetchSummary();
    }, [activeTimeRange]);
    const chartProps = useMemo(
        () => ({
            labels: label,
            datasets: chartData,
            options: options && options,
        }),
        [label, chartData, options],
    );

    const MemoizedHorizontalStackBarChart = memo(ModLineChart);
    return (
        <Card sx={{ p: 3 }}>
            <MDBox display="flex" justifyContent="end" mt={3}>
                <FormControl>
                    <Select sx={{ p: 0.5 }} value={activeTimeRange} onChange={handleChange}>
                        {timeRange.map((item, i) => (
                            <MenuItem key={i} value={item} sx={{ minWidth: 50 }}>
                                <MDBox display="flex" alignItems="center">
                                    <MDTypography
                                        variant="subtitle2"
                                        sx={(theme: any) => timeRangeBar(theme, { activeTimeRange, item })}>
                                        {translate(`report.summaryType.${item}`)}
                                    </MDTypography>
                                </MDBox>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </MDBox>
            <MDBox mt={4}>
                {!loading ? <MemoizedHorizontalStackBarChart chart={chartProps} /> : <MDCircularProgress size={36} />}
            </MDBox>
        </Card>
    );
};

export default HealthDataLineChart;

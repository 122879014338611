import axios from "axios";
import AuthService from "services/auth.service";

interface Pagination {
    next?: {
        page: number;
        limit: number;
    };
    prev?: {
        page: number;
        limit: number;
    };
    total: number;
    page: number;
    pages: number;
    count: number;
}

interface CMSPagination {
    page: number;
    pageSize: number;
    pageCount: number;
    total: number;
}

interface Error {
    status: string;
    name: string;
    message: string;
    details: string;
}

export interface Response<T> {
    success: boolean;
    count?: number;
    pagination?: Pagination;
    error?: string;
    data?: T;
}

export interface CMSResponse<T> {
    meta: CMSPagination;
    error?: Error;
    data?: T;
}

const instance = axios.create({
    baseURL:
        process.env.REACT_APP_ENV === "production"
            ? "https://api.realeasyliving.com/"
            : process.env.REACT_APP_ENV === "development"
            ? "https://devapi.realeasyliving.com/"
            : "http://localhost:8080/",
});

// const instance = axios.create({
//     baseURL: process.env.REACT_APP_ENV === "production" ? "https://devapi.realeasyliving.com/" : "http://localhost:6000/",
// });

if (process.env.REACT_APP_ENV === "production") {
    instance.defaults.withCredentials = true;
}

// Add request interceptor
instance.interceptors.request.use(function (config) {
    const token = AuthService.getAuthToken();
    if (token !== null) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
});

// Add a response interceptor
instance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            AuthService.logout();
            // <Navigate to="/sign-in" />;
        }
        // Should return response within any error code
        return error.response;
    },
);

export const CMSinstance = axios.create({
    baseURL: "https://cms.realeasyliving.com/api/",
});

// Add request interceptor
CMSinstance.interceptors.request.use(function (config) {
    const token = AuthService.getAuthToken();
    if (token !== null) {
        config.headers.Authorization = `Bearer de2e30272a5b91614f928da52d5a303a2e8cf7b4c8c69c82b0c006a997bb939a4a183d648ba7326642e88d95932eed6b6cddde1de8fc9ed419ab6ae35c424920e8723e0cb13f614f911140d2874b4095641c8e8274d81b896957a7e8c7f8b987b3fce6d78c1f1235cc69c016a7ce475dcaa5f7432b19468f61bf2601640fa288`;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
});

export default instance;

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "context/userContext";

import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDCircularProgress from "components/MDCircularProgress";
import MDNotification from "components/MDNotification";
import AddButton from "components/MDButton/addButton";

// Components
import BasicLayout from "examples/LayoutContainers/BasicLayout";
import BusinessCard from "layouts/settings/components/BusinessCard";
import NewBusiness from "layouts/settings/business-page/newBusiness";

// Business service
import businessService from "services/business.service";

// Types
import { Business } from "types/setting-schema";
import { FormOperation, FormType } from "types/enums";
import { isRelAdmin } from "types/user-schema";

// Helpers
import { isNotNil, isNil } from "helpers/utils";

// i18n
import { useTranslation } from "react-i18next";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

export const BusinessSettingPage: React.FC = () => {
    const { currentUser } = useAuth();
    const [businessData, setBusinessData] = useState<Business[]>(null);
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [mode, setMode] = useState<{ type: FormType; op: FormOperation }>({
        type: FormType.BUSINESS,
        op: FormOperation.CREATE,
    });
    const [formAlert, setFormAlert] = useState<boolean>(false);
    let state = useLocation().state as { op: FormOperation } | null;
    const { t: translate } = useTranslation(["common"]);

    useEffect(() => {
        fetchBusinesses();
        if (isNotNil(state)) {
            setMode({ ...mode, op: FormOperation.DELETE });
            setFormAlert(true);
        }
    }, []);

    useEffect(() => {
        if (formAlert) {
            setTimeout(() => {
                setFormAlert(false);
                window.history.replaceState({ state: null }, document.title); // reset state
                setMode({ ...mode, op: FormOperation.CREATE });
            }, 3000);
        }
    }, [formAlert]);

    const fetchBusinesses = async () => {
        const res = await businessService.getAllBusinesses();
        if (res.success) setBusinessData(res.data);
    };

    const childCallback = async (updated: boolean, type?: FormType) => {
        if (updated) {
            fetchBusinesses();
            setToggleModal(false);
            setMode({ ...mode, type: type });
            setFormAlert(true);
        }
    };

    const handleToggleModal = (type: FormType) => {
        setToggleModal(true);
        setMode({ ...mode, type: type });
    };

    const handleDirecttoDevicePage = (businessId: string) => {
        window.location.href = `/device`;
    }

    const renderBusinessCard = () => {
        return businessData.map((item: Business) => <BusinessCard key={item._id} {...item} callback={childCallback} />);
    };

    const renderAddBueinessButton = () => {
        if (currentUser.role === "installer") {
            return (
                <MDBox mb={2} px={2}>
                <AddButton
                    content={translate("sidemenu.device-list")}
                    handleToggleModal={() => handleDirecttoDevicePage(currentUser.businessId)}
                />
            </MDBox>
            );
        }
        if (!isRelAdmin(currentUser)) {
            return <></>;
        }

        return (
            <MDBox mb={2} px={2}>
                <AddButton
                    content={translate("general.button.addBusiness")}
                    handleToggleModal={() => handleToggleModal(FormType.BUSINESS)}
                />
            </MDBox>
        );
    };

    return (
        <BasicLayout>
            {toggleModal && <NewBusiness setToggleModal={setToggleModal} callback={childCallback} />}
            {formAlert && (
                <MDNotification
                    content={translate("general.notification.message", {
                        type: `${translate(`general.type.${mode.type}`)}`,
                        operation: `${translate(`general.operation.past.${mode.op}`)}`,
                    })}
                />
            )}
            {isNil(businessData) ? (
                <MDCircularProgress color="primary" />
            ) : (
                <>
                    {renderAddBueinessButton()}
                    <Grid container spacing={3} maxWidth={1920} display={{ xxl: "flex" }}>
                        {renderBusinessCard()}
                    </Grid>
                </>
            )}
        </BasicLayout>
    );
};

export default BusinessSettingPage;

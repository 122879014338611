import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDNoData from "components/MDNoData";
import MDTypography from "components/MDTypography";
import MDNotification from "components/MDNotification";
import MDCircularProgress from "components/MDCircularProgress";
import More from "components/MDIcon/more";
import { HealthData } from "types/measurement-schema";
import AddButton from "components/MDButton/addButton";
import DataTable from "examples/Tables/DataTable";
import DataText from "layouts/info/components/DataText";
import { BiometricsType, FormOperation } from "types/enums";
import { convertBloodSugar } from "helpers/infoUtil";
import NewMeasurement from "layouts/info/newMeasurement";
import ViewMeasurement from "layouts/info/viewMeasurement";
import BiometricsService from "services/biometrics.service";

// i18n
import { useTranslation } from "react-i18next";
import { isNotNil, isNil } from "helpers/utils";
import dayjs from "dayjs";

interface Cells {
    columns: { Header: string; accessor: string }[];
    rows: {
        id: string;
        date: string;
        bloodPressure: JSX.Element;
        bloodSugar: JSX.Element;
        pulse: JSX.Element;
        bloodOxygen: JSX.Element;
        temperature: JSX.Element;
        more: JSX.Element;
    }[];
}

interface Props {
    residentId: string;
    diabetes: boolean;
    timeZone: string;
}

const isToday = (date: string) => {
    const today = new Date();
    const someDate = new Date(date);

    return (
        someDate.getUTCDate() === today.getUTCDate() &&
        someDate.getUTCMonth() === today.getUTCMonth() &&
        someDate.getUTCFullYear() === today.getUTCFullYear()
    );
};

export const HealthDataTable: React.FC<Props> = ({ residentId, diabetes,timeZone }) => {
    const [biometricsList, setBiometricsList] = useState<HealthData[] | null>(null);
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [mode, setMode] = useState<{ op: FormOperation; biometricsId: string } | null>(null);
    const [formAlert, setFormAlert] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [count, setCount] = useState<number>(0);
    const { t: translate } = useTranslation(["common"]);

    useEffect(() => {
        fetchBiometrics();
    }, [page]);

    useEffect(() => {
        if (formAlert) {
            setTimeout(() => {
                setFormAlert(false);
            }, 3000);
        }
    }, [formAlert]);

    // useEffect(() => {
    //     fetchBiometrics();
    // }, [page]);

    const hasTodayData = biometricsList && biometricsList.length > 0 && isToday(biometricsList[0].inputDate);

    const renderTime = (time: string) => {
        // get yyyy-mm-dd hh:mm format by use dayjs and timezone 
        const date = dayjs(time).tz(timeZone).format("YYYY-MM-DD HH:mm");
        return date;
    };

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    }

    const renderTodayData = (type: BiometricsType, data: number | number[]) => {
        return (
            <>
                <MDBox mb={1}>
                    <MDTypography variant="button" fontWeight="regular">
                        {translate(`info.measurement.${type}`)}
                    </MDTypography>
                </MDBox>
                {typeof data === "number" ? (
                    <DataText {...{ type, data, isToday: true, diabetes: diabetes }} />
                ) : (
                    <DataText {...{ type, dataList: data, isToday: true, diabetes: diabetes }} />
                )}
            </>
        );
    };

    const fetchBiometrics = async () => {
        const res = await BiometricsService.getAllBiometricsByResident(residentId,null,null,page);

        if (res.success) {
            setBiometricsList(res.data);
            setCount(res.pagination.total);
        }
    };

    const handleToggleModal = (biometricsId?: string, op: FormOperation = FormOperation.CREATE) => {
        setMode({ op: op, biometricsId: biometricsId });
        setToggleModal(true);
    };

    const childCallback = async (updated: boolean) => {
        if (updated) {
            fetchBiometrics();
            setToggleModal(false);
            setFormAlert(true);
        }
    };

    const renderTopData = (firstData: HealthData) => {
        return (
            <Card sx={{ mb: 6, mx: 2 }}>
                <Grid
                    container
                    spacing={3}
                    p={2}
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleToggleModal(firstData._id, FormOperation.UPDATE)}>
                    <Grid item xs={6} md={3}>
                        {renderTodayData(BiometricsType.BLOOD_PRESSURE, [
                            firstData.systolicBloodPressure,
                            firstData.diastolicBloodPressure,
                        ])}
                    </Grid>
                    <Grid item xs={6} md={2}>
                        {renderTodayData(BiometricsType.BLOOD_SUGAR, convertBloodSugar(firstData.bloodSugar.level))}
                    </Grid>
                    <Grid item xs={6} md={2}>
                        {renderTodayData(BiometricsType.PULSE, firstData.pulse)}
                    </Grid>
                    <Grid item xs={6} md={2}>
                        {renderTodayData(BiometricsType.BLOOD_OXYGEN, firstData.bloodOxygen)}
                    </Grid>
                    <Grid item xs={6} md={2}>
                        {renderTodayData(BiometricsType.TEMPERATURE, firstData.temperature)}
                    </Grid>
                </Grid>
            </Card>
        );
    };

    const getDefaultMeasurement = (currentData: HealthData) => {
        const { height, weight, waistline, fat } = currentData;
        const originalObject = {
            height: height,
            weight: weight,
            waistline: waistline,
            fat: fat,
        };

        // Object.entries(originalObject) converts the object into an array of key-value pairs.
        const filteredObject = Object.entries(originalObject).reduce((acc: { [key: string]: number }, [key, value]) => {
            // check if the value is greater than 0. If it is, add the key-value pair to the accumulator (acc) object.
            if (value > 0) {
                acc[key] = value;
            }
            return acc;
        }, {});
        return filteredObject;
    };

    const renderTable = () => {
        if (biometricsList.length < 1)
            return (
                <MDNoData
                    content={translate("general.notification.noData", {
                        type: `${translate("general.type.measurement")}`,
                    })}
                />
            );

        const cells: Cells = {
            columns: translate("info.measurement.table", { returnObjects: true }),
            rows: [] as any[],
        };

        // biometricsList.forEach((biometrics, idx) => {
        //     if (idx > 0) {
        //         cells.rows.push({
        //             id: biometrics._id,
        //             date: renderTime(biometrics.inputDate),
        //             bloodPressure: (
        //                 <DataText
        //                     type={BiometricsType.BLOOD_PRESSURE}
        //                     dataList={[biometrics.systolicBloodPressure, biometrics.diastolicBloodPressure]}
        //                 />
        //             ),
        //             bloodSugar: (
        //                 <DataText
        //                     type={BiometricsType.BLOOD_SUGAR}
        //                     data={convertBloodSugar(biometrics.bloodSugar.level)}
        //                 />
        //             ),
        //             pulse: <DataText type={BiometricsType.PULSE} data={biometrics.pulse} />,
        //             bloodOxygen: <DataText type={BiometricsType.BLOOD_OXYGEN} data={biometrics.bloodOxygen} />,
        //             temperature: <DataText type={BiometricsType.TEMPERATURE} data={biometrics.temperature} />,
        //             more: <More />,
        //         });
        //     }
        // });
        biometricsList.forEach((biometrics, idx) => {
                cells.rows.push({
                    id: biometrics._id,
                    date: renderTime(biometrics.inputDate),
                    bloodPressure: (
                        <DataText
                            type={BiometricsType.BLOOD_PRESSURE}
                            dataList={[biometrics.systolicBloodPressure, biometrics.diastolicBloodPressure]}
                        />
                    ),
                    bloodSugar: (
                        <DataText
                            type={BiometricsType.BLOOD_SUGAR}
                            data={convertBloodSugar(biometrics.bloodSugar.level)}
                        />
                    ),
                    pulse: <DataText type={BiometricsType.PULSE} data={biometrics.pulse} />,
                    bloodOxygen: <DataText type={BiometricsType.BLOOD_OXYGEN} data={biometrics.bloodOxygen} />,
                    temperature: <DataText type={BiometricsType.TEMPERATURE} data={biometrics.temperature} />,
                    more: <More />,
                });
            
        });
        return <DataTable table={cells} handleOnClick={handleToggleModal} handlePageChange={handlePageChange} CustomPage={page} count={count} />;
    };

    const renderForm = () => {
        if (mode.op === FormOperation.UPDATE) {
            const initMeasurement = biometricsList.find((biometrics) => biometrics._id === mode.biometricsId);
            return (
                <ViewMeasurement
                    setToggleModal={setToggleModal}
                    residentId={residentId}
                    measurement={initMeasurement}
                    callback={childCallback}
                    timeZone={timeZone}
                />
            );
        } else {
            let defaultData = null;
            if (isNotNil(biometricsList) && biometricsList.length > 0) {
                defaultData = getDefaultMeasurement(biometricsList[0]);
            }

            return (
                <NewMeasurement
                    setToggleModal={setToggleModal}
                    residentId={residentId}
                    callback={childCallback}
                    defaultData={defaultData}
                />
            );
        }
    };

    return (
        <>
            {formAlert && (
                <MDNotification
                    content={translate("general.notification.message", {
                        type: `${translate("general.type.measurement")}`,
                        operation: `${translate(`general.operation.past.${mode.op}`)}`,
                    })}
                />
            )}
            {toggleModal && renderForm()}
            <Card>
                <MDBox display="flex" justifyContent={hasTodayData ? "space-between" : "end"} m={3} mb={1} mt={2}>
                    {hasTodayData && (
                        <MDBox display={{ md: "flex" }} alignItems="center">
                            <MDTypography variant="h2" mr={2}>
                                {translate(`info.measurement.todayNewest`)}
                            </MDTypography>
                            {/* <MDTypography variant="subtitle2">{renderTime(biometricsList[0].inputDate)}</MDTypography> */}
                        </MDBox>
                    )}
                    <AddButton
                        content={translate("general.button.addMeasurement")}
                        handleToggleModal={handleToggleModal}
                    />
                </MDBox>
                {isNil(biometricsList) ? (
                    <MDCircularProgress />
                ) : (
                    <>
                        {/* {hasTodayData && renderTopData(biometricsList[0])} */}
                        {renderTable()}
                    </>
                )}
            </Card>
        </>
    );
};

export default HealthDataTable;

/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2024 REL Technology Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDErrorMsg from "components/MDErrorMsg";

// Authentication layout components
import BasicLayout from "layouts/authentications/components/BasicLayout";
import SubmitButton from "layouts/authentications/components/SubmitButton";

// Authentication
import AuthService from "services/auth.service";

// User context
import { useAuth } from "context/userContext";

// Validate
import { validateEmail, validatePassword } from "helpers/validations";
import DialogHeader from "../components/DialogHeader";

// Helpers
import { isNotNil, isNil } from "helpers/utils";

import { useTranslation } from "react-i18next";
import { globalStyle } from "styles/global.styles";

const login = (loginInfo: any) => {
    return AuthService.login(loginInfo);
};

const getUserData = () => {
    try {
        return AuthService.getUser();
    } catch (error: any) {
        return;
    }
};

export const Basic: React.FC = () => {
    const { setCurrentUser } = useAuth();
    const [formFields, setFormFields] = useState({ email: "", password: "" });
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const navigate = useNavigate();
    const { t: translate } = useTranslation(["common"]);

    useEffect(() => {
        const res = getRememberMeStorage();
        if (isNotNil(res)) {
            setRememberMe(true);
            setFormFields({ ...formFields, email: res });
        }
    }, []);

    const handleSetRememberMe = () => setRememberMe(!rememberMe);

    const handleFormChange = (e: any) => {
        const { name, value } = e.target;
        setFormFields({ ...formFields, [name]: value });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setErrorMsg(null);

        if (!validateEmail(formFields.email)) {
            setErrorMsg(translate("auth.error.emailFormat"));
            return;
        }

        const info = {
            email: formFields.email,
            password: formFields.password,
        };
        const res = await login(info);
        if (res.success) {
            const user = await getUserData();
            setCurrentUser(user);

            setRememberMeStorage(formFields.email);

            navigate("/dashboard");
        } else {
            setErrorMsg(translate("auth.error.combination"));
        }
    };

    const getRememberMeStorage = () => {
        if (isNil(localStorage.getItem("rememberMe-token"))) return null;
        const data = JSON.parse(localStorage.getItem("rememberMe-token"));

        // If expiry day is today, remove token
        if (new Date(data.expiryDate).getTime() === new Date().getTime()) {
            localStorage.removeItem("rememberMe-token");
            return null;
        }
        return data.email;
    };

    const setRememberMeStorage = (email: string) => {
        if (!rememberMe) {
            localStorage.removeItem("rememberMe-token");
            return;
        }
        // Set expiry date to 30 days after today
        const date = new Date().setDate(new Date().getDate() + 30);
        const info = {
            email: email,
            expiryDate: date,
        };
        localStorage.setItem("rememberMe-token", JSON.stringify(info));
    };

    return (
        <BasicLayout>
            <DialogHeader title={translate("auth.welcome")} />
            <MDBox component="form" role="form" noValidate onSubmit={(e) => handleSubmit(e)}>
                <MDBox mb={2}>
                    <MDInput
                        type="email"
                        name="email"
                        label={translate("auth.email")}
                        value={formFields.email}
                        fullWidth
                        onChange={handleFormChange}
                    />
                </MDBox>
                <MDBox mb={2}>
                    <MDInput
                        type="password"
                        name="password"
                        label={translate("auth.password")}
                        fullWidth
                        onChange={handleFormChange}
                    />
                </MDBox>
                <MDBox ml={-1} mb={2} sx={globalStyle.flex.vertical}>
                    <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                    <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        ml={-1}
                        sx={{ userSelect: "none" }}>
                        &nbsp;&nbsp;{translate("auth.rememberMe")}
                    </MDTypography>
                </MDBox>
                {isNotNil(errorMsg) && <MDErrorMsg errorMsg={errorMsg} />}
                <MDBox mt={3} mb={1}>
                    <SubmitButton
                        content={translate("auth.login")}
                        disable={formFields.email.length === 0 || formFields.password.length === 0}
                    />
                </MDBox>
                <MDBox mt={3} textAlign="center">
                    <MDTypography
                        component={Link}
                        to="/forgot-password"
                        variant="button"
                        color="text"
                        fontWeight="medium">
                        {translate("auth.forgotPassword")}?
                    </MDTypography>
                </MDBox>
            </MDBox>
        </BasicLayout>
    );
};

export default Basic;

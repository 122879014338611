import axios from "axios";
import MDBox from "components/MDBox";
import MDTab from "components/MDTab";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthService from "services/auth.service";
import ResidentService from "services/resident.service";
import { Instance } from "flatpickr/dist/types/instance";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card/Card";
import Grid from "@mui/material/Grid/Grid";
import FormControl from "@mui/material/FormControl/FormControl";
import Select from "@mui/material/Select";

import Icon from "@mui/material/Icon/Icon";
// react-flatpickr components
import Flatpickr from "react-flatpickr";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { isNotNil } from "helpers/utils";
import MDErrorMsg from "components/MDErrorMsg";
import { Reports } from "types/report";
import { MenuItem } from "@mui/material";
const reportTypeOptions = [
    Reports.vsDaily,
    Reports.vsThreeDays,
    Reports.vsSeveral,
    Reports.vsFourteenDays,
    Reports.careFourteenDays,
];

export const DownloadReport: React.FC = () => {
    const { t: translate } = useTranslation(["common"]);
    const [error, setError] = useState<string | null>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [selectedDates, setSelectedDates] = useState({ startDate: null, endDate: null });
    const [reportType, setReportType] = useState<Reports>(reportTypeOptions[0]);
    const [resident, setResident] = useState<any>(null); //todo set resident here
    const [room, setRoom] = useState<any[]>([]); //todo set resident here
    const fp = useRef(null);
    const submitForm = async (event: any) => {
        event.preventDefault();
        setError(null);
        if(resident === null){
            setError(translate("setting.resident.emptyRoomError"));
            return;
        }
        if (selectedDates.endDate === null && selectedDates.startDate === null) {
            setError(translate("setting.resident.emptyDateError"));
            return;
        }
        try {
            const instance = axios.create({
                baseURL:
                process.env.REACT_APP_ENV === "production"
                    ? "https://api.realeasyliving.com/"
                    : process.env.REACT_APP_ENV === "development"
                    ? "https://devapi.realeasyliving.com/"
                    : "http://localhost:8080/",
            });
            instance.interceptors.request.use(function (config) {
                const token = AuthService.getAuthToken();
                if (token !== null) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                config.headers["Content-Type"] = "application/json";
                return config;
            });
            setIsFetching(true);
            //todo set resident id here
            const wistronResident = await ResidentService.getWistronResidentByID(resident);

            // const wistronResident = await ResidentService.getWistronResidentByID(resident.wistron_id);
            if (wistronResident.data) {
                const { mom, floor, roomName, bed } = wistronResident.data;
                let dateRange;
                if (selectedDates.startDate === selectedDates.endDate) {
                    dateRange = selectedDates.startDate;
                } else {
                    dateRange = `${selectedDates.startDate}~${selectedDates.endDate}`;
                }
                const response = await instance.get(
                    `/api/v1/report/getReport?lang=${translate(
                        "lang",
                    )}&reportType=${reportType}&bed=${floor}-${roomName}-${bed}&residentName=${mom}&dateRange=${dateRange}`,
                    { responseType: "blob" },
                );

                if (response.data) {
                    let reportFileName;
                    switch (reportType) {
                        case Reports.vsDaily:
                            reportFileName = "VS-Daily";
                            break;
                        case Reports.vsThreeDays:
                            reportFileName = "VS-ThreeDays";
                            break;
                        case Reports.vsSeveral:
                            reportFileName = "VS-Several";
                            break;
                        case Reports.vsFourteenDays:
                            reportFileName = "VS-Biweekly";
                            break;
                        case Reports.careFourteenDays:
                            reportFileName = "Care-Biweekly";
                            break;
                        default:
                            break;
                    }
                    const contentDisposition = response.headers["content-disposition"];
                    let filename = `${reportFileName}-${floor}-${roomName}-${bed}-${mom}-${selectedDates.startDate}~${selectedDates.endDate}.pdf`; // Default filename
                    if (contentDisposition) {
                        const filenameRegex = /filename\*=UTF-8''([^;]*)/;
                        const matches = filenameRegex.exec(contentDisposition);
                        if (matches != null && matches[1]) {
                            filename = decodeURIComponent(matches[1]);
                        }
                    }
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                    setIsFetching(false);
                }
            }
        } catch (error) {
            setError(translate("setting.resident.fetchReportError"));
            setIsFetching(false);
        }
    };
    const handleReportTypeChange = (e: any) => {
        setReportType(e.target.value);
    };
    const handleResidentChange = (e: any) => {
        setResident(e.target.value);
    };
    const formatDate = (date: Date) => {
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    const handleSetDate = (date: Date, range: number, self: Instance) => {
        let startDate, endDate;
        if (range === 1) {
            startDate = formatDate(date);
            endDate = formatDate(date);
            self.clear();
            self.setDate([startDate], false, "Y/m/d");
        } else {
            // if (new Date(date.getTime() + 86400000 * range) > new Date()) {
            //     console.log("adjustedTimeAsMs > new Date().getTime()");
            //     startDate = formatDate(new Date(new Date(date).getTime() - 86400000 * (range - 1)));
            //     endDate = formatDate(date);
            // } else {
            //     endDate = formatDate(new Date(new Date(date).getTime() + 86400000 * (range - 1)));
            //     startDate = formatDate(date);
            // }
            endDate = formatDate(new Date(new Date(date).getTime() - 86400000 * (range - 1)));
            startDate = formatDate(date);
            //update the Flatpickr
            self.clear();
            self.setDate([startDate, endDate], false, "Y/m/d");
        }
        setSelectedDates({ startDate, endDate });
    };
    useEffect(() => {
        //fetch resident here
        const fetchResident = async () => {
            try {
                const response = await ResidentService.getAllResidentWithWistronId();
                const list = response.data.map((item: any) => {
                    return {
                        label: `${
                            item?.unitRecords?.[0]?.unit?.floor !== undefined && item?.unitRecords?.[0]?.unit?.floor !== null
                                ? item.unitRecords[0].unit.floor + "F" + "-" + item.unitRecords[0].unit.unitNumber
                                : "No Floor - " + (item?.unitRecords?.[0]?.unit?.unitNumber ?? "Unknown Unit")
                        } - ${item.name}`,
                        value: item.wistron_id,
                    };
                });
                setRoom(list);
            } catch (error) {
                console.error("fetchTodayReport -> error", error);
            }
        };
        fetchResident();
    }, []);
    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null);
            }, 10000);
        }
    }, [error]);
    useEffect(() => {
        if (!fp?.current?.flatpickr) return;
        fp.current.flatpickr.clear();
    }, [reportType]);

    return (
        <MDBox p={2}>
            <Grid container columnSpacing={2} rowSpacing={5} justifyContent="center" alignItems="stretch">
                {isNotNil(error) && (
                    <Grid item xs={12}>
                        <MDErrorMsg errorMsg={error} />
                    </Grid>
                )}
                <Grid item xs={12} md={12}>
                    <MDBox>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={2.5}>
                                <MDBox>
                                    <MDTypography variant="h5" color="lightBlue">
                                        {translate(`report.reportType.title`)}
                                        <span style={{ color: "red", verticalAlign: "super" }}>*</span>
                                    </MDTypography>
                                    <FormControl sx={{ width: "100%", position: "relative" }}>
                                        <Select
                                            disabled={isFetching}
                                            defaultValue="All"
                                            sx={{
                                                py: 1,
                                                background: "#E7E7E7",
                                                // border: "1px solid #344767",
                                                borderRadius: "0px",
                                                position: "relative",
                                                zIndex: 5,
                                            }}
                                            value={reportType}
                                            onChange={handleReportTypeChange}>
                                            {reportTypeOptions.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <MDTypography variant="h5" pl={1} pr={5}>
                                                        {translate(`report.reportType.${item}`)}
                                                    </MDTypography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Icon
                                            sx={{
                                                position: "absolute",
                                                right: "20px",
                                                transform: "translateY(50%)",
                                                display: "inline-block !important",
                                                zIndex: "6",
                                            }}>
                                            expand_more
                                        </Icon>
                                    </FormControl>
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={2.5}>
                                <MDBox>
                                    <MDTypography variant="h5" color="lightBlue">
                                        {translate(`report.period`)}
                                        <span style={{ color: "red", verticalAlign: "super" }}>*</span>
                                    </MDTypography>
                                    <FormControl sx={{ width: "100%", position: "relative" }}>
                                        <MDBox
                                            sx={{
                                                py: 1,
                                                background: "#E7E7E7",
                                                borderRadius: "0px",
                                                position: "relative",
                                            }}>
                                            <Flatpickr
                                                ref={fp}
                                                disabled={isFetching}
                                                options={{
                                                    mode: reportType !== reportTypeOptions[0] ? "range" : "single",
                                                    locale: {
                                                        rangeSeparator: " - ",
                                                    },
                                                    maxDate: new Date(),
                                                    onChange: (
                                                        date: Date[],
                                                        currentDateString: string,
                                                        self: Instance,
                                                        data: any,
                                                    ) => {
                                                        if (date.length === 1) {
                                                            switch (reportType) {
                                                                case Reports.vsDaily:
                                                                    handleSetDate(date[0], 1, self);
                                                                    break;
                                                                case Reports.vsThreeDays:
                                                                    handleSetDate(date[0], 3, self);
                                                                    break;
                                                                case Reports.vsSeveral:
                                                                    handleSetDate(date[0], 7, self);
                                                                    break;
                                                                case Reports.vsFourteenDays:
                                                                    handleSetDate(date[0], 14, self);
                                                                    break;
                                                                case Reports.careFourteenDays:
                                                                    handleSetDate(date[0], 14, self);
                                                                    break;
                                                            }
                                                        } else {
                                                        }
                                                    },
                                                }}
                                                render={({ defaultValue }: any, ref: any) => (
                                                    <MDInput
                                                        disabled={isFetching}
                                                        sx={{
                                                            height: "22px",
                                                            width: "100%",
                                                            padding: "0 8px",
                                                            textAlign: "center",
                                                            "& input": {
                                                                fontSize: "1rem",
                                                                fontWeight: "700",
                                                                padding: "0",
                                                                "&:before": { border: "0 !important" },
                                                            },
                                                        }}
                                                        defaultValue={defaultValue}
                                                        inputRef={ref}
                                                    />
                                                )}
                                            />
                                        </MDBox>
                                    </FormControl>
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={2.5}>
                                <MDBox>
                                    <MDTypography variant="h5" color="lightBlue">
                                        {translate(`report.resident`)}
                                        <span style={{ color: "red", verticalAlign: "super" }}>*</span>
                                    </MDTypography>
                                    <FormControl sx={{ width: "100%", position: "relative" }}>
                                        <Select
                                            disabled={isFetching}
                                            defaultValue="All"
                                            sx={{
                                                py: 1,
                                                background: "#E7E7E7",
                                                // border: "1px solid #344767",
                                                borderRadius: "0px",
                                                position: "relative",
                                                zIndex: 5,
                                            }}
                                            value={resident}
                                            onChange={handleResidentChange}>
                                            {room.map((item) => (
                                                <MenuItem key={item.value} value={item.value}>
                                                    <MDTypography variant="h5" pl={1} pr={5}>
                                                        {item.label}
                                                    </MDTypography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Icon
                                            sx={{
                                                position: "absolute",
                                                right: "20px",
                                                transform: "translateY(50%)",
                                                display: "inline-block !important",
                                                zIndex: "6",
                                            }}>
                                            expand_more
                                        </Icon>
                                    </FormControl>
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={4.5}>
                                <MDBox sx={{ textAlign: "right" }}>
                                    <MDTypography variant="h5" color="lightBlue">
                                        <span>&nbsp;</span>
                                    </MDTypography>
                                    <MDButton
                                        disabled={isFetching}
                                        sx={{
                                            background: "#BDBDFF",
                                            color: "black",
                                            borderRadius: 0,
                                            height: "38px",
                                            minWidth: "179px",
                                        }}
                                        circular={isFetching}
                                        onClick={submitForm}>
                                        {isFetching ? (
                                            <CircularProgress sx={{ color: "white !important" }} size={20} />
                                        ) : (
                                            translate(`report.download`)
                                        )}
                                    </MDButton>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox>
                </Grid>
            </Grid>
        </MDBox>
    );
};

export default DownloadReport;
import Grid from "@mui/material/Grid/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UnitService from "services/unit.service";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon/Icon";
import MDInput from "components/MDInput";
import MDCircularProgress from "components/MDCircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { set } from "date-fns";
import AddButton from "components/MDButton/addButton";

const FloorSetting = () => {
    const { t: translate } = useTranslation(["common"]);
    const [roomData, setRoomData] = useState<any[]>([]);
    const [selectedRoom, setSelectedRoom] = useState<any>(0);
    const [setting, setSetting] = useState(null);
    const [_setting, _setSetting] = useState<any[]>([]);
    const [grid, setGrid] = useState<{ column: string; row: string }>({ column: "1", row: "1" });
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteable, setDeleteable] = useState(true);
    const [newRoom, setNewRoom] = useState(false);
    const [modal, setModal] = useState(false);
    const getWistronRoomList = async () => {
        setLoading(true);
        setDeleteable(false);
        setGrid({ column: "0", row: "0" });
        setSetting(null);
        setEdit(false);
        _setSetting([]);
        setSelectedRoom(0);
        setNewRoom(false);
        const res = await UnitService.getWistronRoomList("123");
        if (res && res.data.length > 0) {
            setRoomData(res.data);
            setSelectedRoom(res.data[0]?.id);
        } else {
            setRoomData([]);
            setLoading(false);
        }
    };
    const addWistronRoom = async (body: any) => {
        const res = await UnitService.addWistronRoom(body);
        if (res) {
            getWistronRoomList();
        }
    };
    const updateWistronRoom = async (body: any) => {
        const res = await UnitService.updateWistronRoom(body);
        if (res) {
            getWistronRoomList();
        }
    };
    const deleteWistronRoom = async (floor: any) => {
        const res = await UnitService.deleteWistronRoom(floor);
        setModal(false);
        if (res) {
            getWistronRoomList();
        }
    };
    const getWistronRoom = async (id: any, floor: any) => {
        const res = await UnitService.getWistronRoom(id, floor);
        if (res) {
            return res.data;
        }
    };
    const handleSelectRoom = (room: any) => {
        if (loading) return;
        setSelectedRoom(room);
    };

    useEffect(() => {
        getWistronRoomList();
    }, []);

    useEffect(() => {
        if (!newRoom) {
            setEdit(false);
        }

        if (roomData[selectedRoom]?.setting) {
            setSetting(JSON.parse(roomData[selectedRoom]?.setting));
            setGrid(JSON.parse(roomData[selectedRoom]?.grid));
        }
        if (newRoom) {
            setSetting([]);
            setGrid({ column: "1", row: "1" });
        }
    }, [selectedRoom, roomData]);

    useEffect(() => {
        const fetchRoomData = async () => {
            if (setting && !edit) {
                const settingCopy = setting.map((room: any) => ({ ...room, editable: true })); // Initialize `editable` to true
                setDeleteable(false);
                for (let index = 0; index < settingCopy.length; index++) {
                    const room = settingCopy[index];
                    try {
                        setLoading(true);
                        const roomInfo = await getWistronRoom(room.room, roomData[selectedRoom].floor);

                        if (roomInfo.length > 0 && roomInfo[0].roomName == room.room) {
                            settingCopy[index].editable = false;
                            setDeleteable(true);
                        }
                    } catch (error) {
                        console.error(`Error fetching data for room ${room.room}:`, error);
                    }
                }
                setLoading(false);
                _setSetting(settingCopy);
            }
        };

        fetchRoomData();
    }, [setting]);

    useEffect(() => {
        if (edit) {
            const newGrid = [];
            for (let i = 0; i < parseInt(grid.column) * parseInt(grid.row); i++) {
                newGrid.push({ room: "", order: i, editable: true });
            }
            _setSetting(newGrid);
        }
    }, [grid]);

    const handleInputChange = (index: any, event: any) => {
        const updatedSetting = [..._setting];
        console.log(event.target.value);
        updatedSetting[index].room = event.target.value;
        _setSetting(updatedSetting);
    };

    const handleEdit = () => {
        if (edit === false) {
            setEdit(!edit);
        } else {
            setEdit(!edit);
            let _settingCopy = JSON.parse(JSON.stringify(_setting));

            _settingCopy.forEach((room: any, index: any) => {
                delete _settingCopy[index].editable;
            });

            const body = {
                grid: grid,
                setting: JSON.stringify(_settingCopy),
                floor: roomData[selectedRoom].floor,
            };
            if (roomData[selectedRoom].setting) {
                updateWistronRoom(body);
            } else {
                addWistronRoom(body);
            }
        }
    };

    const handleCancelOrDelete = () => {
        if (edit) {
            if (newRoom) {
                setNewRoom(false);
                const updatedRoomData = roomData.slice(0, roomData.length - 1);
                setRoomData(updatedRoomData);
                setSelectedRoom(updatedRoomData.length - 1); 
            } else {
                setEdit(false);
                setSetting(JSON.parse(roomData[selectedRoom].setting));
                setGrid(JSON.parse(roomData[selectedRoom].grid));
            }
        } else {
            setModal(true);
        }
    };

    const handleAddRoom = () => {
        if (newRoom) return;
        setNewRoom(true);
        setRoomData([...roomData, { floor: "New", grid: JSON.stringify({ column: "1", row: "1" }), setting: '' }]);
        setSelectedRoom(roomData.length);
        setDeleteable(false);
        setEdit(true);
    };

    const renderTable = () => {
        return (
            <>
                <MDBox
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        maxWidth: "1000px",
                        width: "100%",
                    }}>
                    <MDBox sx={{ display: "flex", fontSize: "20px", alignItems: "center", marginBottom: "8px" }}>
                        {edit && !deleteable ? (
                            <MDInput
                                value={roomData[selectedRoom].floor}
                                variant="outlined"
                                onChange={(event: any) => {
                                    const newValue = event.target.value;
                                    if (newValue.length <= 3) {
                                        const newRoomData = [...roomData];
                                        newRoomData[selectedRoom].floor = newValue;
                                        setRoomData(newRoomData);
                                    }
                                }}
                                sx={{
                                    width: "100px",
                                    textAlignLast: "center",
                                    marginRight: "15px",
                                    "& .MuiInputBase-input": { padding: "5px 0" },
                                }}
                            />
                        ) : (
                            <MDTypography
                                sx={{ marginRight: "35px" }}>{`${roomData[selectedRoom].floor}`}</MDTypography>
                        )}

                        <MDTypography sx={{ fontSize: "18px", marginLeft: "15px" }}>{translate(`setting.room.floor.plan`)}</MDTypography>
                    </MDBox>
                    <MDBox sx={{ display: "flex", fontSize: "20px" }}>
                        <MDButton
                            type="button"
                            variant="outlined"
                            circular={false}
                            color="error"
                            // disabled={edit ? false : loading || deleteable}
                            disabled={true}
                            sx={{ minWidth: "100px", width: "100px" }}
                            onClick={() => handleCancelOrDelete()}>
                            {edit ? translate(`general.button.cancel`) : translate(`general.button.delete`)}
                        </MDButton>
                        <MDButton
                            sx={{ minWidth: "100px", width: "100px", marginLeft: "20px" }}
                            circular={false}
                            // disabled={loading}
                            disabled={true}
                            onClick={() => handleEdit()}>
                            <Icon fontSize="medium" sx={{ cursor: "pointer", marginRight: "5px" }}>
                                create
                            </Icon>
                            {edit ? translate(`general.button.update`) : translate(`general.button.edit`)}
                        </MDButton>
                    </MDBox>
                </MDBox>
                <MDBox display="flex">
                    <MDTypography sx={{ fontSize: "14px", marginRight: "25px", color: "#000000a6" }}>
                        {!edit || deleteable ? (
                            `${grid.column} ${translate(`setting.room.floor.column`)}`
                        ) : (
                            <>
                                <Select
                                    inputProps={{ "aria-label": "Without label" }}
                                    value={grid.column}
                                    onChange={(event: SelectChangeEvent) =>
                                        setGrid({ ...grid, column: event.target.value })
                                    }
                                    sx={{
                                        width: "100px",
                                        textAlignLast: "center",
                                        marginRight: "6px",
                                        padding: "5px 0",
                                    }}>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                </Select>
                                {translate(`setting.room.floor.column`)}
                            </>
                        )}
                    </MDTypography>
                    <MDTypography sx={{ fontSize: "14px", color: "#000000a6" }}>
                        {!edit || deleteable ? (
                            `${grid.row} ${translate(`setting.room.floor.row`)}`
                        ) : (
                            <>
                                <Select
                                    inputProps={{ "aria-label": "Without label" }}
                                    value={grid.row}
                                    onChange={(event: SelectChangeEvent) =>
                                        setGrid({ ...grid, row: event.target.value })
                                    }
                                    sx={{
                                        width: "100px",
                                        textAlignLast: "center",
                                        marginRight: "6px",
                                        padding: "5px 0",
                                    }}>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                </Select>
                                {translate(`setting.room.floor.row`)}
                            </>
                        )}
                    </MDTypography>
                </MDBox>
                <MDBox sx={{ paddingTop: "15px" }}>
                    <Grid container sx={{ maxWidth: "1000px" }} rowGap={5}>
                        {loading ? (
                            // Show loading indicator when loading is true
                            <MDCircularProgress size={20} />
                        ) : (
                            // Show the content when loading is false
                            _setting.map((room: any, index: any) => (
                                <Grid item key={index} xs={2.4}>
                                    <MDBox
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "150px",
                                            width: "150px",
                                            border: "1px solid #cccccc",
                                            margin: "5px",
                                            borderRadius: "15px",
                                        }}>
                                        {!edit ? (
                                            <MDTypography sx={{ fontSize: "14px" }}>{room.room}</MDTypography>
                                        ) : (
                                            <MDInput
                                                value={room.room}
                                                variant="outlined"
                                                disabled={!room.editable}
                                                onChange={(event: any) => handleInputChange(index, event)}
                                                sx={{ width: "100px", textAlignLast: "center" }}
                                            />
                                        )}
                                    </MDBox>
                                </Grid>
                            ))
                        )}
                    </Grid>
                </MDBox>
            </>
        );
    };

    const deleteModal = () => {
        return (
            <MDBox
                sx={{
                    display: "flex",
                    position: "fixed",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0,0,0,.5)",
                    zIndex: "999",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                <MDBox
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        padding: "20px",
                        borderRadius: "10px",
                    }}>
                    <MDTypography sx={{ fontSize: "20px", marginBottom: "20px" }}>
                        {translate(`general.popup.deleteFloor`)}
                    </MDTypography>
                    <MDBox sx={{ display: "flex", justifyContent: "right", width: "100%" }}>
                        <MDButton
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                deleteWistronRoom(roomData[selectedRoom].floor);
                            }}>
                            {translate(`general.button.delete`)}
                        </MDButton>
                        <MDButton
                            variant="outlined"
                            color="primary"
                            sx={{ marginLeft: "20px" }}
                            onClick={() => {
                                setDeleteable(false);
                            }}>
                            {translate(`general.button.cancel`)}
                        </MDButton>
                    </MDBox>
                </MDBox>
            </MDBox>
        );
    };
    return (
        <>
            {modal && deleteModal()}
            <MDBox sx={{ display: "flex", clear: "both", padding: "20px 0 ", width: "100%" }} />
            <MDBox sx={{ display: "flex", width: "100%", paddingBottom: "25px" }}>
                <MDBox
                    sx={{
                        borderRight: "1px solid #cccccc",
                        paddingRight: "10px",
                        marginRight: "20px",
                        cursor: loading ? "not-allowed" : "pointer",
                        textAlign: "center",
                    }}>
                    {roomData.map((room, index) => {
                        const grid = JSON.parse(room.grid);
                        return (
                            <MDBox key={index} sx={{ width: "200px" }} onClick={() => handleSelectRoom(index)}>
                                <MDBox
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginBottom: "20px",
                                        marginRight: "25px",
                                        paddingLeft: "24px",
                                        width:"100%"
                                    }}>
                                    <MDTypography sx={{ display: "flex", alignItems: "baseline" }}>
                                        <MDTypography sx={{ fontSize: "18px", fontWeight: "700",width:"70%"}}>
                                            {room.floor}
                                        </MDTypography>
                                        <MDTypography
                                            sx={{ fontSize: "12px", color: "#000000a6", paddingLeft: "4px",width:"30%" }}>{`${
                                            grid.column * grid.row
                                            } ${translate(`setting.room.floor.room`)}`}</MDTypography>
                                    </MDTypography>
                                    {selectedRoom === index && <KeyboardArrowRightIcon />}
                                </MDBox>
                                <MDBox
                                    sx={{
                                        display: "flex",
                                        clear: "both",
                                        width: "100%",
                                        minWidth: "100%",
                                        margin: "24px 0 ",
                                        borderTop: "1px solid rgba(0,0,0,.06)",
                                    }}
                                />
                            </MDBox>
                        );
                    })}
                    <AddButton
                        content={translate(`general.button.addFloor`)}
                        handleToggleModal={() => {
                            setEdit(true);
                            handleAddRoom();
                        }}
                        // isDisabled={loading || newRoom}
                        isDisabled={true}
                    />
                </MDBox>
                <MDBox sx={{ boxSizing: "border-box", width: "100%" }}>
                    {roomData.length > 0 && setting && renderTable()}
                </MDBox>
            </MDBox>
        </>
    );
};

export default FloorSetting;

import { Response, default as http } from "http-common";
import { TodayAlertsHistory } from "types/alert-schema";

class AlarmService {
    async getTodayBusinessAlarm(
        type: "businessId" | "centerId" | "all",
        value: string,
        timeZone: string,
        page?:number
    ): Promise<Response<TodayAlertsHistory>> {
        const response = await http.get<Response<TodayAlertsHistory>>(`/api/v1/alerts?${type}=${value}&timeZone=${timeZone}&page=${page}`);
        return response.data;
    }
    async updateAlarmStatus(
        alertId: string,
        ownerReport: boolean,
        status?: boolean
    ): Promise<Response<TodayAlertsHistory>> {
        const response = await http.post<Response<TodayAlertsHistory>>(`/api/v1/alerts/update`, {
            alertId: alertId,
            status: status,
            ownerReport: ownerReport,
        });
        return response.data;
    }

    async updateAlarmReaded(alertId: string): Promise<Response<any>> {
        const response = await http.post<Response<any>>(`/api/v1/alerts/${alertId}`);
        return response.data;
    }
}

export default new AlarmService();

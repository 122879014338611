/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2024 REL Technology Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactNode } from "react";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import { Theme } from "@mui/material/styles";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Custom styles for the SidenavItem
import { item, itemContent, itemArrow } from "examples/Sidenav/styles/sidenavItem";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from "context";

// Declaring props types for SidenavCollapse
interface Props {
    name: string;
    active?: boolean | string;
    nested?: boolean;
    children?: ReactNode;
    open?: boolean;
    [key: string]: any;
}

export const SidenavItem: React.FC<Props> = ({ name, active, nested, children, open, ...rest }) => {
    const [controller] = useMaterialUIController();
    const { miniSidenav } = controller;

    return (
        <>
            <ListItem {...rest} component="li" sx={(theme) => item(theme, { active })}>
                <MDBox
                    sx={(theme: Theme): any =>
                        itemContent(theme, {
                            active,
                            miniSidenav,
                            name,
                            open,
                            nested,
                        })
                    }>
                    <ListItemText primary={name} />
                    {children && (
                        <Icon component="i" sx={(theme) => itemArrow(theme, { open, miniSidenav })}>
                            expand_less
                        </Icon>
                    )}
                </MDBox>
            </ListItem>
            {children && (
                <Collapse in={open} timeout="auto" unmountOnExit {...rest}>
                    {children}
                </Collapse>
            )}
        </>
    );
};

// Declaring default props for SidenavItem
SidenavItem.defaultProps = {
    active: false,
    nested: false,
    children: false,
    open: false,
};

export default SidenavItem;

// Setting layout components
import MDModal from "components/MDModal";
import MeasurementForm from "layouts/info/components/Form/Measurement";

// Type
import { FormOperation } from "types/enums";
import { ToggleProp } from "types/state";
import { HealthData } from "types/measurement-schema";

interface Props extends ToggleProp {
    residentId: string;
    measurement: HealthData;
    callback: (updated: boolean) => void;
    timeZone?: string;
}

export const ViewMeasurement: React.FC<Props> = ({ setToggleModal, residentId, measurement, callback,timeZone }) => {
    return (
        <MDModal type="form" setToggleModal={setToggleModal}>
            <MeasurementForm
                operation={FormOperation.VIEW}
                residentId={residentId}
                measurement={measurement}
                callback={callback}
                timeZone={timeZone}
            />
        </MDModal>
    );
};

export default ViewMeasurement;

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ResidentCardProps } from "layouts/info/schemas/card-schema";
import { Icon } from "@mui/material";

import BasicLayout from "examples/LayoutContainers/BasicLayout";
import MDTab from "components/MDTab";
import MDBox from "components/MDBox";
import MDCircularProgress from "components/MDCircularProgress";
import ResidentCard from "layouts/info/components/ResidentCard";
import RiskCard from "layouts/info/components/RiskCard";
import HealthDataTable from "layouts/info/components/HealthDataTable";
import HealthDataChart from "layouts/info/components/HealthDataChart";
import Survey from "layouts/info/components/Survey";
import { Risk } from "types/setting-schema";

import { isNotNil, isNil } from "helpers/utils";
import ResidentService from "services/resident.service";
import { useTranslation } from "react-i18next";
import { is } from "date-fns/locale";

export const MeasurementPage: React.FC = () => {
    let state = useLocation().state as { residentId: string; tag?: string } | null;
    const [resident, setResident] = useState<ResidentCardProps | null>(null);
    const [activePage, setActivePage] = useState("measurement");
    const [activeMode, setActiveMode] = useState<string>("list");
    const { t: translate } = useTranslation(["common"]);

    useEffect(() => {
        if (isNotNil(state.residentId)) {
            fetchResident();
        }
        if (isNotNil(state.tag)) {
            setActiveMode(state.tag);
        }
    }, []);

    const fetchResident = async () => {
        const res = await ResidentService.getSingleResident(state.residentId);
        if (res.success) {
            const { _id, avatarUrl, name, dateOfBirth, gender, address, center, risks, diabetes, preferredLanguage } =
                res.data;

            const data = {
                id: _id,
                avatarUrl: isNotNil(avatarUrl) ? avatarUrl : null,
                name: name,
                gender: gender,
                dateOfBirth: dateOfBirth,
                center: isNotNil(center) ? center.name : null,
                address: address,
                risks: risks,
                diabetes: diabetes,
                preferredLanguage: preferredLanguage,
            };
            setResident(data);
        }
    };

    const handleCallback = (updated: boolean) => {
        if (updated) {
            setResident(null);
            fetchResident();
        }
    };

    const getUniqueRisks = (arr: Risk[]) => {
        const uniqueObjects: { [key: string]: Risk } = {};

        arr.forEach((obj) => {
            // If not exist in the object, add it in. If exist, check updatedAt field.
            if (!(obj.type in uniqueObjects) || (obj.updatedAt && uniqueObjects[obj.type].updatedAt < obj.updatedAt)) {
                uniqueObjects[obj.type] = obj;
            }
        });

        return Object.values(uniqueObjects);
    };

    const renderPage = () => {
        if (isNil(resident)) {
            return <MDCircularProgress />;
        }
        const risks = getUniqueRisks(resident.risks);

        return (
            <>
                <MDBox display="flex" justifyContent="space-between" flexDirection={{ xs: "column", md: "row" }} mb={3}>
                    <ResidentCard {...resident} />
                    <MDBox display="flex" flexWrap="wrap" gap="10px">
                        {risks
                            .sort((a, b) => {
                                if (a.type === "fall" && b.type === "frailty") return 1;
                                if (a.type === "frailty" && b.type === "fall") return -1;

                                const order = ["d", "n", "f"];
                                return order.indexOf(a.type.charAt(0)) - order.indexOf(b.type.charAt(0));
                            })
                            .map((item, idx) => (
                                <MDBox key={idx} width="120px" height="158px">
                                    <RiskCard risk={item} />
                                </MDBox>
                            ))}
                    </MDBox>
                </MDBox>
                <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="end"
                    mb={3}
                    mt={{ xs: 3, md: 0 }}
                    sx={{
                        flexDirection: { xs: "column", md: "row" },
                        rowGap: { xs: 1 },
                        justifyContent: { xs: "center", md: "space-between" },
                        alignItems: { xs: "center", md: "end" },
                    }}>
                    <MDTab
                        list={[
                            { key: translate(`info.measurement.dataType.measurement`), value: "measurement" },
                            { key: translate(`info.measurement.dataType.survey`), value: "survey" },
                        ]}
                        active={activePage}
                        scrollable={false}
                        setActive={setActivePage}
                        noPadding={true}
                    />
                    {activePage === "measurement" && (
                        <MDTab
                            list={[
                                { key: "menu", value: "list" },
                                { key: "insights", value: "chart" },
                            ]}
                            active={activeMode}
                            scrollable={false}
                            isIcon={true}
                            setActive={setActiveMode}
                        />
                    )}
                </MDBox>
                {activePage === "measurement" ? (
                    <>
                        {activeMode === "list" ? (
                            <HealthDataTable residentId={resident.id} diabetes={resident.diabetes} timeZone={resident.timeZone} />
                        ) : (
                            <HealthDataChart residentId={resident.id} diabetes={resident.diabetes} />
                        )}
                    </>
                ) : (
                    <Survey residentInfo={{ ...resident }} callback={handleCallback} />
                )}
            </>
        );
    };

    return <BasicLayout>{renderPage()}</BasicLayout>;
};

export default MeasurementPage;

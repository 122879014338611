import MDBox from "components/MDBox";
import { useTranslation } from "react-i18next";
import PageLayout from "examples/LayoutContainers/PageLayout";
import brand from "assets/images/logos/REL-logo.svg";
import Markdown from "markdown-to-jsx";
import { Fragment, useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import { useSearchParams } from "react-router-dom";
import HealthEduService from "services/healthedu.service";
import { HealthEdu } from "types/healthedu-schema";
import { isNotNil } from "helpers/utils";
import MDCircularProgress from "components/MDCircularProgress";

const PageTitle = (props: any) => {
    const { title } = props;
    return <MDBox sx={{ fontSize: { xs: "32px", md: "40px" } }}>{title}</MDBox>;
};

const Error = () => {
    return <MDBox>Cannot find this health education</MDBox>;
};

const Content = (props: any) => {
    const { children } = props;
    return <MDBox sx={{ paddingTop: "32px", display: "flex", flexDirection: "column" }}>{children}</MDBox>;
};

const StyledTitle = (props: any) => {
    const { title } = props;
    return (
        <MDBox
            sx={{
                position: "relative",
                "&::before": {
                    content: '""',
                    position: "absolute",
                    bottom: -7,
                    left: 0,
                    width: "40px",
                    height: "4px",
                    background: "#7D0FB1",
                },
                marginBottom: "20px",
            }}>
            <MDTypography variant="h1" fontWeight="regular">
                {title}
            </MDTypography>
        </MDBox>
    );
};

const StyledList = (props: any) => {
    const { children } = props;
    return (
        <MDBox>
            <ol style={{ paddingLeft: "40px", fontSize: "20px", lineHeight: "180%" }}>{children}</ol>
        </MDBox>
    );
};

const StyledGallery = (props: any) => {
    const { children } = props;
    return (
        <MDBox>
            <Gallery
                plugins={(pswpLightbox) => {
                    // const captionPlugin = new PhotoSwipeDynamicCaption(pswpLightbox, {
                    //   captionContent: (slide: any) => slide.data.alt,
                    // })
                }}>
                <MDBox
                    sx={{
                        display: "flex",
                        // justifyContent: "space-between",
                        overflow: "hidden",
                        overflowX: "scroll",
                        padding: "60px 0 40px 0",
                    }}>
                    {children}
                </MDBox>
            </Gallery>
        </MDBox>
    );
};
const Version = (props: any) => {
    const { children } = props;
    return (
        <MDBox sx={{ paddingTop: "32px" }}>
            <MDTypography variant="h6" fontWeight="regular">
                {children}
            </MDTypography>
        </MDBox>
    );
};
const ImageBox = (props: any) => {
    const { src, name, version } = props;

    return (
        <Item width="1024" height="768" original={src}>
            {({ ref, open }) => (
                <img
                    style={{ pointerEvents: "auto", width: "400px", padding: "0 20px" }}
                    src={src}
                    ref={ref as React.MutableRefObject<HTMLImageElement>}
                    onClick={open}
                />
            )}
        </Item>
    );
};

export const HealthEducation: React.FC<any> = ({ url }) => {
    const { t: translate } = useTranslation(["common"]);
    const [searchParams] = useSearchParams();
    const [data, setData] = useState<HealthEdu>();

    const fetchHealthEdu = async () => {
        try {
            const res = await HealthEduService.getHealthEdu(searchParams.get("id"));


            if (res.data) {
                setData(res.data);
            }
        } catch (error) {}
    };

    useEffect(() => {
        if (isNotNil(searchParams.get("id"))) {
            fetchHealthEdu();
        }
    }, []);

    return (
        <MDBox sx={{ background: "#F9F9FF", padding: "15px 0 ", minHeight: "100vh" }}>
            <MDBox
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    mx: "auto",
                    
                    padding: { xs: "8px", md: "32px" },
                }}>
                <a href="https://www.realeasyliving.com/" target="_blank">
                    <MDBox sx={{ display: "flex", alignItems: "center", paddingBottom: "24px" }}>
                        <MDBox component="img" src={brand} alt="Brand" />
                        {/* <MDBox sx={{ fontSize: "34px", fontWeight: "100", color: "#7D0FB1" }}>REL Living</MDBox> */}
                    </MDBox>
                </a>

                <MDBox
                    sx={{
                        maxWidth: "1440px",
                        // minHeight: "770px",
                        padding: { xs: "32px 16px", md: "64px" },
                        width: "100%",
                        height: "100%",

                        background: "white",
                        borderRadius: "20px",
                    }}>
                    {data ? (
                        <>
                            <PageTitle title={translate(`info.education.healthType.${data.attributes.health_care_type.data.attributes.Name}`)} />
                            <MDBox sx={{ paddingTop: "32px", display: "flex", flexDirection: "column" }}>
                                <StyledTitle
                                    title={`${translate(`info.education.healthType.${data.attributes.health_care_type.data.attributes.Name}`)} - ${translate(`info.education.riskType.${data.attributes.risk_type.data.attributes.Name}`)}`}
                                />
                                <MDBox sx={{ paddingLeft: "20px", fontSize: "20px", lineHeight: "180%" }}>
                                    <Markdown
                                        options={{
                                            wrapper: Fragment,
                                            forceWrapper: false,
                                        }}>
                                        {data.attributes.Tips}
                                    </Markdown>
                                </MDBox>
                                <MDBox>
                                    {data.attributes.Media.data.length > 0 && (
                                        <StyledGallery>
                                            {data.attributes.Media.data.map((image) => (
                                                <ImageBox src={`https://cms.realeasyliving.com${image.attributes.url}`} />
                                            ))}
                                        </StyledGallery>
                                    )}
                                </MDBox>
                            </MDBox>
                        </>
                    ) : (
                        <MDCircularProgress />
                    )}
                </MDBox>
            </MDBox>
        </MDBox>
    );
};

export default HealthEducation;
